import React, { useState, useEffect } from 'react';
import { PageTitle, Datepicker, SearchBar, PageSkeleton, Pagination, Button } from '@upshop/exodia';
import { IGetCustomersParams, IGetCustomersResponse } from 'redux/slices/reports/types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card } from 'reactstrap';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { useNavigate } from 'react-router-dom';

import Table from '../../order/Components/Table';

import styles from './styles';

interface CustomerReportProps {
    customersLoading: boolean;
    customersError: string;
    customers: IGetCustomersResponse[];
    currentPage: number;
    currentIndex: number;
    setCurrentPage: (page: number) => void;
    setCurrentIndex: (index: number) => void;
    getCustomers: (params: IGetCustomersParams) => void;
}

const CustomerReport = (props: CustomerReportProps): JSX.Element => {
    const { customersLoading, customersError, customers, currentPage, currentIndex, setCurrentPage, setCurrentIndex, getCustomers } = props;
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [dateDropdown, setDateDropdown] = useState(false);
    const [selectedExportMethod, setSelectedExportMethod] = useState('');
    const [query, setQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (startDate && endDate) {
            getCustomers({ index: currentIndex, dateFrom: dayjs(startDate).format('YYYY-MM-DD'), dateTo: dayjs(endDate).format('YYYY-MM-DD'), search: query });
        } else {
            getCustomers({ index: currentIndex, dateFrom: null, dateTo: null, search: query });
        }
    }, [endDate, currentIndex]);

    useEffect(() => {
        if (customers[0]?.maxIndex >= 1) {
            setCurrentIndex(currentIndex || 1);
            setCurrentPage(currentPage || 1);
        }
    }, []);

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const renderDropdownList = () => {
        return (
            <DropdownItem key='csv' onClick={() => setSelectedExportMethod('csv')} style={{ maxWidth: '300px', overflowX: 'hidden', overflowY: 'auto' }}>Export to CSV</DropdownItem>
        );
    };

    const handleOnKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleSearch(e.currentTarget.value.trim());
        }
    };

    const handleSearch = (queryString: string) => {
        getCustomers({ index: currentIndex, dateFrom: dayjs(startDate).format('DD/MM/YYYY'), dateTo: dayjs(endDate).format('DD/MM/YYYY'), search: queryString.trim() });
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const prepareTableData = () => {
        const tableHeaders = ['No.', 'Name', 'Total No. of Orders', 'Total Amount Spent'];

        const tableData = customers[currentPage - 1]?.data.map((customer, index) => {
            const { id, firstName, lastName, totalSpent: { totalAmount, currency }, totalNumOfOrders } = customer;

            return [index + 1, <Button label={firstName.concat(' ').concat(lastName)} onClick={() => navigate('reports/customers/details', { state: { id } })} style={{ color: '#6989FE', border: 0, background: 0 }} />, totalNumOfOrders, currency.concat(totalAmount.toString())];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const renderTable = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            <div style={{ marginTop: '20px' }}>
                {customersLoading
                    ? <PageSkeleton count={10} />
                    : (
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                            {customersError ? <div style={{ color: 'red' }}>{customersError}</div> : <Table tableHeaders={tableHeaders} tableData={tableData} />}

                            <div style={{ position: 'absolute', bottom: '5px' }}>
                                <Pagination onClick={setCurrentPage} currentPage={currentPage} maxPages={customers[0]?.maxIndex > 0 ? customers[0]?.maxIndex : 0} index={currentIndex} setIndex={setCurrentIndex} />
                            </div>
                        </div>
                    )}
            </div>
        );
    };

    return (
        <div
            style={{
                margin: '25px',
                maxWidth: '1500px',
            }}
        >
            <div style={{ marginLeft: '30px', marginBottom: '0px' }}>
                <PageTitle size='xl'>Customers</PageTitle>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                <div style={{ marginRight: '15px', marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                    <Datepicker selected={startDate} startDate={startDate} endDate={endDate} onChange={onDatesChange} />
                </div>

                <div>
                    <Dropdown isOpen={dateDropdown} toggle={() => setDateDropdown(!dateDropdown)}>
                        <DropdownToggle style={{ maxWidth: '300px', overflow: 'hidden' }} caret>
                            Export
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderDropdownList()}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>

            <Card style={styles.CustomersCard}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '60px' }}>
                    <div>
                        <SearchBar
                            onClick={() => handleSearch(query)}
                            label
                            onKeyDown={(e) => handleOnKeyDown(e)}
                            value={query || ''}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                {renderTable()}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    customersLoading: Selectors.getReportsCustomersAttempting(state),
    customersError: Selectors.getReportsCustomersError(state),
    customers: Selectors.getReportsCustomers(state),
    currentPage: Selectors.getReportsCustomersCurrentPage(state),
    currentIndex: Selectors.getReportsCustomersCurrentIndex(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setCurrentPage: (page: number) => dispatch(Actions.reportsSetCustomersCurrentPage(page)),
    setCurrentIndex: (index: number) => dispatch(Actions.reportsSetCustomersCurrentIndex(index)),
    getCustomers: (params: IGetCustomersParams) => dispatch(Actions.reportsGetCustomersAttempt({ ...params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReport);
