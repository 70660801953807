import React, { useEffect } from 'react';
import { IAdminOrderDetails } from '@upshop/types';
import { PageTitle, BackButton, Button } from '@upshop/exodia';

import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { useNavigate, useParams } from 'react-router-dom';

import { IAddOrderRemarksParams, IOrderStatusEnum, IUpdateOrderTrackingIdParams } from 'redux/slices/order/types';

import Utils from 'lib/Utils';

import dayjs from 'dayjs';
import CustomerDetailsComponent from './Components/CustomerDetailsComponent';
import PaymentDetailsComponent from './Components/PaymentDetailsComponent';
import OrderDetailsComponent from './Components/OrderDetailsComponent';
import OrderStatusLabel from './Components/OrderStatusLabel';

import Styles from './styles';

interface OrderDetailsProps {
    orderDetails: IAdminOrderDetails;
    orderDetailsError: string;
    updatingOrderStatus: boolean;
    updateOrderStatusError: string;
    updatingId: string;
    approvingManualBankTransfer: boolean;
    approveManualBankTransferError: string;
    orderDetailsLoading: boolean;
    trackingIdModalIsOpen: boolean;
    getOrderDetails: (orderId: string) => void;
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => void;
    resetOrderDetails: () => void;
    approveManualBankTransfer: (orderId: string, isApproved: boolean) => void;
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => void;
    setTrackingIdModalIsOpen: (isOpen: boolean) => void;
    addOrderRemarks: (params: IAddOrderRemarksParams) => void;
}

const OrderDetails = (props: OrderDetailsProps): JSX.Element => {
    const {
        orderDetails,
        updatingOrderStatus,
        updatingId,
        approvingManualBankTransfer,
        orderDetailsError,
        updateOrderStatusError,
        approveManualBankTransferError,
        orderDetailsLoading,
        trackingIdModalIsOpen,
        getOrderDetails,
        updateOrderStatus,
        resetOrderDetails,
        approveManualBankTransfer,
        updateOrderTrackingId,
        setTrackingIdModalIsOpen,
        addOrderRemarks,
    } = props;
    const { orderId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (orderId) {
            getOrderDetails(orderId);
        }

        return (() => {
            resetOrderDetails();
        });
    }, []);

    const prepareData = () => {
        const { id, shortId, orderDate, products, customerRemarks, customerInfo, totalPrice, currency, shippingFee, remarks, paymentInfo, fulfillmentStatus, deliveryDetails, customAttribute, customerName, customerEmail, mobileNumber, orderLogs } = orderDetails;

        const { phoneNumber } = customerInfo;

        const { paymentMethod, paymentStatus, paymentId } = paymentInfo;

        const paymentSlipImageUrl = '';
        const paymentSlipUploadDate = '';

        const CustomerDetails = {
            name: customerName,
            email: customerEmail,
            phoneNumber,
            products,
        };

        const PaymentDetails = {
            id,
            status: fulfillmentStatus,
            shortId,
            orderDate: dayjs(orderDate).toString(),
            paymentId,
            paymentMethod,
            paymentStatus,
            paymentSlipImageUrl,
            paymentSlipUploadDate,
            adminRemarks: remarks,
        };

        const { address, trackingId, logisticsProvider, customAttributes: deliveryCustomAttributes } = deliveryDetails || {};
        const { address1 = '', address2 = '', city = '', state = '', postCode = '', country = '' } = address || {};

        const OrderDetailsData = {
            id,
            orderDate: dayjs(orderDate).toString(),
            status: fulfillmentStatus,
            location: products.map(product => Utils.Order.getLocation(product)),
            customerRemarks,
            currency,
            totalPrice,
            paymentMethod,
            shippingFee,
            remarks,
            name: customerName,
            address: `${address1.length ? address1.concat(', ') : ''}${address2.length ? address2.concat(', ') : ''}${city.length ? city.concat(', ') : ''}${state.length ? state.concat(', ') : ''}${postCode.length ? postCode.concat(', ') : ''}${country.length ? country : ''}`,
            phoneNumber,
            trackingId,
            logisticsProvider,
            customAttribute,
            deliveryCustomAttributes,
            orderLogs,
        };

        return {
            CustomerDetails,
            PaymentDetails,
            OrderDetailsData,
        };
    };

    const renderComponents = () => {
        const { CustomerDetails, PaymentDetails, OrderDetailsData } = prepareData();

        return (
            <div style={{ marginTop: '10px', display: 'flex' }}>
                <div>
                    <CustomerDetailsComponent
                        data={CustomerDetails}
                        orderDetailsLoading={orderDetailsLoading}
                    />
                    <PaymentDetailsComponent
                        data={PaymentDetails}
                        updateOrderStatus={updateOrderStatus}
                        updating={updatingOrderStatus}
                        updatingId={updatingId}
                        addOrderRemarks={addOrderRemarks}
                        approveManualBankTransfer={approveManualBankTransfer}
                        approvingManualBankTransfer={approvingManualBankTransfer}
                        orderDetailsError={orderDetailsError}
                        orderDetailsLoading={orderDetailsLoading}
                        updateOrderTrackingId={updateOrderTrackingId}
                        modal={trackingIdModalIsOpen}
                        setModal={setTrackingIdModalIsOpen}
                    />
                </div>

                <div>
                    <OrderDetailsComponent
                        data={OrderDetailsData}
                        orderDetailsError={orderDetailsError}
                        orderDetailsLoading={orderDetailsLoading}
                        updating={updatingOrderStatus}
                        updatingId={updatingId}
                        updateOrderStatus={updateOrderStatus}
                        updateOrderTrackingId={updateOrderTrackingId}
                        modal={trackingIdModalIsOpen}
                        setModal={setTrackingIdModalIsOpen}
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                paddingTop: '5%',
                paddingLeft: '5%',
                // justifyContent: 'center',
                // alignItems: 'center',
            }}
        >
            <div
                style={{
                    margin: '10px 25px',
                    maxWidth: '1220px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <BackButton
                        style={Styles.OrderDetailsBackButton}
                        onClick={() => navigate('/orders')}
                    />
                    {/* {orderDetailsError
                        ? (<PageTitle size='large' color='red'>{orderDetailsError}</PageTitle>)
                        : ( */}
                    {/* )} */}
                    <div style={{ width: '100%' }}>
                        <PageTitle size='large'>
                            {`Order : ${orderDetails.shortId} `}
                        </PageTitle>
                    </div>

                    <OrderStatusLabel orderStatus={orderDetails.fulfillmentStatus} paymentStatus={orderDetails.paymentInfo.paymentStatus} />

                    <div
                        // hidden={orderDetailsError.length > 0}
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            style={Styles.OrderDetailsButton}
                            label='Refund'
                            onClick={() => updateOrderStatus(orderDetails.id, IOrderStatusEnum.Refunded)}
                        />
                    </div>
                </div>

                <div
                    // hidden={orderDetailsError.length > 0}
                    style={{
                        marginLeft: '70px',
                    }}
                >
                    <PageTitle size='small'>
                        {orderDetails.totalPrice > 0 ? `Total Order : MYR ${orderDetails.totalPrice}` : ''}
                    </PageTitle>
                </div>
                {
                    // orderDetails.id ? (
                    renderComponents()
                    // ) : (
                    //     <div style={{ marginTop: '10px', display: 'flex' }}>
                    //         <div style={{ color: 'red' }}>No data found</div>
                    //     </div>
                    // )
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    orderDetails: Selectors.getOrderOrderDetails(state),
    orderDetailsError: Selectors.getOrderOrderDetailsError(state),
    orderDetailsLoading: Selectors.getOrderOrderDetailsAttempting(state),
    updatingOrderStatus: Selectors.getOrderUpdateOrderStatusAttempting(state),
    updateOrderStatusError: Selectors.getOrderUpdateOrderStatusError(state),
    updatingId: Selectors.getOrderUpdateOrderStatus(state),
    updateId: Selectors.getOrderUpdateOrderStatusError(state),
    approvingManualBankTransfer: Selectors.getOrderApproveManualBankTransferAttempting(state),
    approveManualBankTransferError: Selectors.getOrderApproveManualBankTransferError(state),
    trackingIdModalIsOpen: Selectors.getOrderTrackingIdModalIsOpen(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrderDetails: (orderId: string) => dispatch(Actions.orderGetOrderDetailsAttempt({ orderId })),
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => dispatch(Actions.orderUpdateStatusAttempt({ orderId, status })),
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => dispatch(Actions.orderUpdateTrackingIdAttempt(params)),
    resetOrderDetails: () => dispatch(Actions.orderResetOrderDetails()),
    approveManualBankTransfer: (orderId: string, isApproved: boolean) => dispatch(Actions.orderApproveManualBankTransferAttempt({ orderId, isApproved })),
    setTrackingIdModalIsOpen: (isOpen: boolean) => dispatch(Actions.orderSetTrackingIdModalIsOpen(isOpen)),
    addOrderRemarks: (params: IAddOrderRemarksParams) => dispatch(Actions.orderAddOrderRemarksAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
