import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import LoginScreen from 'containers/auth/Login';
import SettingsScreen from 'containers/settings';
import OrderScreen from 'containers/order';
import OrderDetailsScreen from 'containers/order/OrderDetails';
import CustomerScreen from 'containers/customer';
import CustomerDetailsScreen from 'containers/customer/CustomerDetails';
import DashboardScreen from 'containers/dashboard';
import CustomerReportScreen from 'containers/reports/customers/index';
import CustomerReportDetailsScreen from 'containers/reports/customers/details';
import CategoriesScreen from 'containers/products/categories';
import CreateCategoryScreen from 'containers/products/categories/CreateCategory';
import ForgotPassword from 'containers/auth/ForgotPassword';

import PrivateRoute from './PrivateRoute';

const PrivateBucket = (): JSX.Element => {
    return (
        <Route path='/' element={<PrivateRoute />}>
            <Route
                index
                element={<DashboardScreen />}
            />
            <Route
                path='/settings'
                element={<SettingsScreen />}
            />

            <Route
                path='/orders'
                element={<OrderScreen />}
            />
            <Route
                path='/orders/:orderId'
                element={<OrderDetailsScreen />}
            />
            <Route
                path='/customer'
                element={<CustomerScreen />}
            />
            <Route
                path='/customer/:customerId'
                element={<CustomerDetailsScreen />}
            />
            <Route
                path='/reports/customers'
                element={<CustomerReportScreen />}
            />
            <Route
                path='reports/customers/details'
                element={<CustomerReportDetailsScreen />}
            />
            <Route
                path='/categories'
                element={<CategoriesScreen />}
            />
            <Route
                path='/newCategory'
                element={<CreateCategoryScreen />}
            />

            <Route
                path='/promotions'
                element={<SettingsScreen />}
            />

            <Route
                path='/customers'
                element={<SettingsScreen />}
            />
        </Route>
    );
};

const NavRoutes = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/login' element={<LoginScreen />} />

                <Route
                    path='/forgot-password'
                    element={<ForgotPassword />}
                />

                <Route path='*' element={<Navigate replace to='/' />} />
                {PrivateBucket()}
            </Routes>
        </BrowserRouter>
    );
};

export default NavRoutes;
