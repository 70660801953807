import authSlice from 'redux/slices/auth';
import tenantSlice from 'redux/slices/tenant';
import orderSlice from './slices/order';
import reportsSlice from './slices/reports';
import productsSlice from './slices/products';
import customersSlice from './slices/customer';

export default {
    ...authSlice.actions,
    ...tenantSlice.actions,
    ...orderSlice.actions,
    ...reportsSlice.actions,
    ...productsSlice.actions,
    ...customersSlice.actions,
};
