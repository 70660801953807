export default {
    OrderDetailsButton: {
        color: '#4069FF',
        backgroundColor: 'transparent',
        border: '1px solid rgba(0,0,0,0.1)',
        width: 'auto',
    },
    OrderDetailsBackButton: {
        margin: '0px 20px 0px 0px',
        width: '50px',
        height: '50px',
    },
    OrdersCard: {
        padding: '20px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        minWidth: '1200px',
        maxWidth: '1500px',
        height: 'auto',
    },
    OrdersAdditionalFilterButtons: {
        marginRight: '15px',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
};
