import { fork } from 'redux-saga/effects';

import OrderGateway from 'api/Order';
import { RootSagaReturnType } from 'sagas/types';

import watchGetOrder from './getOrders';
import watchGetOrderDetails from './getOrderDetails';
import watchUpdateOrderStatus from './updateOrderStatus';
import watchApproveManualBankTransfer from './approveManualBankTransfer';
import watchAddTrackingId from './addTrackingId';
import watchAddOrderRemarks from './addOrderRemarks';
import watchExportOrder from './exportOrder';
import watchGetOrderLogs from './getOrderLogs';

export default (api: OrderGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetOrder, api);
        yield fork(watchGetOrderDetails, api);
        yield fork(watchUpdateOrderStatus, api);
        yield fork(watchApproveManualBankTransfer, api);
        yield fork(watchAddTrackingId, api);
        yield fork(watchAddOrderRemarks, api);
        yield fork(watchExportOrder, api);
        yield fork(watchGetOrderLogs, api);
    }

    return {
        rootSaga,
    };
};
