import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Button, PageTitle, Input } from '@upshop/exodia';
import { Card, Row } from 'reactstrap';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';

import { RootState, AppDispatch } from 'redux/store';

interface LoginProps {
    loading: boolean;
    login(username: string, password: string, navigate: NavigateFunction): void;
    error: string;
}

const Login = (props: LoginProps): JSX.Element => {
    const { login, loading, error } = props;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginBasicError, setLoginBasicError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const handleLoginClick = () => {
        if (!email && !password) {
            setLoginBasicError('Please enter Email and Password');
            return;
        }
        if (!email) {
            setLoginBasicError('Please enter Email');
            return;
        }
        if (!password) {
            setLoginBasicError('Please enter Password');
            return;
        }
        if (email && password) login(email, password, navigate);
    };

    const handleForgotPasswordClick = () => {
        navigate('/forgot-password');
    };

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', height: '100vh', backgroundColor: '#6989FE', alignItems: 'center' }}>
            <Card style={{ width: '500px', height: '700px', right: '150px' }}>
                <Row style={loginCardRows}>
                    <img src='/Group.png' alt='logo' style={{ width: '70%', height: 'auto', marginTop: 50 }} />
                </Row>
                <Row>
                    <div style={loginCardRows}>
                        <PageTitle>Login to Administration</PageTitle>
                    </div>
                </Row>
                <Row style={loginCardRows}>
                    Enter your email and password below
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Input disabled={loading} placeholder='Email' style={{ width: '400px' }} label='EMAIL' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </Row>
                <Row style={{ marginTop: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center ' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center ' }}>
                            <Input disabled={loading} placeholder='Password' type={showPassword ? 'text' : 'password'} style={{ width: '400px' }} label='PASSWORD' value={password} onChange={(e) => setPassword(e.target.value)} />
                            <div style={{ display: 'flex' }}>
                                <Input type='checkbox' value='' onChange={handleShowPasswordClick} />
                                <p style={{ margin: 0, marginLeft: 10 }}>Show Password</p>
                            </div>
                        </div>
                    </div>

                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p style={{ color: 'red' }}>{loginBasicError}</p>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button loading={loading} style={{ backgroundColor: '#FF9900', width: '400px', boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)' }} label='Log In' onClick={() => handleLoginClick()} />
                    </div>
                    <div style={{ color: 'red', fontSize: '12px', display: 'flex', justifyContent: 'center' }}>{error}</div>
                </Row>
                <Row style={{ marginTop: 'auto', marginBottom: '1px', display: 'flex', justifyContent: 'center' }}>
                    <Button
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#9FA2B4',
                            backgroundColor: 'transparent',
                            textDecorationLine: 'underline',
                            width: 'auto',
                        }}
                        onClick={handleForgotPasswordClick}
                        label='Forgot password?'
                    />
                </Row>
            </Card>
        </div>
    );
};

const loginCardRows = { display: 'flex', justifyContent: 'center', marginBottom: '10px' };

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthLoginAttempting(state),
    error: Selectors.getAuthLoginError(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    login: (username: string, password: string, navigate: NavigateFunction) =>
        dispatch(Actions.authLoginAttempt({ username, password, navigate })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
