import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { PageSkeleton, Collapsible, Label } from '@upshop/exodia';
import { ICartProduct } from '@upshop/types';
import Icons from 'Icons';

import Styles from './styles';

export interface CustomerDetailsDataProps {
    name: string;
    email?: string;
    phoneNumber?: number;
    products: ICartProduct[];
}

interface CustomerDetailsProps {
    data: CustomerDetailsDataProps | undefined;
    orderDetailsLoading: boolean;
}

const CustomerDetails = (props: CustomerDetailsProps): JSX.Element => {
    const { data, orderDetailsLoading } = props;

    const renderCustomerDetails = () => {
        return (
            <div>
                <Label label='Order Customer' text={data?.name || '-'} />
                <Label label='Email' text={data?.email || '-'} />
                <Label label='Contact Number' text={data?.phoneNumber || '-'} />
                {/* <Label label='Metadata' text={data?.customAttributes || '-'} /> */}
            </div>
        );
    };

    const renderCollapsibles = () => {
        if (data?.products.length) {
            const itemsToRender = data?.products.map(product => {
                const { name, selectedVariant, customAttributes } = product;

                const title = (
                    <div style={Styles.PurchasedItemsCollapsibleTitleRow}>
                        <div style={{ marginRight: '10px' }}>
                            <img alt='product' src={`https://jemisys.tiansi.my/JewelImages/${product.id}.JPG`} height='40px' width='auto' />
                        </div>

                        <div style={Styles.PurchasedItemsCollapsibleTitle}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                {`${name} - ${selectedVariant.price.currency}${product.price ? product.price : selectedVariant.price.currentPrice}`}
                            </div>
                        </div>
                    </div>
                );

                const getProductMetadata = () => {
                    if (customAttributes !== undefined) {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                {customAttributes.map(attribute => {
                                    return (
                                        <div
                                            style={{
                                                display: 'block',
                                                margin: '0px 10px 5px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <div>{attribute.name}</div>
                                            <div>{attribute.value}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    }
                    return false;
                };

                const getProductVariation = () => {
                    const variant = selectedVariant.type.find(item => item.name === 'Weight');

                    if (!variant) return '-';
                    return variant.value;
                };

                const content = (
                    <div style={Styles.PurchasedItemsCollapsibleContentRow}>
                        <div>
                            <Label
                                label='Variation'
                                text={getProductVariation()}
                            />
                            <Label label='SKU' text={selectedVariant.sku} />
                        </div>

                        <div
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            <Label
                                hidden={customAttributes === undefined}
                                label='Metadata'
                                text={getProductMetadata()}
                            />
                        </div>
                    </div>
                );

                return (
                    <div>
                        <Collapsible
                            expandedHeight='200px'
                            title={title}
                            content={content}
                        />
                    </div>
                );
            });

            if (itemsToRender.length) {
                return itemsToRender;
            }
        }

        return (
            <div>
                No items found
            </div>
        );
    };

    const renderPurchasedItems = () => {
        return (
            <div style={Styles.PurchasedItems}>
                {renderCollapsibles()}
            </div>
        );
    };

    return (
        <Card style={Styles.CustomerDetailsCard}>
            {orderDetailsLoading
                ? <PageSkeleton count={10} />
                : (
                    <Row>
                        <Col xs={4}>
                            <div style={Styles.ComponentTitle}>
                                Customer Details
                            </div>
                            {renderCustomerDetails()}
                        </Col>

                        <Col>
                            <div>
                                <div style={Styles.ComponentTitle}>
                                    Purchased Items
                                </div>
                                <div>
                                    {renderPurchasedItems()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
        </Card>
    );
};

export default CustomerDetails;
