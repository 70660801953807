import { Button, Input, PageTitle } from '@upshop/exodia';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

interface ResetPasswordPayload {
    email: string;
}

interface ForgotPasswordProps {
    loading: boolean;
    error: string;
    success: boolean,
    resetPassword: (data: ResetPasswordPayload) => void;
}

const ForgotPassword = (props: ForgotPasswordProps): JSX.Element => {
    const navigate = useNavigate();

    const { loading, error, success, resetPassword } = props;

    const [email, setEmail] = useState('');
    const [resetPasswordBasicError, setResetPasswordBasicError] = useState('');

    const handleResetPasswordClick = () => {
        if (!email) {
            setResetPasswordBasicError('Please enter Email');
            return;
        }

        resetPassword({ email });
    };

    const handleLoginClicked = () => {
        navigate('/login');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#6989FE' }}>
            <div style={{ width: '500px', height: '350px', backgroundColor: 'white', padding: 40, borderRadius: 4 }}>
                <PageTitle>Reset Password</PageTitle>

                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Input label='Email address' value={email} style={{ width: '400px' }} onChange={(e) => setEmail(e.target.value)} />

                    <Row style={{ marginTop: '30px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ color: 'red' }}>{resetPasswordBasicError}</p>
                            {
                                success && <p style={{ color: 'green' }}>Email sent successfully</p>
                            }
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {
                                success
                                    ? (
                                        <Button loading={loading} style={{ backgroundColor: '#FF9900', width: '400px', boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)' }} label='OK' onClick={() => handleLoginClicked()} />
                                    )
                                    : (
                                        <Button loading={loading} style={{ backgroundColor: '#FF9900', width: '400px', boxShadow: '0px 4px 12px rgba(55, 81, 255, 0.24)' }} label='Send Email' onClick={() => handleResetPasswordClick()} />
                                    )
                            }
                        </div>
                        <div style={{ color: 'red', fontSize: '12px', display: 'flex', justifyContent: 'center' }}>{error}</div>
                    </Row>

                    <Row style={{ marginTop: 'auto', marginBottom: '1px', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#9FA2B4',
                                backgroundColor: 'transparent',
                                textDecorationLine: 'underline',
                                width: 'auto',
                            }}
                            onClick={handleLoginClicked}
                            label='To Login'
                        />
                    </Row>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: Selectors.getAuthResetPasswordAttempting(state),
    error: Selectors.getAuthResetPasswordError(state),
    success: Selectors.getAuthResetPasswordSuccess(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetPassword: (data: ResetPasswordPayload) => dispatch(Actions.authResetPasswordAttempt(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
