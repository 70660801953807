import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminCustomerDetails } from '@upshop/types';
import { IGetCustomersParams, ReportsReduxState, IGetCustomersResponse, IGetCustomerDetailsParams, IUpdateCustomerParams } from './types';

const initialState: ReportsReduxState = {
    actions: {
        customers: false,
        customerDetails: false,
        updateCustomer: false,
    },
    customersCurrentPage: 0,
    customersCurrentIndex: 0,
    customers: [
        {
            index: -1,
            maxIndex: -1,
            data: [],
        },
    ],
    customerDetails: {
        address: {
            address1: '',
            address2: '',
            state: '',
            city: '',
            postCode: '',
            country: '',
        },
        orders: [],
        id: '',
        firstName: '',
        lastName: '',
        totalSpent: {
            totalAmount: 0,
            currency: '',
        },
        totalNumOfOrders: 0,
    },
    customerDetailsEditMode: false,
    error: {
        customers: '',
        customerDetails: '',
        updateCustomer: '',
    },
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        reportsGetCustomersAttempt: (state, _action: PayloadAction<IGetCustomersParams>) => {
            state.actions.customers = true;
            state.error.customers = '';
        },
        reportsGetCustomersSuccess: (state, action: PayloadAction<IGetCustomersResponse[]>) => {
            state.actions.customers = false;
            if (action.payload) {
                state.customers = action.payload;
            }
        },
        reportsGetCustomersFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.customers = false;
            if (action.payload) {
                state.error.customers = action.payload;
            }
        },
        reportsSetCustomersCurrentPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.customersCurrentPage = action.payload;
        },
        reportsSetCustomersCurrentIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) state.customersCurrentIndex = action.payload;
        },
        reportsGetCustomerDetailsAttempt: (state, _action: PayloadAction<IGetCustomerDetailsParams>) => {
            state.actions.customerDetails = true;
            state.error.customerDetails = '';
        },
        reportsGetCustomerDetailsSuccess: (state, action: PayloadAction<IAdminCustomerDetails>) => {
            state.actions.customerDetails = false;
            if (action.payload) {
                state.customerDetails = action.payload;
            }
        },
        reportsGetCustomerDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.customerDetails = false;
            if (action.payload) {
                state.error.customerDetails = action.payload;
            }
        },
        reportsUpdateCustomerAttempt: (state, _action: PayloadAction<IUpdateCustomerParams>) => {
            state.actions.updateCustomer = true;
            state.error.updateCustomer = '';
        },
        reportsUpdateCustomerSuccess: (state, action: PayloadAction<IUpdateCustomerParams>) => {
            if (action.payload) {
                const { id, firstName, lastName, phoneNumber, email, address, birthday } = action.payload;

                state.customerDetails.id = id;
                state.customerDetails.firstName = firstName;
                state.customerDetails.lastName = lastName;
                state.customerDetails.phoneNumber = phoneNumber;
                state.customerDetails.email = email;
                state.customerDetails.address = address;
                state.customerDetails.birthday = birthday;
            }
            state.actions.updateCustomer = false;
            state.customerDetailsEditMode = false;
        },
        reportsUpdateCustomerFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateCustomer = false;
            state.customerDetailsEditMode = false;
            if (action.payload) {
                state.error.updateCustomer = action.payload;
            }
        },
        reportsSetCustomerDetailsEditMode: (state, action: PayloadAction<boolean>) => {
            state.customerDetailsEditMode = action.payload;
        },
    },
});

export type ReportsState = typeof initialState;

export default {
    actions: reportsSlice.actions,
    reducers: reportsSlice.reducer,
};
