import { IAddress, ICustomAttribute } from '@upshop/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IOrderStatusEnum } from 'redux/slices/order/types';

export interface GetCustomersParams {
    authToken: string;
    index: number;
    dateFrom?: string;
    dateTo?: string;
    search?: string;
}

export interface GetCustomersResponse {
    index: number;
    maxIndex: number;
    data: [
        {
            customerId: string
            name: string
            mobileNumber: string
            totalOrders: number
            totalRevenue: number
            averageRevenuePerOrder: number;
        },
    ],
}

export interface GetCustomerDetailsParams {
    authToken: string;
    customerId: string;
}

export interface GetCustomerDetailsResponse {
    name: string;
    mobileNumber: string;
    totalOrder: number;
    totalRevenue: number;
    averageRevenuePerOrder: number;
    email: string;
    address: IAddress;
    metadata: ICustomAttribute[];
}

export interface GetOrderDetailsParams {
    authToken: string;
    orderId: string;
}

export interface UpdateOrderStatusParams {
    authToken: string;
    orderId: string;
    status: IOrderStatusEnum;
}

export interface ApproveManualBankTransferParams {
    authToken: string;
    orderId: string;
    isApproved: boolean;
}

export interface UpdateTrackingIdParams {
    orderId: string;
    authToken: string;
    trackingId: string;
    logisticCompany: string;
}

export interface ApproveManualBankTransferResponse {
    orderId: string;
    status: IOrderStatusEnum;
}

export abstract class ICustomerGateway extends Gateway {
    abstract getCustomers(params: GetCustomersParams): GatewayResponse<GetCustomersResponse | null>;

    abstract getCustomerDetails(params: GetCustomerDetailsParams): GatewayResponse<GetCustomerDetailsResponse | null>;

    abstract updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<null>;

    abstract updateTrackingId(params: UpdateTrackingIdParams): GatewayResponse<null>;

    abstract approveManualBankTransfer(params: ApproveManualBankTransferParams): GatewayResponse<ApproveManualBankTransferResponse | null>;

    abstract exportCustomer(params: GetCustomersParams): GatewayResponse<string | null>;
}
