import { fork } from 'redux-saga/effects';
import ProductsGateway from 'api/Products';
import { RootSagaReturnType } from 'sagas/types';

import watchGetCategories from './getCategories';
import watchDeleteCategory from './deleteCategory';
import watchUpdateCategoryStatus from './updateCategoryStatus';
import watchCreateCategory from './createCategory';

export default (api: ProductsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCategories, api);
        yield fork(watchDeleteCategory, api);
        yield fork(watchUpdateCategoryStatus, api);
        yield fork(watchCreateCategory, api);
    }

    return {
        rootSaga,
    };
};
