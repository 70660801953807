import { IAdminCustomerDetails } from '@upshop/types';
import { IGetCustomersResponse } from './types';
import { ReportsState } from '.';

const getCustomersAttempting = (state: ReportsState): boolean => state.actions.customers || false;
const getCustomersError = (state: ReportsState): string => state.error.customers || '';
const getCustomers = (state: ReportsState): IGetCustomersResponse[] => state.customers || {};

const getCustomersCurrentPage = (state: ReportsState): number => state.customersCurrentPage || 1;
const getCustomersCurrentIndex = (state: ReportsState): number => state.customersCurrentIndex || 1;

const getCustomerDetailsAttempting = (state: ReportsState): boolean => state.actions.customerDetails || false;
const getCustomerDetailsError = (state: ReportsState): string => state.error.customerDetails || '';
const getCustomerDetails = (state: ReportsState): IAdminCustomerDetails => state.customerDetails || {};

const getUpdateCustomerAttempting = (state: ReportsState): boolean => state.actions.updateCustomer || false;
const getUpdateCustomerError = (state: ReportsState): string => state.error.updateCustomer || '';

const getCustomerDetailsEditMode = (state: ReportsState): boolean => state.customerDetailsEditMode || false;

export default {
    getCustomersAttempting,
    getCustomersError,
    getCustomers,

    getCustomersCurrentPage,
    getCustomersCurrentIndex,

    getCustomerDetailsAttempting,
    getCustomerDetailsError,
    getCustomerDetails,

    getUpdateCustomerAttempting,
    getUpdateCustomerError,

    getCustomerDetailsEditMode,
};
