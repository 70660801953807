import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Card } from 'reactstrap';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { PageTitle, Button, PageSkeleton } from '@upshop/exodia';
import { IAdminTenantStatus, ISetGoldPriceParams, ISetMaintenanceParams } from 'redux/slices/tenant/types';
import Maintenance from './Components/Maintenance';
import GoldPriceSettings from './Components/GoldPriceSettings';

import styles from './styles';

interface SettingsProps {
    getTenantStatus: () => void;
    tenantStatus: IAdminTenantStatus;
    tenantStatusLoading: boolean;
    tenantStatusError: string;
    setMaintenance: (data: ISetMaintenanceParams) => void;
    setGoldPrice: (goldPrice: ISetGoldPriceParams) => void;
    loading: boolean;
    error: string;
}

const Settings = (props: SettingsProps): JSX.Element => {
    const {
        getTenantStatus,
        tenantStatus,
        tenantStatusLoading,
        tenantStatusError,
        setMaintenance,
        setGoldPrice,
        loading,
        error,
    } = props;
    const { goldPrice, isMaintenance } = tenantStatus;

    const [maintenanceMode, setMaintenanceMode] = useState(isMaintenance);
    // const [gold375, setGold375] = useState(goldPrice.gold375G);
    // const [gold750, setGold750] = useState(goldPrice.gold750);
    const [gold916B, setGold916B] = useState(goldPrice.gold916B);
    const [gold916A, setGold916A] = useState(goldPrice.gold916A);
    // const [gold916, setGold916] = useState(goldPrice.gold916);
    const [gold999, setGold999] = useState(goldPrice.gold999);
    const [gold9999, setGold9999] = useState(goldPrice.gold9999);

    useEffect(() => {
        getTenantStatus();
    }, []);

    useEffect(() => {
        // if (goldPrice.gold375G !== gold375) setGold375(goldPrice.gold375G);
        // if (goldPrice.gold750 !== gold750) setGold750(goldPrice.gold750);
        if (goldPrice.gold916B !== gold916B) setGold916B(goldPrice.gold916B);
        if (goldPrice.gold916A !== gold916A) setGold916A(goldPrice.gold916A);
        // if (goldPrice.gold916 !== gold916) setGold916(goldPrice.gold916);
        if (goldPrice.gold999 !== gold999) setGold999(goldPrice.gold999);
        if (goldPrice.gold9999 !== gold9999) setGold9999(goldPrice.gold9999);
    }, [goldPrice]);

    useEffect(() => {
        if (isMaintenance !== maintenanceMode) setMaintenanceMode(isMaintenance);
    }, [isMaintenance]);

    const handleSaveClick = () => {
        if (isMaintenance !== maintenanceMode) setMaintenance({ isMaintenance: maintenanceMode });
        if (gold916B !== goldPrice.gold916B || gold916A !== goldPrice.gold916A || gold999 !== goldPrice.gold999 || gold9999 !== goldPrice.gold9999) {
            setGoldPrice({
                gold375G: 0,
                gold750: 0,
                gold916B: Number(gold916B),
                gold916A: Number(gold916A),
                gold916: 0,
                gold999: Number(gold999),
                gold9999: Number(gold9999),
            });
        }
    };

    return (
        <div style={{ margin: '25px' }}>
            <div style={{ marginLeft: '30px', marginBottom: '20px' }}>
                <PageTitle size='xl'>Settings</PageTitle>
            </div>
            <Card style={styles.cardStyle}>
                {tenantStatusLoading
                    ? <PageSkeleton count={20} />
                    : (
                        <div>
                            {/* <Maintenance maintenanceSwitch={maintenanceMode} setMaintenanceSwitch={setMaintenanceMode} />
                            <GoldPriceSettings gold916B={gold916B} gold916A={gold916A} gold999={gold999} gold9999={gold9999} setGold916B={setGold916B} setGold916A={setGold916A} setGold999={setGold999} setGold9999={setGold9999} />
                            <Button primary label='Save' onClick={handleSaveClick} loading={loading} /> */}
                        </div>
                    )}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    tenantStatusLoading: Selectors.getTenantTenantStatusAttempting(state),
    tenantStatusError: Selectors.getTenantTenantStatusError(state),
    tenantStatus: Selectors.getTenantTenantStatus(state),
    loading: Selectors.getTenantSetMaintenanceAttempting(state) || Selectors.getTenantSetGoldPriceAttempting(state),
    error: Selectors.getTenantSetGoldPriceError(state) || Selectors.getTenantSetMaintenanceError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setMaintenance: (data: ISetMaintenanceParams) => dispatch(Actions.tenantSetMaintenanceAttempt({ ...data })),
    setGoldPrice: (goldPrice: ISetGoldPriceParams) => dispatch(Actions.tenantSetGoldPriceAttempt({ ...goldPrice })),
    getTenantStatus: () => dispatch(Actions.tenantGetTenantStatusAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
