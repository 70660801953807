import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IGetOrderLogsResponse, IOrderStatusEnum } from 'redux/slices/order/types';
import { IAdminOrderDetails, ICustomAttribute, IOrderPaymentMethodEnum, IPaymentTransactionStatus } from '@upshop/types';

export interface GetOrderLogsParams {
    authToken: string;
}

export interface GetOrdersParams {
    authToken: string;
    index: number;
    dateFrom?: string;
    dateTo?: string;
    statusFilter?: string;
    search?: string;
    fulfillmentStatus?: string;
    paymentStatus?: string;
    customerId?: string;
}

export interface GetOrdersResponse {
    index: number;
    maxIndex: number;
    data: [
        {
            id: string;
            shortId: string;
            customerName: string;
            orderDate: Date;
            totalPrice: number;
            totalBoughtItems: number;
            currency: string;
            fulfillmentStatus: IOrderStatusEnum;
            paymentInfo: {
                paymentMethod: IOrderPaymentMethodEnum;
                paymentStatus: IPaymentTransactionStatus;
            };
            customAttributes: ICustomAttribute[];
        },
    ],
}

export interface GetOrderDetailsParams {
    authToken: string;
    orderId: string;
}

export interface UpdateOrderStatusParams {
    authToken: string;
    orderId: string;
    status: IOrderStatusEnum;
}

export interface ApproveManualBankTransferParams {
    authToken: string;
    orderId: string;
    isApproved: boolean;
}

export interface UpdateTrackingIdParams {
    orderId: string;
    authToken: string;
    trackingId: string;
    logisticCompany: string;
}

export interface UpdateOrderTrackingInformationParams {
    authToken: string
    orderId: string;
    trackingNo: string;
    shippingCarrier: string;
    trackingUrl: string;
}

export interface AddOrderRemarksParams{
    authToken: string;
    orderId: string;
    remarks: string;
}

export interface ApproveManualBankTransferResponse {
    orderId: string;
    status: IPaymentTransactionStatus;
}

export abstract class IOrderGateway extends Gateway {
    abstract getOrders(params: GetOrdersParams): GatewayResponse<GetOrdersResponse | null>;

    abstract getOrderDetails(params: GetOrderDetailsParams): GatewayResponse<IAdminOrderDetails | null>;

    abstract updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<null>;

    abstract updateTrackingId(params: UpdateTrackingIdParams): GatewayResponse<null>;

    abstract updateTrackingInformation(params: UpdateOrderTrackingInformationParams): GatewayResponse<null>;

    abstract addOrderRemarks(params: AddOrderRemarksParams): GatewayResponse<null>;

    abstract approveManualBankTransfer(params: ApproveManualBankTransferParams): GatewayResponse<ApproveManualBankTransferResponse | null>;

    abstract exportOrder(params: GetOrdersParams): GatewayResponse<string | null>;

    abstract placeOrderLogs(params: GetOrderLogsParams): GatewayResponse<IGetOrderLogsResponse[] | null>;
}
