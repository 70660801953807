import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminOrderDetails, IPaymentTransactionStatus, OrderLogsEventType } from '@upshop/types';

import { ApproveManualBankTransferResponse, GetOrderLogsParams } from 'api/OrderBase';
import dayjs from 'dayjs';
import {
    OrderReduxState,
    IGetOrderParams,
    IGetOrdersResponse,
    IGetOrderDetailsParams,
    IUpdateOrderStatusParams,
    IApproveManualBankTransferParams,
    IOrderStatusEnum,
    IUpdateOrderTrackingIdParams,
    IOrderPaymentMethodEnum,
    IGetOrderLogsResponse,
    IAddOrderRemarksParams,
} from './types';

const initialState: OrderReduxState = {
    actions: {
        orders: false,
        orderDetails: false,
        updateOrderStatus: false,
        approveManualBankTransfer: false,
        exportOrder: false,
        orderLogs: false,
        remarks: false,
    },
    updateOrderStatus: '', // id of the order that is being updated, tracking this to render spinner
    paginationIndex: 1,
    currentPage: 1,
    filterStartDate: undefined,
    filterEndDate: undefined,
    query: '',
    filters: [],
    orders: [
        {
            index: -1,
            maxIndex: -1,
            data: [],
        },
    ],
    orderDetails: {
        id: '',
        shortId: '',
        orderDate: dayjs().toISOString(),
        products: [
            {
                id: '',
                name: '',
                categoryId: '',
                imageUrl: [],
                selectedVariant: {
                    sku: '',
                    type: [
                        {
                            name: '',
                            value: '',
                        },
                    ],
                    price: {
                        currency: '',
                        currentPrice: 0,
                        originalAmount: 0,
                        discountAmount: 0,
                    },
                    location: {
                        id: '',
                        name: '',
                    },
                    customAttributes: [
                        {
                            name: '',
                            value: '',
                        },
                    ],
                    imageUrl: [],
                },
                quantity: 0,
                remarks: '',
                customAttributes: [
                    {
                        name: '',
                        value: '',
                    },
                ],
            },
        ],
        customerRemarks: '',
        deliveryInfo: {
            trackingId: '',
            logisticsProvider: {
                id: 0,
                name: '',
                url: '',
                customAttributes: [
                    {
                        name: '',
                        value: '',
                    },
                ],
            },
            address: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                address1: '',
                address2: '',
                address3: '',
                state: '',
                city: '',
                postCode: '',
                country: '',
                customAttributes: [
                    {
                        name: '',
                        value: '',
                    },
                ],
            },
            customAttributes: [
                {
                    name: '',
                    value: '',
                },
            ],
            eta: '', // ISO8601 time format
        },
        totalPrice: 0,
        currency: '',
        shippingFee: 0,
        customerInfo: {
            firstName: '',
            lastName: '',
            phoneNumber: 0,
            email: '',
            gender: '',
        },
        remarks: '',
        paymentInfo: {
            paymentId: '',
            paymentMethod: IOrderPaymentMethodEnum.FPXOnline,
            paymentStatus: IPaymentTransactionStatus.Pending,
        },
        fulfillmentStatus: IOrderStatusEnum.PaymentProcessing,
        deliveryDetails: {
            trackingId: '',
            logisticsProvider: {
                id: 0,
                name: '',
                url: '',
                customAttributes: [
                    {
                        name: '',
                        value: '',
                    },
                ],
            },
            address: {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                address1: '',
                address2: '',
                address3: '',
                state: '',
                city: '',
                postCode: '',
                country: '',
                customAttributes: [
                    {
                        name: '',
                        value: '',
                    },
                ],
            },
            customAttributes: [
                {
                    name: '',
                    value: '',
                },
            ],
            eta: '', // ISO8601 time format
        },
        customAttribute: [
            {
                name: '',
                value: '',
            },
        ],
        customerName: '',
        customerEmail: '',
        mobileNumber: '',
        orderLogs: [
            {
                _id: '',
                orderId: '',
                eventType: OrderLogsEventType.upsertRemarks,
                createdAt: '',
                createdBy: '',
                action: '',
            },
        ],
    },
    trackingIdModalIsOpen: false,
    exportOrder: '',
    orderLogs: [],
    error: {
        orders: '',
        orderDetails: '',
        updateOrderStatus: '',
        approveManualBankTransfer: '',
        exportOrder: '',
        orderLogs: '',
        remarks: '',
    },
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        orderGetOrdersAttempt: (state, _action: PayloadAction<IGetOrderParams>) => {
            state.actions.orders = true;
            state.error.orders = '';
        },
        orderGetOrdersSuccess: (state, action: PayloadAction<IGetOrdersResponse>) => {
            state.actions.orders = false;
            if (action.payload) {
                state.orders[action.payload.index - 1] = action.payload;
            }
        },
        orderGetOrdersFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.orders = false;
            if (action.payload) {
                state.error.orders = action.payload;
            }
        },
        orderGetOrderDetailsAttempt: (state, _action: PayloadAction<IGetOrderDetailsParams>) => {
            state.actions.orderDetails = true;
            state.error.orderDetails = '';
        },
        orderGetOrderDetailsSuccess: (state, action: PayloadAction<IAdminOrderDetails>) => {
            state.actions.orderDetails = false;
            if (action.payload) {
                state.orderDetails = action.payload;
            }
        },
        orderGetOrderDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.orderDetails = false;
            if (action.payload) {
                state.error.orderDetails = action.payload;
            }
        },
        orderResetOrderDetails: (state) => {
            state.orderDetails = initialState.orderDetails;
        },
        orderUpdateStatusAttempt: (state, action: PayloadAction<IUpdateOrderStatusParams>) => {
            state.actions.updateOrderStatus = true;
            if (action.payload.orderId) {
                state.updateOrderStatus = action.payload.orderId;
            }
            state.error.updateOrderStatus = '';
        },
        orderUpdateStatusSuccess: (state, action: PayloadAction<IUpdateOrderStatusParams>) => {
            state.actions.updateOrderStatus = false;
            state.updateOrderStatus = '';

            if (action.payload.orderId === state.orderDetails.id) {
                state.orderDetails.fulfillmentStatus = action.payload.status;
            }

            if (state.orders[state.currentPage - 1]?.data.length) {
                state.orders = state.orders.map((item, index) => {
                    if (index + 1 === state.currentPage) {
                        return {
                            ...item,
                            data: item.data.map((order) => {
                                if (order.id === action.payload.orderId) {
                                    return {
                                        ...order,
                                        status: action.payload.status,
                                    };
                                }

                                return order;
                            }),
                        };
                    }
                    return item;
                });
            }
        },
        orderUpdateStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrderStatus = false;
            state.updateOrderStatus = '';
            if (action.payload) state.error.updateOrderStatus = action.payload;
        },
        orderSetPaginationIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) state.paginationIndex = action.payload;
        },
        orderSetCurrentPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.currentPage = action.payload;
        },
        orderApproveManualBankTransferAttempt: (state, _action: PayloadAction<IApproveManualBankTransferParams>) => {
            state.actions.approveManualBankTransfer = true;
            state.error.approveManualBankTransfer = '';
        },
        orderApproveManualBankTransferSuccess: (state, action: PayloadAction<ApproveManualBankTransferResponse>) => {
            state.actions.approveManualBankTransfer = false;

            if (action.payload.orderId === state.orderDetails.id) {
                state.orderDetails.paymentInfo.paymentStatus = action.payload.status;
            }

            const matchingOrder = state.orders[state.currentPage - 1].data.findIndex(item => item.id === action.payload.orderId);

            if (matchingOrder === -1) {
                let updated = false;

                for (let i = 0; i < state.orders.length; i + 1) {
                    if (!updated) {
                        for (let index = 0; i < state.orders[i].data.length; index + 1) {
                            if (state.orders[i].data[index].id === action.payload.orderId) {
                                state.orders[i].data[index].paymentInfo.paymentStatus = action.payload.status;
                                updated = true;
                                break;
                            }
                        }
                    } else if (updated) break;
                }
            } else if (matchingOrder > -1) {
                state.orders[state.currentPage - 1].data[matchingOrder].paymentInfo.paymentStatus = action.payload.status;
            }
        },
        orderApproveManualBankTransferFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.approveManualBankTransfer = false;
            if (action.payload) {
                state.error.approveManualBankTransfer = action.payload;
            }
        },
        orderUpdateTrackingIdAttempt: (state, _action: PayloadAction<IUpdateOrderTrackingIdParams>) => {
            state.actions.updateOrderStatus = true;
            state.error.updateOrderStatus = '';
        },
        orderUpdateTrackingIdSuccess: (state) => {
            state.actions.updateOrderStatus = false;
        },
        orderUpdateTrackingIdFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrderStatus = false;
            if (action.payload) {
                state.error.remarks = action.payload;
            }
        },
        orderAddOrderRemarksAttempt: (state, _action: PayloadAction<IAddOrderRemarksParams>) => {
            state.actions.updateOrderStatus = true;
            state.error.updateOrderStatus = '';
        },
        orderAddOrderRemarksSuccess: (state) => {
            state.actions.updateOrderStatus = false;
        },
        orderAddOrderRemarksFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateOrderStatus = false;
            if (action.payload) {
                state.error.updateOrderStatus = action.payload;
            }
        },
        orderSetFilterStartDate: (state, action: PayloadAction<string | undefined>) => {
            state.filterStartDate = action.payload;
            state.filterEndDate = undefined;
        },
        orderSetFilterEndDate: (state, action: PayloadAction<string | undefined>) => {
            state.filterEndDate = action.payload;
        },
        orderSetQuery: (state, action: PayloadAction<string | undefined>) => {
            state.query = action.payload;
        },
        orderSetFilters: (state, action: PayloadAction<IOrderStatusEnum[]>) => {
            state.filters = action.payload;
        },
        orderSetTrackingIdModalIsOpen: (state, action: PayloadAction<boolean>) => {
            state.trackingIdModalIsOpen = action.payload;
        },
        orderExportOrdersAttempt: (state, _action: PayloadAction<IGetOrderParams>) => {
            state.actions.exportOrder = true;
            state.error.exportOrder = '';
        },
        orderExportOrdersSuccess: (state, action: PayloadAction<string | undefined>) => {
            state.exportOrder = action.payload;
        },
        orderExportOrdersFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.exportOrder = false;
            if (action.payload) {
                state.error.exportOrder = action.payload;
            }
        },
        orderGetJemisysPlaceOrderLogsAttempt: (state, _action: PayloadAction<any>) => {
            state.actions.orderLogs = true;
            state.error.orderLogs = '';
        },
        orderGetJemisysPlaceOrderLogsSuccess: (state, action: PayloadAction<IGetOrderLogsResponse[]>) => {
            state.actions.orderLogs = false;
            if (action.payload) {
                state.orderLogs = action.payload;
            }
        },
        orderGetJemisysPlaceOrderLogsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.orders = false;
            if (action.payload) {
                state.error.orderLogs = action.payload;
            }
        },
    },
});

export type OrderState = typeof initialState;

export default {
    actions: orderSlice.actions,
    reducers: orderSlice.reducer,
};
