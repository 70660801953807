export default {
    goldCardStyle: {
        // width: '400px',
        width: '100%',
        // margin: '25px',
        padding: '25px 20px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
    },
    goldEditButton: {
        display: 'flex',
        justifyContent: 'center',
        // marginTop: '30px',
        // marginRight: '60px',
    },
    inputStyle: {
        border: 'none',
        fontFamily: 'DMSans-Bold',
        fontSize: '1.4rem',
        color: '#6989FE',
        padding: 5,
        width: '80%',
    },
};
