import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OrderGateway from 'api/Order';

import Actions from 'redux/Actions';
import { IGetOrderParams } from 'redux/slices/order/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchGetOrder(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderGetOrdersAttempt', handleGetOrder, api);
}

function* handleGetOrder(api: OrderGateway, data: PayloadAction<IGetOrderParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { index, dateFrom, dateTo, statusFilter, search = '', fulfillmentStatus, paymentStatus, customerId } = data.payload;

    if (!authToken) {
        yield put(Actions.orderGetOrdersFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.getOrders], { authToken, index, dateFrom, dateTo, statusFilter: statusFilter?.join(','), search, fulfillmentStatus: fulfillmentStatus?.join(','), paymentStatus: paymentStatus?.join(','), customerId });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderGetOrdersFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.orderGetOrdersSuccess(response.data));
            return;
        }
    }

    yield put(Actions.orderGetOrdersFailure('Something went wrong. Please try again later'));
}
