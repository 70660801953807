const dev = {
    upshopBaseUrl: 'https://kapchai-api.upshop.my',
};

const staging = {
    upshopBaseUrl: 'https://kapchai-api.upshop.my',
};

const prod = {
    upshopBaseUrl: 'https://api.upshop.my',
};

let config;

if (process.env.REACT_APP_STAGE === 'dev') {
    config = dev;
}
if (process.env.REACT_APP_STAGE === 'staging') {
    config = staging;
}
if (process.env.REACT_APP_STAGE === 'prod') {
    config = prod;
}

const env = process.env.REACT_APP_STAGE;

export default {
    ...config,
    env,
};
