import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OrderGateway from 'api/Order';

import Actions from 'redux/Actions';
import { IAddOrderRemarksParams } from 'redux/slices/order/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchAddOrderRemarks(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderAddOrderRemarksAttempt', handleAddRemarks, api);
}

function* handleAddRemarks(api: OrderGateway, data: PayloadAction<IAddOrderRemarksParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { orderId, remarks } = data.payload;

    if (!authToken) {
        yield put(Actions.orderAddOrderRemarksFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.addOrderRemarks], {
        authToken,
        orderId,
        remarks,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderAddOrderRemarksFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderAddOrderRemarksSuccess());
        yield put(Actions.orderGetOrderDetailsAttempt({ orderId }));
        return;
    }

    yield put(Actions.orderAddOrderRemarksFailure('Something went wrong. Please try again later'));
}
