import React from 'react';
import { MenuItem, Collapsible, Button } from '@upshop/exodia';
import { useLocation, useNavigate } from 'react-router-dom';

// import styles from 'containers/settings/styles';
import styles from './styles';
import Icons from '../../Icons';

const NavMenu = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const reportsTitle = (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '90%',
            padding: '20px',
            WebkitTouchCallout: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            userSelect: 'none',
        }}
        >
            <img src={Icons.pieChart} alt='piechart' style={{ width: '20px' }} />
            <div style={{ padding: '10px', fontWeight: 'bold', fontSize: '18px' }}>
                Reports
            </div>
        </div>
    );

    const reportsContent = (
        <div style={{
            display: 'flex',
            WebkitTouchCallout: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            userSelect: 'none',
            padding: '20px',
        }}
        >
            <Button
                label='Customer Report'
                style={{
                    display: 'flex',
                    justifyContent: 'start',
                    width: '100%',
                    height: '40px',
                    backgroundColor: location.pathname === '/reports/customer' ? '#FFFFFF' : '#F8F8F8',
                    color: location.pathname === '/reports/customer' ? '#6989FE' : '#000000',
                }}
                onClick={() => {
                    navigate('/reports/customers');
                }}
            />
        </div>
    );

    return (
        <div
            style={{
                maxWidth: '250px',
                minWidth: '350px',
                height: '100vh',
                backgroundColor: '#F8F8F8',
                padding: '10px',
                justifyContent: 'center',
                display: 'block',
                position: 'sticky',
                top: '0px',
                left: '0px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    WebkitTouchCallout: 'none',
                    msUserSelect: 'none',
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    userSelect: 'none',
                }}
            >
                <img src='/Group.png' alt='logo' style={{ width: '70%', height: 'auto', marginTop: '20px', marginBottom: '50px' }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        navigate('/');
                    }}
                    onKeyPress={() => {
                        navigate('/');
                    }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname === '/' ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    {
                        location.pathname === '/' ? (
                            <>
                                <img src={Icons.homeBlue} alt='' style={styles.navIcon} />
                                <p style={styles.navTitle}>Dashboard</p>
                            </>
                        ) : (
                            <>
                                <img src={Icons.home} alt='' style={styles.navIcon} />
                                <p style={styles.navTitle}>Dashboard</p>
                            </>
                        )
                    }
                </div>

                <div
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        navigate('/orders');
                    }}
                    onKeyPress={() => {
                        navigate('/orders');
                    }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname.includes('/orders') ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    {
                        location.pathname.includes('/orders') ? (
                            <>
                                <img src={Icons.cartBlue} alt='' style={styles.navIcon} />
                                <p style={styles.navTitle}>Order</p>
                            </>
                        ) : (
                            <>
                                <img src={Icons.cart} alt='' style={styles.navIcon} />
                                <p style={styles.navTitle}>Order</p>
                            </>
                        )
                    }
                </div>

                {/* <div
                    // role='button'
                    // tabIndex={0}
                    // onClick={() => {
                    //     navigate('/settings');
                    // }}
                    // onKeyPress={() => {
                    //     navigate('/settings');
                    // }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname === '/settings' ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    {
                        location.pathname === '/settings' ? (
                            <>
                                <img src={Icons.pieChart} alt='' style={{ width: 20, height: 20 }} />
                                <p style={styles.navTitle}>Report</p>
                            </>
                        ) : (
                            <>
                                <img src={Icons.pieChart} alt='' style={{ width: 20, height: 20 }} />
                                <p style={styles.navTitle}>Report</p>
                            </>
                        )
                    }
                </div> */}

                {/* <div
                    // role='button'
                    // tabIndex={0}
                    // onClick={() => {
                    //     navigate('/promotions');
                    // }}
                    // onKeyPress={() => {
                    //     navigate('/promotions');
                    // }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname === '/promotions' ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    {
                        location.pathname === '/promotions' ? (
                            <>
                                <img src={Icons.dollarBlue} alt='' style={{ width: 18, height: 18 }} />
                                <p style={styles.navTitle}>Promotions</p>
                            </>
                        ) : (
                            <>
                                <img src={Icons.dollarSign} alt='' style={{ width: 18, height: 18 }} />
                                <p style={styles.navTitle}>Promotions</p>
                            </>
                        )
                    }
                </div> */}

                <div
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        navigate('/customer');
                    }}
                    onKeyPress={() => {
                        navigate('/customer');
                    }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname.includes('/customer') ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    {
                        location.pathname.includes('/customer') ? (
                            <>
                                <img src={Icons.userBlue} alt='' style={{ width: 20, height: 20 }} />
                                <p style={styles.navTitle}>Customers</p>
                            </>
                        ) : (
                            <>
                                <img src={Icons.user} alt='' style={{ width: 20, height: 20 }} />
                                <p style={styles.navTitle}>Customers</p>
                            </>
                        )
                    }
                </div>

                {/* <MenuItem
                    sourceIcon={Icons.home}
                    itemLabel='Homepage'
                    hasBadge={false}
                    badgeContent=''
                    isSelected={location.pathname === '/'}
                    onClick={() => {
                        navigate('/');
                    }}
                /> */}
                {/* <MenuItem
                    sourceIcon={Icons.cart}
                    itemLabel='Orders'
                    hasBadge={false}
                    badgeContent=''
                    isSelected={location.pathname === '/orders'}
                    onClick={() => {
                        navigate('/orders');
                    }}
                /> */}
                {/* <MenuItem
                    sourceIcon={Icons.shoppingBag}
                    itemLabel='Promotions'
                    hasBadge={false}
                    badgeContent=''
                    isSelected={location.pathname === '/p'}
                    onClick={() => {
                        navigate('/');
                    }}
                /> */}
                {/* <MenuItem
                    sourceIcon={Icons.shoppingBag}
                    itemLabel='Categories'
                    hasBadge={false}
                    badgeContent=''
                    isSelected={location.pathname === '/categories'}
                    onClick={() => {
                        navigate('/categories');
                    }}
                /> */}
                {/* <Collapsible
                    initialWidth='230px'
                    initialHeight='45px'
                    expandedHeight='auto'
                    hoverColor='#E3E3E3'
                    backgroundColor='#E3E3E3'
                    style={{ margin: '0px', border: 0, borderRadius: '12px' }}
                    title={reportsTitle}
                    content={reportsContent}
                /> */}
            </div>

            <div style={{ position: 'absolute', bottom: '10px' }}>
                {/* <div
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        navigate('/settings');
                    }}
                    onKeyPress={() => {
                        navigate('/settings');
                    }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname === '/settings' ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    <img src={Icons.cog} alt='' style={styles.navIcon} />
                    <p style={styles.navTitle}>Settings</p>
                </div> */}

                <div
                    role='button'
                    tabIndex={0}
                    onClick={() => {
                        navigate('/login');
                        window.location.reload();
                    }}
                    onKeyPress={() => {
                        navigate('/login');
                        window.location.reload();
                    }}
                    style={{ display: 'flex', alignItems: 'center', gap: 10, borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: `${location.pathname === '/login' ? 'white' : 'rgb(248, 248, 248)'}` }}
                >
                    <img src={Icons.logout} alt='' style={styles.navIcon} />
                    <p style={styles.navTitle}>Sign Out</p>
                </div>

                {/* <MenuItem
                    sourceIcon={Icons.cog}
                    itemLabel='Settings'
                    hasBadge={false}
                    badgeContent=''
                    isSelected={location.pathname === '/settings'}
                    onClick={() => {
                        navigate('/settings');
                    }}
                /> */}
                {/* <MenuItem
                    sourceIcon={Icons.logout}
                    itemLabel='Sign Out'
                    hasBadge={false}
                    badgeContent=''
                    onClick={() => {
                        navigate('/login');
                        // TODO: handle signout
                    }}
                /> */}
            </div>
        </div>
    );
};

export default NavMenu;
