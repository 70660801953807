import { ICartProduct, ICustomAttribute, IPaymentTransactionStatus } from '@upshop/types';
import { IOrderStatusEnum, IOrderPaymentMethodEnum } from 'redux/slices/order/types';

type Status = 'Success' | 'Failed';

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
    getHeaders: (authToken: string): { headers: { Authorization: string; } } => {
        return { headers: {
            Authorization: `Bearer ${authToken}`,
        } };
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
};

const CustomAttr = {
    getValueFromKey: (key: string, attributes: ICustomAttribute[]): string | number | null => {
        if (!attributes.length) return null;
        const item = attributes.find(i => i.name === key);

        if (item) return item.value;
        return null;
    },
};

const Order = {
    getStatus: (status: IOrderStatusEnum | undefined): string | boolean => {
        if (status === IOrderStatusEnum.PendingPayment) return 'Pending Payment';
        if (status === IOrderStatusEnum.PaymentFailed) return 'Payment Failed';
        if (status === IOrderStatusEnum.PaymentProcessing) return 'Payment Processing';
        if (status === IOrderStatusEnum.Unfulfilled) return 'Unfulfilled';
        if (status === IOrderStatusEnum.Shipped) return 'Shipped';
        if (status === IOrderStatusEnum.Completed) return 'Completed';
        if (status === IOrderStatusEnum.Cancelled) return 'Cancelled';
        if (status === IOrderStatusEnum.Refunded) return 'Refunded';
        return false;
    },
    getFulfillmentStatus: (status: IOrderStatusEnum | undefined): string | boolean => {
        if (status === IOrderStatusEnum.PendingPayment) return 'Pending Payment';
        if (status === IOrderStatusEnum.PaymentFailed) return 'Payment Failed';
        if (status === IOrderStatusEnum.PaymentProcessing) return 'Payment Processing';
        if (status === IOrderStatusEnum.Unfulfilled) return 'Unfulfilled';
        if (status === IOrderStatusEnum.Shipped) return 'Shipped';
        if (status === IOrderStatusEnum.Completed) return 'Completed';
        if (status === IOrderStatusEnum.Cancelled) return 'Cancelled';
        if (status === IOrderStatusEnum.Refunded) return 'Refunded';
        return false;
    },
    getPaymentStatus: (status: IPaymentTransactionStatus | undefined): string | boolean => {
        if (status === IPaymentTransactionStatus.Pending) return 'Pending';
        if (status === IPaymentTransactionStatus.Success) return 'Success';
        if (status === IPaymentTransactionStatus.Failed) return 'Failed';
        if (status === IPaymentTransactionStatus.Processing) return 'Processing';
        return false;
    },
    // getPaymentStatus: (status: IOrderStatusEnum | undefined): string | boolean => {
    //     if (status === 0 || status === 1) return 'Unpaid';
    //     if (status === 2) return 'Processing Payment';
    //     if (status === 3 || status === 4 || status === 5) return 'Paid';
    //     return false;
    // },
    getDeliveryStatus: (status: IOrderStatusEnum | undefined): string | boolean => {
        if (status === 0 || status === 1 || status === 2 || status === 3) return 'Not delivered';
        if (status === 4 || status === 5) return 'Delivered';
        if (status === 6) return 'Cancelled';
        if (status === 7) return 'Refunded';
        return false;
    },
    getPaymentMethod: (status: IOrderPaymentMethodEnum): string | boolean => {
        if (status === 1) return 'Online Payment';
        if (status === 2) return 'Credit Card Payment';
        if (status === 3) return 'Bank Transfer';
        if (status === 4) return 'eGHL';
        return false;
    },
    getLocation: (order: ICartProduct): string | undefined => {
        const { selectedVariant } = order;

        if (!selectedVariant.customAttributes) return undefined;

        const location = CustomAttr.getValueFromKey('Location', selectedVariant.customAttributes) as string;

        return location;
    },
};

const OrderColor = {
    getFulfillmentBgColor: (status: IOrderStatusEnum | undefined): string | boolean => {
        if (status === IOrderStatusEnum.Unfulfilled) return '#FF99001A';
        if (status === IOrderStatusEnum.Shipped) return '#00ADD21A';
        if (status === IOrderStatusEnum.Completed) return '#38AE001A';
        if (status === IOrderStatusEnum.Refunded) return '#E800001A';
        return false;
    },
    getFulfillmentTextColor: (status: IOrderStatusEnum | undefined): string | boolean => {
        if (status === IOrderStatusEnum.Unfulfilled) return '#FF9900';
        if (status === IOrderStatusEnum.Shipped) return '#00ADD2';
        if (status === IOrderStatusEnum.Completed) return '#38AE00';
        if (status === IOrderStatusEnum.Refunded) return '#E80000';
        return false;
    },

    getPaymentBgColor: (status: IPaymentTransactionStatus | undefined): string | boolean => {
        if (status === IPaymentTransactionStatus.Pending) return '#9672FF1A';
        if (status === IPaymentTransactionStatus.Success) return '#38AE001A';
        if (status === IPaymentTransactionStatus.Processing) return '#FF99001A';
        if (status === IPaymentTransactionStatus.Failed) return '#666F7E1A';
        return false;
    },
    getPaymentTextColor: (status: IPaymentTransactionStatus | undefined): string | boolean => {
        if (status === IPaymentTransactionStatus.Pending) return '#9672FF';
        if (status === IPaymentTransactionStatus.Success) return '#38AE00';
        if (status === IPaymentTransactionStatus.Processing) return '#FF9900';
        if (status === IPaymentTransactionStatus.Failed) return '#666F7E';
        return false;
    },
};

export default {
    Auth,
    LocalStorage,
    Order,
    OrderColor,
    CustomAttr,
};
