import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantReduxState, ISetMaintenanceParams, ISetGoldPriceParams, IAdminTenantStatus, ISetTogggableGoldPriceParams } from './types';

const initialState: TenantReduxState = {
    actions: {
        tenantStatus: false,
        setMaintenance: false,
        setGoldPrice: false,
        setToggableGoldPrice: false,
    },
    tenantStatus: {
        isMaintenance: false,
        goldPrice: {
            gold375G: 0,
            gold750: 0,
            gold916B: 0,
            gold916A: 0,
            gold916: 0,
            gold925: 0,
            gold999: 0,
            gold9999: 0,
        },
        toggableGoldPrice: {
            gold375G: {
                isActive: false,
                value: 0,
            },
            gold750: {
                isActive: false,
                value: 0,
            },
            gold916: {
                isActive: false,
                value: 0,
            },
            gold916A: {
                isActive: false,
                value: 0,
            },
            gold916B: {
                isActive: false,
                value: 0,
            },
            gold925: {
                isActive: false,
                value: 0,
            },
            gold999: {
                isActive: false,
                value: 0,
            },
            gold9999: {
                isActive: false,
                value: 0,
            },
        },
    },
    error: {
        tenantStatus: '',
        setMaintenance: '',
        setGoldPrice: '',
        setToggableGoldPrice: '',
    },
};

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers: {
        tenantGetTenantStatusAttempt: (state) => {
            state.actions.tenantStatus = true;
            state.error.tenantStatus = '';
        },
        tenantGetTenantStatusSuccess: (state, action: PayloadAction<IAdminTenantStatus>) => {
            state.actions.tenantStatus = false;
            if (action.payload) state.tenantStatus = action.payload;
        },
        tenantGetTenantStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.tenantStatus = false;
            if (action.payload) state.error.tenantStatus = action.payload;
        },
        tenantSetMaintenanceAttempt: (state, _action: PayloadAction<ISetMaintenanceParams>) => {
            state.actions.setMaintenance = true;
            state.error.setMaintenance = '';
        },
        tenantSetMaintenanceSuccess: (state, action: PayloadAction<ISetMaintenanceParams>) => {
            state.actions.setMaintenance = false;
            if (action.payload) state.tenantStatus.isMaintenance = action.payload.isMaintenance;
        },
        tenantSetMaintenanceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setMaintenance = false;
            if (action.payload) state.error.setMaintenance = action.payload;
        },
        tenantSetGoldPriceAttempt: (state, _action: PayloadAction<ISetGoldPriceParams>) => {
            state.actions.setGoldPrice = true;
            state.error.setGoldPrice = '';
        },
        tenantSetGoldPriceSuccess: (state, action: PayloadAction<ISetGoldPriceParams>) => {
            state.actions.setGoldPrice = false;
            if (action.payload) state.tenantStatus.goldPrice = action.payload;
        },
        tenantSetGoldPriceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setGoldPrice = false;
            if (action.payload) state.error.setGoldPrice = action.payload;
        },
        tenantSetToggableGoldPriceAttempt: (state, _action: PayloadAction<ISetTogggableGoldPriceParams>) => {
            state.actions.setToggableGoldPrice = true;
            state.error.setToggableGoldPrice = '';
        },
        tenantSetToggableGoldPriceSuccess: (state, action: PayloadAction<ISetTogggableGoldPriceParams>) => {
            state.actions.setToggableGoldPrice = false;
            if (action.payload) state.tenantStatus.toggableGoldPrice = action.payload;
        },
        tenantSetToggableGoldPriceFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setToggableGoldPrice = false;
            if (action.payload) state.error.setToggableGoldPrice = action.payload;
        },
    },
});

export type TenantState = typeof initialState;

export default {
    actions: tenantSlice.actions,
    reducers: tenantSlice.reducer,
};
