import { fork } from 'redux-saga/effects';

import api from 'api';

import auth from './auth';
import tenant from './tenant';
import order from './order';
import reports from './reports';
import products from './products';
import customer from './customer';

import { SagaForkReturnType } from './types';

export default function* root(): SagaForkReturnType {
    yield fork(auth(api.auth).rootSaga);
    yield fork(tenant(api.tenant).rootSaga);
    yield fork(order(api.order).rootSaga);
    yield fork(reports(api.reports).rootSaga);
    yield fork(products(api.products).rootSaga);
    yield fork(customer(api.customer).rootSaga);
}
