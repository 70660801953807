import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PageTitle, SearchBar, PageSkeleton, Pagination, Button, DeleteButton } from '@upshop/exodia';
import { useNavigate } from 'react-router-dom';

import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import { RootState } from 'redux/store';

import { IGetProductsCategoriesResponse, IProductCategoryStatusEnum } from 'redux/slices/products/types';
import { GetCategoriesSortEnum } from 'api/ProductsBase';
import Icons from 'Icons/index';

import Table from '../../order/Components/Table';
import styles from './styles';

interface CategoriesProps {
    categories: IGetProductsCategoriesResponse[];
    categoriesLoading: boolean;
    categoriesError: string;
    currentIndex: number;
    currentPage: number;
    query: string;
    sortQuantity: number;
    sortTitle: number;
    updatingCategoryStatus: boolean;
    updatingId: string;
    getCategories: (index: number, search?: string, sortQuantityAsc?: GetCategoriesSortEnum, sortTitleAsc?: GetCategoriesSortEnum) => void;
    setCurrentIndex: (index: number) => void;
    setCurrentPage: (page: number) => void;
    setQuery: (query: string) => void;
    setSortQuantity: (sort: number) => void;
    setSortTitle: (sort: number) => void;
    deleteCategory: (id: string) => void;
    setCategoryStatus: (id: string, status: IProductCategoryStatusEnum) => void;
}

const Categories = (props: CategoriesProps): JSX.Element => {
    const navigate = useNavigate();
    const [categoriesToDisplay, setCategoriesToDisplay] = useState<IGetProductsCategoriesResponse[]>();
    const [deleteCategoryName, setDeleteCategoryName] = useState('');
    const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
    const [deleteCategoryId, setDeleteCategoryId] = useState('');
    const [modal, setModal] = useState(false);
    const [updatingCategoryName, setUpdatingCategoryName] = useState('');
    const [updatingCategoryId, setUpdatingCategoryId] = useState('');
    const [updatingStatus, setUpdatingStatus] = useState<IProductCategoryStatusEnum>();

    const {
        categories,
        categoriesLoading,
        categoriesError,
        currentIndex,
        currentPage,
        query,
        sortQuantity,
        sortTitle,
        updatingCategoryStatus,
        updatingId,
        getCategories,
        setCurrentIndex,
        setCurrentPage,
        setQuery,
        setSortQuantity,
        setSortTitle,
        deleteCategory,
        setCategoryStatus,
    } = props;

    const handleOnError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = Icons.brokenImage;
    };

    useEffect(() => {
        if (currentPage || sortQuantity !== -1 || sortTitle !== -1) {
            getCategories(currentPage, query, sortQuantity, sortTitle);
        } if (currentPage === 1 && categories[0]?.index === -1) {
            getCategories(currentPage, query, sortQuantity, sortTitle);
        }
    }, [currentPage, sortQuantity, sortTitle]);

    const handleDeleteCategoryClick = (name: string, id: string) => {
        if (name && id) {
            setDeleteCategoryName(name);
            setDeleteCategoryId(id);
            setDeleteCategoryModal(true);
        }
    };

    const renderDeleteCategory = () => {
        return (
            <Modal size='md' isOpen={deleteCategoryModal} toggle={() => setDeleteCategoryModal(!deleteCategoryModal)}>
                <ModalHeader>
                    <div style={{ ...styles.ComponentTitle, margin: 0 }}>
                        Delete
                        {' '}
                        {deleteCategoryName}
                        ?
                    </div>
                </ModalHeader>
                <ModalBody style={{ marginLeft: '15px' }}>
                    <div style={{ display: ' flex', marginTop: '20px' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={() => {
                                setDeleteCategoryModal(false);
                            }}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={() => {
                                setDeleteCategoryModal(false);
                                deleteCategory(deleteCategoryId);
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    useEffect(() => {
        if (categories[0]?.maxIndex >= 1) {
            setCurrentIndex(currentIndex || 1);
            setCurrentPage(currentPage || 1);
        }
    }, []);

    const getStatusName = (statusNumber: IProductCategoryStatusEnum | undefined) => {
        if (statusNumber === 1) {
            return 'Active';
        } return 'Inactive';
    };

    const prepareTableData = () => {
        const Title = (
            <button
                type='button'
                onClick={() => {
                    setSortTitle(sortTitle === 0 ? 1 : 0);
                }}
                style={{ color: '#6989FE', border: 0, background: 0, outline: 'none', fontWeight: '700' }}
            >
                Title
                {' '}
                <img src={sortTitle === 0 ? Icons.sortDownArrow : Icons.sortUpArrow} alt='quantity' />
            </button>
        );

        const renderQuantity = (
            <button
                type='button'
                onClick={() => {
                    setSortQuantity(sortQuantity === 0 ? 1 : 0);
                }}
                style={{ color: '#6989FE', border: 0, background: 0, outline: 'none', fontWeight: '700' }}
            >
                Quantity
                {' '}
                <img src={sortQuantity === 0 ? Icons.sortDownArrow : Icons.sortUpArrow} alt='quantity' />
            </button>
        );

        const tableHeaders = [
            <text style={{ color: '#6989FE' }}>No.</text>,
            <text style={{ color: '#6989FE' }}>Image</text>,
            Title,
            renderQuantity,
            <text style={{ color: '#6989FE' }}>Description</text>,
            <text style={{ color: '#6989FE' }}>Status</text>,
            <text style={{ color: '#6989FE' }}>Actions</text>,
        ];

        const tableData = categories[currentPage - 1]?.data.map((category, index) => {
            const { id, name, description, status, numberOfItems, imageUrl, collectionId, customAttributes } = category;

            return [
                index + 1,
                <img alt={name} src={imageUrl === undefined ? Icons.brokenImage : imageUrl} onError={handleOnError} style={{ height: '75px', width: '75px' }} />,
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Button label={name} onClick={() => navigate('/', { state: { id } })} style={{ color: '#6989FE', border: 0, background: 0 }} /></div>,
                numberOfItems,
                description,
                status === 1 ? 'Active' : 'Inactive',
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><DeleteButton onClick={() => handleDeleteCategoryClick(name, id)} /></div>,
            ];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const handleUpdateStatus = (id: string, name: string, updateStatus: IProductCategoryStatusEnum) => {
        setModal(true);
        setUpdatingCategoryName(name);
        setUpdatingCategoryId(id);
        setUpdatingStatus(updateStatus);
    };

    const renderUpdateCategoryStatusModal = () => {
        return (
            <Modal size='md' isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ ...styles.ComponentTitle, margin: 0 }}>
                        Set
                        {' '}
                        {updatingCategoryName}
                        {' '}
                        to
                        {' '}
                        {getStatusName(updatingStatus)}
                        ?
                    </div>
                </ModalHeader>
                <ModalBody style={{ marginLeft: '15px' }}>
                    <div style={{ display: ' flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={() => {
                                setModal(false);
                            }}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={() => {
                                setModal(false);
                                setCategoryStatus(updatingCategoryId, IProductCategoryStatusEnum.Inactive);
                            }}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderTable = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            <div style={{ marginTop: '20px' }}>
                {categoriesLoading
                    ? <PageSkeleton count={10} />
                    : (
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                            {categoriesError ? <div style={{ color: 'red' }}>{categoriesError}</div> : <Table tableHeaders={tableHeaders} tableData={tableData?.length ? tableData : []} />}

                            <div style={{ position: 'absolute', bottom: '5px' }}>
                                <Pagination onClick={setCurrentPage} currentPage={currentPage} maxPages={categories[0]?.maxIndex > 0 ? categories[0]?.maxIndex : 0} index={currentIndex} setIndex={setCurrentIndex} />
                            </div>
                        </div>
                    )}
            </div>
        );
    };

    const handleSearch = (queryString: string) => {
        setCurrentIndex(1);
        setCurrentPage(1);
        getCategories(1, queryString, sortQuantity, sortTitle);
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(e.currentTarget.value.trim());
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    return (
        <div
            style={{
                margin: '25px',
                maxWidth: '1500px',
            }}
        >
            <div style={{ marginLeft: '30px', marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <PageTitle size='xl'>Categories</PageTitle>
                <Button primary style={{ width: 'auto' }} label='New Category' onClick={() => navigate('/newCategory')} />
            </div>

            <Card style={styles.CategoriesCard}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '60px' }}>
                    <div>
                        <SearchBar
                            onClick={() => handleSearch(query)}
                            onKeyDown={handleOnKeyDown}
                            label
                            value={query || ''}
                            onChange={handleOnChange}
                        />
                    </div>
                </div>
                {renderTable()}
            </Card>
            {renderDeleteCategory()}
            {renderUpdateCategoryStatusModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    categories: Selectors.getProductsCategories(state),
    categoriesLoading: Selectors.getProductsCategoriesAttempting(state),
    categoriesError: Selectors.getProductsCategoriesError(state),
    currentIndex: Selectors.getProductsCategoriesCurrentIndex(state),
    currentPage: Selectors.getProductsCategoriesCurrentPage(state),
    query: Selectors.getProductsCategoriesQuery(state),
    sortQuantity: Selectors.getProductsCategoriesSortQuantity(state),
    sortTitle: Selectors.getProductsCategoriesSortTitle(state),
    updatingCategoryStatus: Selectors.getProductsUpdateCategoryStatusAttempting(state),
    updatingId: Selectors.getProductsUpdateCategoryStatus(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getCategories: (index: number, search?: string, sortQuantityAsc?: GetCategoriesSortEnum, sortTitleAsc?: GetCategoriesSortEnum) => dispatch(Actions.productsGetCategoriesAttempt({ index, search, sortQuantityAsc, sortTitleAsc })),
    setCurrentIndex: (index: number) => dispatch(Actions.productsSetCurrentIndex(index)),
    setCurrentPage: (page: number) => dispatch(Actions.productsSetCurrentPage(page)),
    setQuery: (query: string) => dispatch(Actions.productsSetQuery(query)),
    setSortQuantity: (sort: number) => dispatch(Actions.productsSetSortQuantity(sort)),
    setSortTitle: (sort: number) => dispatch(Actions.productsSetSortTitle(sort)),
    deleteCategory: (id: string) => dispatch(Actions.productsDeleteCategoryAttempt({ id })),
    setCategoryStatus: (id: string, status: IProductCategoryStatusEnum) => dispatch(Actions.productsSetCategoryStatusAttempt({ id, status })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
