import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import Utils from 'lib/Utils';
import {
    UpdateOrderStatusParams,
    UpdateTrackingIdParams,
} from './OrderBase';
import { ApproveManualBankTransferParams, ApproveManualBankTransferResponse, GetCustomerDetailsParams, GetCustomerDetailsResponse, GetCustomersParams, GetCustomersResponse, ICustomerGateway } from './CustomerBase';

export default class CustomerGateway extends ICustomerGateway {
    async getCustomers(params: GetCustomersParams): GatewayResponse<GetCustomersResponse | null> {
        const { authToken, ...restGetCustomerParams } = params;

        const response: ApiResponse<GetCustomersResponse> = await this.api.get('/tenant/customers', restGetCustomerParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async getCustomerDetails(params: GetCustomerDetailsParams): GatewayResponse<GetCustomerDetailsResponse | null> {
        const { authToken, customerId } = params;

        const response: ApiResponse<GetCustomerDetailsResponse> = await this.api.get(`/tenant/customers/${customerId}`, {}, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<null> {
        const { authToken, ...restUpdateOrderStatusParams } = params;

        const response: ApiResponse<null> = await this.api.put('/tenant/orders/status', restUpdateOrderStatusParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async updateTrackingId(params: UpdateTrackingIdParams): GatewayResponse<null> {
        const { authToken, trackingId, orderId, logisticCompany } = params;

        const response: ApiResponse<null> = await this.api.post('/tenant/orders/addTracking', {
            trackingId,
            orderId,
            logisticCompany,
        }, Utils.Auth.getHeaders(authToken));

        return this.process(response);
    }

    async approveManualBankTransfer(params: ApproveManualBankTransferParams): GatewayResponse<ApproveManualBankTransferResponse | null> {
        const { authToken, ...restApproveManualBankTransferParams } = params;

        const response: ApiResponse<ApproveManualBankTransferResponse> = await this.api.put('/tenant/orders/manualBankTransfer', restApproveManualBankTransferParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async exportCustomer(params: GetCustomersParams): GatewayResponse<string | null> {
        const { authToken, ...restGetOrderParams } = params;

        const response: ApiResponse<string> = await this.api.get('/tenant/customers/export', restGetOrderParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }
}
