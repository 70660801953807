import { IAdminOrderDetails } from '@upshop/types';
import { IGetOrderLogsResponse, IGetOrdersResponse, IOrderStatusEnum } from './types';
import { OrderState } from '.';

const getOrdersAttempting = (state: OrderState): boolean => state.actions.orders || false;
const getOrdersError = (state: OrderState): string => state.error.orders || '';
const getOrders = (state: OrderState): IGetOrdersResponse[] => state.orders || [];

const getOrderDetailsAttempting = (state: OrderState): boolean => state.actions.orderDetails || false;
const getOrderDetailsError = (state: OrderState): string => state.error.orderDetails || '';
const getOrderDetails = (state: OrderState): IAdminOrderDetails => state.orderDetails || {};

const getUpdateOrderStatusAttempting = (state: OrderState): boolean => state.actions.updateOrderStatus || false;
const getUpdateOrderStatusError = (state: OrderState): string => state.error.updateOrderStatus || '';
const getUpdateOrderStatus = (state: OrderState): string => state.updateOrderStatus || ''; // id of the order that is being updated, tracking this to render spinner

const getPaginationIndex = (state: OrderState): number => state.paginationIndex || 1;
const getCurrentPage = (state: OrderState): number => state.currentPage || 1;

const getApproveManualBankTransferAttempting = (state: OrderState): boolean => state.actions.approveManualBankTransfer || false;
const getApproveManualBankTransferError = (state: OrderState): string => state.error.approveManualBankTransfer || '';

const getFilterStartDate = (state: OrderState): string | undefined => state.filterStartDate || '';
const getFilterEndDate = (state: OrderState): string | undefined => state.filterEndDate || '';

const getQuery = (state: OrderState): string => state.query || '';
const getFilters = (state: OrderState): IOrderStatusEnum[] => state.filters || [];
const getTrackingIdModalIsOpen = (state: OrderState): boolean => state.trackingIdModalIsOpen || false;

const getOrderExportAttempting = (state: OrderState): boolean => state.actions.exportOrder || false;
const getOrderExportError = (state: OrderState): string => state.error.exportOrder || '';
const getOrderExport = (state: OrderState): string => state.exportOrder || '';

const getJemisysPlaceOrderLogsAttempting = (state: OrderState): boolean => state.actions.orderLogs || false;
const getJemisysPlaceOrderLogsError = (state: OrderState): string => state.error.orderLogs || '';
const getJemisysPlaceOrderLogs = (state: OrderState): IGetOrderLogsResponse[] => state.orderLogs || [];

export default {
    getOrdersAttempting,
    getOrdersError,
    getOrders,

    getOrderDetailsAttempting,
    getOrderDetailsError,
    getOrderDetails,

    getUpdateOrderStatusAttempting,
    getUpdateOrderStatusError,
    getUpdateOrderStatus,

    getPaginationIndex,
    getCurrentPage,

    getApproveManualBankTransferAttempting,
    getApproveManualBankTransferError,

    getFilterStartDate,
    getFilterEndDate,

    getQuery,
    getFilters,
    getTrackingIdModalIsOpen,

    getOrderExportAttempting,
    getOrderExportError,
    getOrderExport,

    getJemisysPlaceOrderLogsAttempting,
    getJemisysPlaceOrderLogsError,
    getJemisysPlaceOrderLogs,
};
