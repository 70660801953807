import React, { useEffect } from 'react';
import { PageTitle, BackButton, Button, SearchBar, PageSkeleton } from '@upshop/exodia';

import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { useNavigate, useParams } from 'react-router-dom';

import { IOrderStatusEnum, IGetOrderParams, IGetOrdersResponse } from 'redux/slices/order/types';

import Utils from 'lib/Utils';

import { Card } from 'reactstrap';
import Icons from 'Icons';
import dayjs from 'dayjs';
import { GetCustomerDetailsResponse } from 'api/CustomerBase';
import CustomerDetailsComponent from './Components/CustomerDetailsComponent';

import Styles from './styles';
import Table from './Components/Table';

interface OrderDetailsProps {
    customerDetails: GetCustomerDetailsResponse;
    customerDetailsError: string;
    customerDetailsLoading: boolean;
    orders: IGetOrdersResponse[];
    query: string | undefined;
    index: number;
    currentPage: number;
    getCustomerDetails: (customerId: string) => void;
    getOrders: (params: IGetOrderParams) => void;
    setQuery: (query: string | undefined) => void;
    setIndex: (index: number) => void;
    setCurrentPage: (page: number) => void;
}

const OrderDetails = (props: OrderDetailsProps): JSX.Element => {
    const {
        customerDetails,
        customerDetailsError,
        customerDetailsLoading,
        orders,
        query,
        index,
        currentPage,
        getCustomerDetails,
        getOrders,
        setQuery,
        setIndex,
        setCurrentPage,
    } = props;

    const { customerId } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setQuery('');
        getOrders({ index, search: undefined, customerId });
    }, []);

    useEffect(() => {
        if (customerId) {
            getCustomerDetails(customerId);
            getOrders({ index, search: undefined, customerId });
        }

        if (orders[0]?.maxIndex >= 1) {
            setIndex(index || 1);
            setCurrentPage(currentPage || 1);
        }
    }, [customerId]);

    const handleSearch = (queryString: string | undefined) => {
        setCurrentPage(1);
        setIndex(1);
        getOrders({ index: 1, search: queryString?.trim() || '', customerId });
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(e.currentTarget.value.trim());
        }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const prepareTableData = () => {
        const tableHeaders = ['Order Id', 'Order Date', 'Customer', 'Fulfillment Status', 'Payment Status', 'Total(RM)', 'Actions'];

        const tableData = orders[currentPage - 1]?.data.map(order => {
            const { id, shortId, orderDate, customerName, fulfillmentStatus, paymentInfo, totalBoughtItems, totalPrice, currency } = order;

            const dropdownBlock = (
                <div hidden={!id} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '150px', paddingTop: 10, paddingBottom: 10, overflow: 'hidden', backgroundColor: `${Utils.OrderColor.getFulfillmentBgColor(fulfillmentStatus) || '#666F7E1A'}`, color: `${Utils.OrderColor.getFulfillmentTextColor(fulfillmentStatus) || '#666F7E'}`, border: 'none', borderRadius: 8 }}>
                        <p style={{ margin: 0 }}>{Utils.Order.getFulfillmentStatus(fulfillmentStatus) || 'No status'}</p>
                    </div>
                </div>
            );

            const paymentBlock = (
                <div hidden={!id} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '150px', paddingTop: 10, paddingBottom: 10, overflow: 'hidden', backgroundColor: `${Utils.OrderColor.getPaymentBgColor(paymentInfo.paymentStatus) || '#666F7E1A'}`, color: `${Utils.OrderColor.getPaymentTextColor(paymentInfo.paymentStatus) || '#666F7E'}`, border: 'none', borderRadius: 8 }}>
                        <p style={{ margin: 0 }}>{Utils.Order.getPaymentStatus(paymentInfo.paymentStatus) || 'No status'}</p>
                    </div>
                </div>
            );

            const actionDelBtn = (
                <div>
                    <img src={Icons.trash} alt='delete' style={{ width: 25, height: 25, cursor: 'pointer' }} />
                </div>
            );

            return [
                <Button
                    style={{
                        background: 0,
                        outline: 'none',
                        border: 0,
                        color: '#4E6DDD',
                        fontFamily: 'DMSans-Bold',
                        fontSize: '1.2rem',
                    }}
                    buttonCss={[
                        `
                        width: 100%;
                    `,
                    ]}
                    label={shortId}
                    onClick={() => navigate(`/orders/${id}`)}
                />,
                dayjs(orderDate).format('DD/MM/YYYY'),
                customerName,
                // dropdownButton,
                dropdownBlock,
                // paymentStatus,
                // paymentButton,
                paymentBlock,
                // totalBoughtItems,
                currency.concat(totalPrice.toString()),
                actionDelBtn,
            ];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const renderComponents = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            <Card style={{ marginTop: '10px', display: 'flex', width: '100%', minHeight: 700, padding: 40, border: '1px solid rgb(0,0,0,0.1)', borderRadius: '15px', boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)' }}>
                <div>
                    <CustomerDetailsComponent
                        data={customerDetails}
                        customerDetailsLoading={customerDetailsLoading}
                    />
                </div>

                <div style={{ paddingTop: 30 }}>
                    <div style={{ paddingBottom: 50 }}>
                        <SearchBar
                            onClick={() => handleSearch(query)}
                            label
                            onKeyDown={(e) => handleOnKeyDown(e)}
                            value={query || ''}
                            onChange={handleOnChange}
                        />
                    </div>

                    {
                        customerDetailsLoading ? (
                            <PageSkeleton count={10} />
                        ) : (
                            <Table tableHeaders={tableHeaders} tableData={tableData?.length ? tableData : []} />
                        )
                    }
                </div>
            </Card>
        );
    };

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    margin: '10px 25px',
                    // maxWidth: '1220px',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <BackButton
                        style={Styles.OrderDetailsBackButton}
                        onClick={() => navigate('/customer')}
                    />

                    {/* {orderDetailsError
                        ? (<PageTitle size='large' color='red'>{orderDetailsError}</PageTitle>)
                        : ( */}
                    <PageTitle size='large'>
                        {`Customer : ${customerDetails.name}`}
                    </PageTitle>
                    {/* )} */}
                </div>

                <div
                    hidden={customerDetailsError.length > 0}
                    style={{
                        marginLeft: '70px',
                    }}
                >
                    <PageTitle size='small'>
                        {customerDetails.totalRevenue > 0 ? `Total Order : MYR ${customerDetails.totalRevenue}` : ''}
                    </PageTitle>
                </div>
                {renderComponents()}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    customerDetails: Selectors.getCustomerCustomersDetails(state),
    customerDetailsError: Selectors.getCustomerCustomersDetailsError(state),
    customerDetailsLoading: Selectors.getCustomerCustomersDetailsAttempting(state),

    orders: Selectors.getOrderOrders(state),
    query: Selectors.getOrderQuery(state),
    index: Selectors.getOrderPaginationIndex(state),
    currentPage: Selectors.getOrderCurrentPage(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getCustomerDetails: (customerId: string) => dispatch(Actions.customerGetCustomersDetailsAttempt({ customerId })),

    getOrders: (params: IGetOrderParams) => dispatch(Actions.orderGetOrdersAttempt({ ...params })),
    setQuery: (query: string | undefined) => dispatch(Actions.orderSetQuery(query)),
    setIndex: (index: number) => dispatch(Actions.orderSetPaginationIndex(index)),
    setCurrentPage: (page: number) => dispatch(Actions.orderSetCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
