import React from 'react';
import { Card } from 'reactstrap';
import { PageSkeleton, Label } from '@upshop/exodia';

import { GetCustomerDetailsResponse } from 'api/CustomerBase';

interface CustomerDetailsProps {
    data: GetCustomerDetailsResponse | undefined;
    customerDetailsLoading: boolean;
}

const CustomerDetails = (props: CustomerDetailsProps): JSX.Element => {
    const { data, customerDetailsLoading } = props;

    return (
        <>
            {
                customerDetailsLoading ? (
                    <PageSkeleton count={10} />
                ) : (
                    <Card style={{ padding: 40, border: '1px solid rgb(0,0,0,0.1)', borderRadius: '15px', boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <Label label='Total Order' text={data?.totalOrder || '-'} />
                                <Label label='Total Revenue' text={data?.totalRevenue || '-'} />
                                <Label label='Average Revenue Per Order' text={data?.averageRevenuePerOrder.toFixed(2) || '-'} />
                            </div>

                            <div style={{ flex: 1, justifyContent: 'space-between' }}>
                                <Label label='Email' text={data?.email || '-'} />
                                <Label label='Phone' text={data?.mobileNumber || '-'} />
                                <div style={{ flex: 1, width: '100%' }}>
                                    <Label label='Address' text={(`${data?.address.address1} ${data?.address.address2} ${data?.address.address3 ?? ''}, ${data?.address.postCode}, ${data?.address.city}, ${data?.address.state}`) || '-'} />
                                </div>
                            </div>
                        </div>
                    </Card>
                )
            }
        </>

    );
};

export default CustomerDetails;
