import { IAdminOrder, IAdminOrderDetails, IPaymentTransactionStatus } from '@upshop/types';

export interface OrderReduxState {
    actions: {
        orders: boolean;
        orderDetails: boolean;
        updateOrderStatus: boolean;
        approveManualBankTransfer: boolean;
        exportOrder: boolean;
        orderLogs: boolean;
        remarks: boolean;
    };
    updateOrderStatus: string;
    paginationIndex: number;
    currentPage: number;
    filterStartDate: string | undefined;
    filterEndDate: string | undefined;
    query: string | undefined;
    filters: IOrderStatusEnum[],
    orders: IGetOrdersResponse[];
    orderDetails: IAdminOrderDetails;
    trackingIdModalIsOpen: boolean;
    exportOrder?: string;
    orderLogs?: IGetOrderLogsResponse[];
    error: {
        orders: string;
        orderDetails: string;
        updateOrderStatus: string;
        approveManualBankTransfer: string;
        exportOrder: string;
        orderLogs: string;
        remarks: string;
    };
}

export interface IGetOrderLogsResponse {
    _id: string;
    params: string;
    status: number;
    message: string;
    tenantName: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export interface IGetOrdersResponse {
    index: number;
    maxIndex: number;
    data: IAdminOrder[],
}

export interface IGetOrderParams {
    index: number;
    dateFrom?: string;
    dateTo?: string;
    statusFilter?: IOrderStatusEnum[];
    search?: string;
    fulfillmentStatus?: IOrderStatusEnum[];
    paymentStatus?: IPaymentTransactionStatus[];
    customerId?: string;
}

export enum IOrderStatusEnum {
    PendingPayment = 0, // only for manual bank transfer (upload transfer slip module)
    PaymentFailed = 1,
    PaymentProcessing = 2,
    Unfulfilled = 3, // merchant has not shipped product (packing)
    Shipped = 4, // merchant has shipped product
    Completed = 5, // buyer has received item
    Cancelled = 6,
    Refunded = 7,
}

export enum IFulfillmentStatusEnum {
    Unfulfilled = 0,
    Shipped = 1,
    Delivered = 2,
    Cancelled = 3,
}

export enum IPaymentStatusEnum {
    Unpaid = 0,
    Paid = 1,
    Processing = 2,
    Refunded = 3,
}

export interface IGetOrderDetailsParams {
    orderId: string;
}

export enum IOrderPaymentMethodEnum {
    FPXOnline = 1,
    CreditCard = 2,
    BankTransfer = 3,
    eGHL = 4,
}

export interface IUpdateOrderStatusParams {
    orderId: string,
    status: IOrderStatusEnum,
}

export interface IApproveManualBankTransferParams {
    orderId: string;
    isApproved: boolean;
}

export interface IDeleteCategoryParams {
    id: string;
}

export interface IUpdateOrderTrackingIdParams {
    orderId: string;
    trackingNo: string;
    shippingCarrier: string;
    trackingUrl: string;
}

export interface IAddOrderRemarksParams {
    orderId: string;
    remarks: string;
}
