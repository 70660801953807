import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Selectors from 'redux/Selectors';
import NavMenu from './Components/NavMenu';

const PrivateRoute = (): JSX.Element => {
    const isAuthenticated = useSelector(Selectors.getAuthAuthToken);

    if (!isAuthenticated) return <Navigate replace to='/login' />;

    return (
        <div style={{ display: 'flex' }}>
            <NavMenu />
            <div
                style={{ width: '100%' }}
            >
                <Outlet />
            </div>
        </div>
    );
};

export default PrivateRoute;
