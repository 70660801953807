import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import ProductsGateway from 'api/Products';
import { SagaWatcherReturnType } from 'sagas/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { IDeleteCategoryParams } from 'redux/slices/order/types';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Toast from 'lib/Toast';

export default function* watchDeleteCategory(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsDeleteCategoryAttempt', handleDeleteCategory, api);
}

function* handleDeleteCategory(api: ProductsGateway, data: PayloadAction<IDeleteCategoryParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id } = data.payload;

    if (!authToken) {
        yield put(Actions.productsDeleteCategoryFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    const response = yield* call([api, api.deleteCategory], { authToken, id });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsDeleteCategoryFailure(response.message));
        Toast.showError(response.message || 'Something went wrong. Please try again.');
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.productsDeleteCategorySuccess({ id }));
        Toast.show('Category successfully deleted!');
        return;
    }

    Toast.showError('Something went wrong. Please try again.');
    yield put(Actions.productsDeleteCategoryFailure('Something went wrong. Please try again later'));
}
