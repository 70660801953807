import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginActionPayload, AuthReduxState, ResetPasswordPayload } from './types';

const initialState: AuthReduxState = {
    actions: {
        login: false,
        startup: true,
        resetPassword: false,
    },
    authToken: '',
    resetPasswordSuccess: false,
    error: {
        login: '',
        startup: '',
        resetPassword: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLoginAttempt: (state, _action: LoginActionPayload) => {
            state.actions.login = true;
            state.error.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.actions.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.login = false;
            if (action.payload) {
                state.error.login = action.payload;
            }
        },
        authStartupAttempt: (state) => {
            state.actions.startup = true;
            state.error.startup = '';
        },
        authStartupSuccess: (state) => {
            state.actions.startup = false;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.startup = false;
            if (action.payload) state.error.startup = action.payload;
        },
        authResetPasswordAttempt: (state, _action: ResetPasswordPayload) => {
            state.actions.resetPassword = true;
            state.error.resetPassword = '';
            state.resetPasswordSuccess = false;
        },
        authResetPasswordSuccess: (state) => {
            state.actions.resetPassword = false;
            state.resetPasswordSuccess = true;
        },
        authResetPasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.resetPassword = false;
            if (action.payload) state.error.resetPassword = action.payload;
        },
    },
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
