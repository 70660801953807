import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IProductsGateway, GetCategoriesParams, GetCategoriesResponse, UpdateCategoryStatusParams, CreateCategoryParams, DeleteCategoryParams } from './ProductsBase';

export default class ProductsGateway extends IProductsGateway {
    async getCategories(params: GetCategoriesParams): GatewayResponse<GetCategoriesResponse | null> {
        const { authToken, ...restGetCategoriesParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<GetCategoriesResponse> = await this.api.get('/tenant/products/categories', restGetCategoriesParams, getHeaders());
        return this.process(response);
    }

    async createCategory(params: CreateCategoryParams): GatewayResponse<string | null> {
        const { authToken, ...restCreateCategoryParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<string> = await this.api.post('/tenant/products/category', restCreateCategoryParams, getHeaders());
        return this.process(response);
    }

    async deleteCategory(params: DeleteCategoryParams): GatewayResponse<null> {
        const { authToken, id } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.delete('tenant/products/categories', { id }, getHeaders());
        return this.process(response);
    }

    async updateCategoryStatus(params: UpdateCategoryStatusParams): GatewayResponse<null> {
        const { authToken, ...updateCategoryStatusParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.put('/tenant/products/category', updateCategoryStatusParams, getHeaders());
        return this.process(response);
    }
}
