import { GetCategoriesSortEnum } from 'api/ProductsBase';

export interface ProductsReduxState {
    actions: {
        categories: boolean;
        deleteCategory: boolean;
        updateCategoryStatus: boolean;
        createProductCategory: boolean;
    };
    currentPage: number;
    currentIndex: number;
    query: string;
    sortQuantity: number;
    sortTitle: number;
    categories: IGetProductsCategoriesResponse[];
    deleteCategory: string;
    updateCategoryStatus: string;
    createProductCategory: string;
    error: {
        categories: string;
        deleteCategory: string;
        updateCategoryStatus: string;
        createProductCategory: string;
    };
}

export interface IGetCategoriesParams {
    index: number;
    search?: string;
    sortQuantityAsc?: GetCategoriesSortEnum;
    sortTitleAsc?: GetCategoriesSortEnum;
}

export interface IGetProductsCategoriesResponse {
    index: number;
    maxIndex: number;
    data: IAdminProductCategory[];
}

export enum IProductCategoryStatusEnum {
    Active = 1, // active and ready to go
    Inactive = 2, // products in this category will not show up in app
    Archived = 3, // deleted category, also will not show up in app
}

export interface IProductCategory {
    id: string,
    name: string;
    description?: string;
    collectionId?: string;
    imageUrl?: string;
    customAttributes?: ICustomAttribute[];
}

export interface ICustomAttribute {
    name: string;
    value: string | number;
}

export interface IAdminProductCategory extends IProductCategory {
    status: IProductCategoryStatusEnum;
    numberOfItems: number;
}

export interface IUpdateCategoryStatusParams {
    id: string;
    status: IProductCategoryStatusEnum;
}

export interface ICreateCategoryParams {
    name: string;
    description: string;
    hasImage: boolean;
}

export interface IDeleteCategoryParams {
    id: string;
}
