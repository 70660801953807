export default {
    navStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        // border: '1px solid red',
        borderRadius: 8,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: 'white',
    },
    navIcon: {
        width: 25,
        height: 25,
    },
    navTitle: {
        margin: 0,
        // fontFamily: 'DMSans-Bold',
        fontSize: '1.2rem',
        color: '#757D8A',
    },
};
