import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import CustomerGateway from 'api/Customer';
import { IGetCustomersParams } from 'redux/slices/customer/types';

export default function* watchGetCustomer(api: CustomerGateway): SagaWatcherReturnType {
    yield takeEvery('customer/customerGetCustomersAttempt', handleGetCustomer, api);
}

function* handleGetCustomer(api: CustomerGateway, data: PayloadAction<IGetCustomersParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { index, dateFrom, dateTo, search = '' } = data.payload;

    if (!authToken) {
        yield put(Actions.customerGetCustomersFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.getCustomers], { authToken, index, dateFrom, dateTo, search });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.customerGetCustomersFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.customerGetCustomersSuccess(response.data));
            return;
        }
    }

    yield put(Actions.customerGetCustomersFailure('Something went wrong. Please try again later'));
}
