import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { LoginParams, LoginResponse, IAuthGateway, ResetPasswordParams } from './AuthBase';

export default class AuthGateway extends IAuthGateway {
    async login(params: LoginParams): GatewayResponse<LoginResponse> {
        const response: ApiResponse<LoginResponse> = await this.api.post('/tenant/auth/login', params);
        return this.process(response);
    }

    async resetPassword(params: ResetPasswordParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('/auth/resetPassword/request', params);
        return this.process(response);
    }
}
