import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle, SearchBar, Button, Datepicker, PageSkeleton, Pagination } from '@upshop/exodia';
import { Card, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import dayjs from 'dayjs';

import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { useNavigate } from 'react-router-dom';

import { IGetCustomersListResponse, IGetCustomersParams } from 'redux/slices/customer/types';
import Table from './Components/Table';
import styles from './styles';

// required for react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

interface CustomerProps {
    customersLoading: boolean;
    customersError: string;
    customers: IGetCustomersListResponse[];
    index: number;
    currentPage: number;
    startDate: string | undefined;
    endDate: string | undefined;
    query: string | undefined;
    exportCustomer: string | undefined;
    getCustomers: (params: IGetCustomersParams) => void;
    setIndex: (index: number) => void;
    setCurrentPage: (page: number) => void;
    setStartDate: (date: string | undefined) => void;
    setEndDate: (date: string | undefined) => void;
    setQuery: (query: string | undefined) => void;
    exportCustomers: (params: IGetCustomersParams) => void;
}

const Customer = (props: CustomerProps): JSX.Element => {
    const {
        customersLoading,
        customersError,
        customers,
        index,
        currentPage,
        startDate,
        endDate,
        query,
        exportCustomer,
        getCustomers,
        setIndex,
        setCurrentPage,
        setStartDate,
        setEndDate,
        setQuery,
        exportCustomers,
    } = props;

    const [dateDropdown, setDateDropdown] = useState(false);

    const [allowDownload, setAllowDownload] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setQuery('');
        getCustomers({ index: currentPage, dateFrom: startDate, dateTo: endDate, search: '' });
    }, []);

    useEffect(() => {
        getCustomers({ index: currentPage, dateFrom: startDate, dateTo: endDate, search: query?.trim() });
    }, [endDate, currentPage]);

    useEffect(() => {
        if (customers[0]?.maxIndex >= 1) {
            setIndex(index || 1);
            setCurrentPage(currentPage || 1);
        }
    }, []);

    const exportData = (data: string, fileName: string) => {
        const url = `data:text/csv;base64,${data}`;
        const a = document.createElement('a');

        // Assign attributes
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // Append to body to ensure it's clickable
        a.click();
        a.remove();

        setAllowDownload(false);
    };

    useEffect(() => {
        if (exportCustomer) {
            if (allowDownload) {
                exportData(exportCustomer, 'customers_export.xlsx');
            }
        }
    }, [exportCustomer, allowDownload]);

    const handleExportCustomer = async () => {
        exportCustomers({ index: currentPage, dateFrom: startDate, dateTo: endDate, search: query?.trim() });

        setAllowDownload(true);
    };

    const clearFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        setQuery(undefined);
        setIndex(1);
        setCurrentPage(1);

        getCustomers({ index: 1, dateFrom: undefined, dateTo: undefined, search: undefined });
    };

    const prepareTableData = () => {
        const tableHeaders = ['Customer Id', 'Name', 'Phone Number', 'Total Orders', 'Total Revenue', 'Average Revenue Per Order'];

        const tableData = customers[0]?.data.map(customer => {
            const { customerId, name, mobileNumber, totalOrders, totalRevenue, averageRevenuePerOrder } = customer;

            const currency = 'RM';

            return [
                <Button
                    style={{
                        background: 0,
                        outline: 'none',
                        border: 0,
                        color: '#4E6DDD',
                        fontFamily: 'DMSans-Bold',
                        fontSize: '1.2rem',
                    }}
                    buttonCss={[
                        `
                        width: 100%;
                    `,
                    ]}
                    label={customerId}
                    onClick={() => navigate(`/customer/${customerId}`)}
                />,
                name,
                mobileNumber,
                totalOrders,
                currency.concat(totalRevenue.toString()),
                currency.concat(averageRevenuePerOrder.toFixed(2)),
            ];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const renderTable = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            <>
                <div style={{ marginTop: '10px' }}>
                    {customersLoading
                        ? <PageSkeleton count={10} />
                        : (
                            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px', marginTop: 20 }}>
                                {customersError ? <div style={{ color: 'red' }}>{customersError}</div> : <Table tableHeaders={tableHeaders} tableData={tableData?.length ? tableData : []} />}

                                <div style={{ position: 'absolute', bottom: '5px' }}>
                                    <Pagination onClick={setCurrentPage} currentPage={currentPage} maxPages={customers[0]?.maxIndex > 0 ? customers[0]?.maxIndex : 0} index={index} setIndex={setIndex} />
                                </div>
                            </div>
                        )}
                </div>
            </>
        );
    };

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) setStartDate(dayjs(start).startOf('day').toISOString());
        if (end) setEndDate(dayjs(end).endOf('day').toISOString());
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(e.currentTarget.value.trim());
        }
    };

    const handleSearch = (queryString: string | undefined) => {
        setCurrentPage(1);
        setIndex(1);
        getCustomers({ index: 1, dateFrom: startDate, dateTo: endDate, search: queryString?.trim() || '' });
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const renderDropdownList = () => {
        return (
            <DropdownItem key='csv' onClick={handleExportCustomer} style={{ maxWidth: '300px', overflowX: 'hidden', overflowY: 'auto' }}>Export to CSV</DropdownItem>
        );
    };

    return (
        <div
            style={{
                padding: '25px',
                maxWidth: '1500px',
            }}
        >
            <div style={{ marginLeft: '30px', marginBottom: '0px' }}>
                <PageTitle size='xl' color='#6989FE'>Customer List</PageTitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '10px',
                    gap: 30,
                    paddingRight: 20,
                }}
            >
                <div
                    style={{
                        // marginRight: '15px',
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Datepicker
                        selected={startDate ? new Date(startDate) : null}
                        startDate={startDate ? new Date(startDate) : null}
                        endDate={endDate ? new Date(endDate) : null}
                        onChange={onDatesChange}
                    />
                </div>

                <div>
                    <Dropdown isOpen={dateDropdown} toggle={() => setDateDropdown(!dateDropdown)}>
                        <DropdownToggle style={{ minWidth: '300px', overflow: 'hidden', color: '#4E6DDD', fontFamily: 'DMSans-Bold', backgroundColor: 'white', border: '1px solid #E1E3E6', borderRadius: 8 }} caret>
                            Export
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderDropdownList()}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    role='button'
                    tabIndex={0}
                    onClick={clearFilters}
                    onKeyDown={clearFilters}
                >
                    <p style={{ margin: 0, color: 'grey' }}>clear filters</p>
                </div>
            </div>

            <Card style={styles.OrdersCard}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 60 }}>
                    <div>
                        <SearchBar
                            onClick={() => handleSearch(query)}
                            label
                            onKeyDown={(e) => handleOnKeyDown(e)}
                            value={query || ''}
                            onChange={handleOnChange}
                        />
                    </div>

                    {/* <div style={{ display: 'flex' }}>
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters === undefined || selectedFilters.length === 0 ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='All'
                            onClick={() => setSelectedFilters([])}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Shipped) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Shipped'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Shipped)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Unfulfilled) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Unfulfilled'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Unfulfilled)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Completed) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Completed'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Completed)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Cancelled) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Cancelled'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Cancelled)}
                        />
                    </div> */}
                </div>

                {/* <div style={styles.OrdersAdditionalFilterButtons}>
                    {renderAdditionalFilterButtons()}

                    <Dropdown isOpen={filterDropdown} toggle={() => setFilterDropdown(!filterDropdown)}>
                        <DropdownToggle style={{ backgroundColor: 'transparent', color: '#4E6DDD', border: '1px solid rgba(0,0,0,0.1)', maxWidth: '300px', overflow: 'hidden', margin: '11px 0px' }} caret>
                            Additional filters
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderFilterList()}
                        </DropdownMenu>
                    </Dropdown>
                </div> */}
                {renderTable()}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    customersLoading: Selectors.getCustomerCustomersAttempting(state),
    customersError: Selectors.getCustomerCustomersError(state),
    customers: Selectors.getCustomerCustomers(state),
    index: Selectors.getCustomerPaginationIndex(state),
    currentPage: Selectors.getCustomerCurrentPage(state),
    startDate: Selectors.getCustomerFilterStartDate(state),
    endDate: Selectors.getCustomerFilterEndDate(state),
    query: Selectors.getCustomerQuery(state),
    exportCustomer: Selectors.getCustomerCustomersExport(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getCustomers: (params: IGetCustomersParams) => dispatch(Actions.customerGetCustomersAttempt({ ...params })),
    setIndex: (index: number) => dispatch(Actions.customerSetPaginationIndex(index)),
    setCurrentPage: (page: number) => dispatch(Actions.customerSetCurrentPage(page)),
    setStartDate: (date: string | undefined) => dispatch(Actions.customerSetFilterStartDate(date)),
    setEndDate: (date: string | undefined) => dispatch(Actions.customerSetFilterEndDate(date)),
    setQuery: (query: string | undefined) => dispatch(Actions.customerSetQuery(query)),
    exportCustomers: (params: IGetCustomersParams) => dispatch(Actions.customerExportCustomersAttempt({ ...params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
