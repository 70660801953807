import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface GetTenantStatusParams {
    authToken: string;
}

export interface GetTenantStatusResponse {
    isMaintenance: boolean;
    goldPrice: {
        gold375G?: number;
        gold750?: number;
        gold916B?: number;
        gold916A?: number;
        gold916?: number;
        gold925?: number;
        gold999?: number;
        gold9999?: number;
    };
    toggableGoldPrice: {
        gold375G: {
            isActive: boolean;
            value: number;
        },
        gold750: {
            isActive: boolean;
            value: number;
        },
        gold916: {
            isActive: boolean;
            value: number;
        },
        gold916B: {
            isActive: boolean;
            value: number;
        },
        gold916A: {
            isActive: boolean;
            value: number;
        },
        gold925: {
            isActive: boolean;
            value: number;
        },
        gold999: {
            isActive: boolean;
            value: number;
        },
        gold9999: {
            isActive: boolean;
            value: number;
        }
    };
}

export interface SetGoldPriceParams {
    gold375G?: number;
    gold750?: number;
    gold916B?: number;
    gold916A?: number;
    gold916?: number;
    gold925?: number;
    gold999?: number;
    gold9999?: number;
    authToken: string;
}

export interface SetToggableGoldPriceParams {
    authToken: string;
    gold375G: {
        isActive: boolean;
        value: number;
    },
    gold750: {
        isActive: boolean;
        value: number;
    },
    gold916: {
        isActive: boolean;
        value: number;
    },
    gold916A: {
        isActive: boolean;
        value: number;
    },
    gold916B: {
        isActive: boolean;
        value: number;
    },
    gold999: {
        isActive: boolean;
        value: number;
    },
    gold9999: {
        isActive: boolean;
        value: number;
    }
}

export interface SetGoldPriceResponse {
    response: string;
}

export interface SetMaintenanceParams {
    isMaintenance: boolean;
    authToken: string;
}

export abstract class ITenantGateway extends Gateway {
    abstract getTenantStatus(params: GetTenantStatusParams): GatewayResponse<GetTenantStatusResponse | null>;

    abstract setGoldPrice(params: SetGoldPriceParams): GatewayResponse<null | null>;

    abstract setToggableGoldPrice(params: SetToggableGoldPriceParams): GatewayResponse<null | null>;

    abstract setMaintenance(params: SetMaintenanceParams): GatewayResponse<null | null>;
}
