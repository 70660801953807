import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import { ResetPasswordPayload } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authResetPasswordAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: ResetPasswordPayload) {
    const { email } = data.payload;

    const response = yield* call([api, api.resetPassword], { email, role: 1 });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.authResetPasswordFailure(response.message));
    }

    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.authResetPasswordSuccess());
    }
}
