import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IGetCustomersResponse } from 'redux/slices/reports/types';
import { IAdminCustomerDetails } from '@upshop/types';
import { GetCustomersParams, IReportsGateway, GetCustomerDetailsParams, UpdateCustomerParams } from './ReportsBase';

export default class ReportsGateway extends IReportsGateway {
    async getCustomers(params: GetCustomersParams): GatewayResponse<IGetCustomersResponse[] | null> {
        const { authToken, ...restGetCustomersParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<IGetCustomersResponse[]> = await this.api.get('/tenant/reports/customers', restGetCustomersParams, getHeaders());
        return this.process(response);
    }

    async getCustomerDetails(params: GetCustomerDetailsParams): GatewayResponse<IAdminCustomerDetails | null> {
        const { authToken, ...restGetCustomerDetailsParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<IAdminCustomerDetails> = await this.api.get('/tenant/reports/customerDetails', restGetCustomerDetailsParams, getHeaders());
        return this.process(response);
    }

    async updateCustomer(params: UpdateCustomerParams): GatewayResponse<null> {
        const { authToken, ...restUpdateCustomerParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.put('/tenant/reports/customerDetails', restUpdateCustomerParams, getHeaders());
        return this.process(response);
    }
}
