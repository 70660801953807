import { push, goBack, replace } from 'connected-react-router';
import { store } from 'redux/store';

const navPush = (routeName: string, state?: any) => {
    store.dispatch(push({ pathname: routeName, state }));
};

const navBack = (): void => {
    store.dispatch(goBack());
};

const navReplace = (routeName: string, params: any) => {
    store.dispatch(replace({ pathname: routeName, state: params }));
};

const navReset = (stackName: string) => {
    store.dispatch(replace(stackName));
};

const navToLogin = (): void => navPush('/login');

const navToOrders = (): void => navPush('/orders');

// const navToSettings = (): void => navPush('/settings');

export default {
    navBack,

    navToLogin,

    navToOrders,

    // navToSettings,
};
