import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsReduxState, IGetCategoriesParams, IGetProductsCategoriesResponse, IUpdateCategoryStatusParams, ICreateCategoryParams, IDeleteCategoryParams } from './types';

const initialState: ProductsReduxState = {
    actions: {
        categories: false,
        updateCategoryStatus: false,
        createProductCategory: false,
        deleteCategory: false,
    },
    currentPage: 0,
    currentIndex: 0,
    query: '',
    sortQuantity: 0,
    sortTitle: 0,
    categories: [],
    deleteCategory: '',
    updateCategoryStatus: '', // id of the category that is being update, tracking for rendering spinner
    createProductCategory: '', // upload url
    error: {
        categories: '',
        updateCategoryStatus: '',
        createProductCategory: '',
        deleteCategory: '',
    },
};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        productsGetCategoriesAttempt: (state, _action: PayloadAction<IGetCategoriesParams>) => {
            state.actions.categories = true;
            state.error.categories = '';
        },
        productsGetCategoriesSuccess: (state, action: PayloadAction<IGetProductsCategoriesResponse>) => {
            state.actions.categories = false;
            if (action.payload) {
                state.categories[action.payload.index - 1] = action.payload;
            }
        },
        productsGetCategoriesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.categories = false;
            if (action.payload) {
                state.error.categories = action.payload;
            }
        },
        productsSetCurrentIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) {
                state.currentIndex = action.payload;
            }
        },
        productsSetCurrentPage: (state, action: PayloadAction<number>) => {
            if (action.payload) {
                state.currentPage = action.payload;
            }
        },
        productsSetQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        productsSetSortQuantity: (state, action: PayloadAction<number>) => {
            state.sortTitle = 0;
            state.sortQuantity = action.payload;
        },
        productsSetSortTitle: (state, action: PayloadAction<number>) => {
            state.sortQuantity = 0;
            state.sortTitle = action.payload;
        },
        productsDeleteCategoryAttempt: (state, action: PayloadAction<IDeleteCategoryParams>) => {
            state.actions.deleteCategory = true;
            state.error.deleteCategory = '';

            if (action.payload) {
                state.deleteCategory = action.payload.id;
            }
        },
        productsDeleteCategorySuccess: (state, action: PayloadAction<IDeleteCategoryParams>) => {
            state.actions.deleteCategory = false;
            state.deleteCategory = '';

            if (action.payload) {
                const categoryIndex = state.categories[state.currentIndex].data.findIndex(item => item.id === action.payload.id);

                if (categoryIndex === -1) {
                    const categoriesCopy = state.categories.filter(category => {
                        return category.data.filter(item => item.id !== action.payload.id);
                    });

                    if (categoriesCopy.length) {
                        state.categories = categoriesCopy;
                    }
                } else {
                    const categoriesCopy = state.categories.slice();
                    categoriesCopy[state.currentIndex].data.splice(categoryIndex, 1);

                    if (categoriesCopy.length) {
                        state.categories = categoriesCopy;
                    }
                }
            }
        },
        productsDeleteCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.deleteCategory = false;
            state.deleteCategory = '';

            if (action.payload) {
                state.error.deleteCategory = action.payload;
            }
        },
        productsSetCategoryStatusAttempt: (state, action: PayloadAction<IUpdateCategoryStatusParams>) => {
            state.actions.updateCategoryStatus = true;
            if (action.payload.id) {
                state.updateCategoryStatus = action.payload.id;
            }
            state.error.updateCategoryStatus = '';
        },
        productsSetCategoryStatusSuccess: (state, action: PayloadAction<IUpdateCategoryStatusParams>) => {
            state.actions.updateCategoryStatus = false;
            state.updateCategoryStatus = '';

            const matchingOrder = state.categories[state.currentIndex - 1].data.findIndex(item => item.id === action.payload.id);

            if (matchingOrder === -1) {
                let updated = false;

                for (let i = 0; i < state.categories.length; i + 1) {
                    if (!updated) {
                        for (let index = 0; i < state.categories[i].data.length; index + 1) {
                            if (state.categories[i].data[index].id === action.payload.id) {
                                state.categories[i].data[index].status = action.payload.status;
                                updated = true;
                                break;
                            }
                        }
                    } else if (updated) break;
                }
            } else if (matchingOrder > -1) {
                state.categories[state.currentIndex - 1].data[matchingOrder].status = action.payload.status;
            }
        },
        productsSetCategoryStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.updateCategoryStatus = false;
            if (action.payload) {
                state.error.updateCategoryStatus = action.payload;
            }
        },
        productsCreateCategoryAttempt: (state, _action: PayloadAction<ICreateCategoryParams>) => {
            state.actions.createProductCategory = true;
            state.error.createProductCategory = '';
            state.createProductCategory = '';
        },
        productsCreateCategorySuccess: (state, action: PayloadAction<string>) => {
            state.actions.createProductCategory = false;
            if (action.payload) {
                state.createProductCategory = action.payload;
            }
        },
        productsCreateCategoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.createProductCategory = false;
            if (action.payload) {
                state.error.createProductCategory = action.payload;
            }
        },
    },
});

export type ProductsState = typeof initialState;

export default {
    actions: productsSlice.actions,
    reducers: productsSlice.reducer,
};
