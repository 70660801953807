import React, { useEffect } from 'react';
import { IAdminCustomerDetails } from '@upshop/types';
import { BackButton, PageTitle } from '@upshop/exodia';

import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';

import Actions from 'redux/Actions';
import { IUpdateCustomerParams } from 'redux/slices/reports/types';

import CustomerDetailsComponent from './Components/CustomerDetailsComponent';
import CustomerHistoryDetails from './Components/CustomerOrderHistory';

import Styles from './styles';

interface CustomerDetailsProps {
    customerDetailsLoading: boolean;
    customerDetailsError: string;
    customerDetails: IAdminCustomerDetails;
    updateCustomer: (data: IUpdateCustomerParams) => void;
    getCustomerDetails: (id: string) => void;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    updatingCustomerDetails: boolean;
}

type LocationState = {
    id: string;
}

const CustomerDetails = (props: CustomerDetailsProps): JSX.Element => {
    const { customerDetailsLoading, customerDetailsError, customerDetails, updateCustomer, getCustomerDetails, editMode, setEditMode, updatingCustomerDetails } = props;

    const navigate = useNavigate();
    const { state } = useLocation();
    const { id: customerId } = state as LocationState;

    useEffect(() => {
        if (customerId) {
            getCustomerDetails(customerId);
        }
    }, []);

    const prepareData = () => {
        const { orders, ...restCustomerDetails } = customerDetails;
        const { address, gender, birthday, customAttributes, id, firstName, lastName, totalSpent, totalNumOfOrders, phoneNumber, email } = restCustomerDetails;

        const customerDetailsData = {
            address,
            gender,
            birthday,
            customAttributes,
            id,
            firstName,
            lastName,
            totalSpent,
            totalNumOfOrders,
            phoneNumber,
            email,
        };

        const customerOrders = orders.slice();

        return {
            customerDetailsData,
            customerOrders,
        };
    };

    const renderCustomerDetailsCards = () => {
        const { customerDetailsData, customerOrders } = prepareData();

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <CustomerDetailsComponent
                        data={customerDetailsData}
                        customerDetailsLoading={customerDetailsLoading}
                        updateCustomer={updateCustomer}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        updatingCustomerDetails={updatingCustomerDetails}
                    />
                </div>

                <div>
                    <CustomerHistoryDetails
                        orders={customerOrders}
                        customerDetailsLoading={customerDetailsLoading}
                        customerDetailsError={customerDetailsError}
                    />
                </div>
            </div>
        );
    };

    return (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ margin: '10px 25px', maxWidth: '1220px' }}>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <BackButton style={Styles.CustomerDetailsBackButton} onClick={() => navigate('/reports/customers')} />
                    {customerDetailsError ? (<PageTitle size='large' color='red'>{customerDetailsError}</PageTitle>)
                        : (
                            <PageTitle size='large'>
                                Customer Details
                            </PageTitle>
                        )}
                </div>

                {renderCustomerDetailsCards()}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    customerDetailsLoading: Selectors.getReportsCustomersAttempting(state),
    customerDetailsError: Selectors.getReportsCustomerDetailsError(state),
    customerDetails: Selectors.getReportsCustomerDetails(state),
    editMode: Selectors.getReportsCustomerDetailsEditMode(state),
    updatingCustomerDetails: Selectors.getReportsUpdateCustomerAttempting(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getCustomerDetails: (id: string) => dispatch(Actions.reportsGetCustomerDetailsAttempt({ id })),
    updateCustomer: (data: IUpdateCustomerParams) => dispatch(Actions.reportsUpdateCustomerAttempt({ ...data })),
    setEditMode: (editMode: boolean) => dispatch(Actions.reportsSetCustomerDetailsEditMode(editMode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
