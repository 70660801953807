import React from 'react';
import { IOrderStatusEnum, IPaymentTransactionStatus } from '@upshop/types';
import Utils from 'lib/Utils';
import Colors from 'Themes/Colors';
import Styles from './styles';

export interface OrderStatusLabelProps {
    orderStatus: IOrderStatusEnum;
    paymentStatus?: IPaymentTransactionStatus;
}

const OrderStatusLabel = (props: OrderStatusLabelProps): JSX.Element => {
    const { orderStatus, paymentStatus } = props;

    const orderStatusBackground = () => {
        if (orderStatus === IOrderStatusEnum.Unfulfilled) return { backgroundColor: '#FF99001A', color: '#FF9900', fontFamily: 'DMSans-Bold' };
        if (orderStatus === IOrderStatusEnum.Shipped) return { backgroundColor: '#00ADD21A', color: '#00ADD2', fontFamily: 'DMSans-Bold' };
        if (orderStatus === IOrderStatusEnum.Completed) return { backgroundColor: '#38AE001A', color: '#38AE00', fontFamily: 'DMSans-Bold' };
        if (orderStatus === IOrderStatusEnum.Refunded) return { backgroundColor: '#E800001A', color: '#E80000', fontFamily: 'DMSans-Bold' };
        return { backgroundColor: '#666F7E1A', color: '#666F7E', fontFamily: 'DMSans-Bold' };
    };

    const paymentStatusBackground = () => {
        if (paymentStatus === IPaymentTransactionStatus.Processing) return { backgroundColor: '#9672FF1A', color: '#9672FF', fontFamily: 'DMSans-Bold' };
        if (paymentStatus === IPaymentTransactionStatus.Success) return { backgroundColor: '#38AE001A', color: '#38AE00', fontFamily: 'DMSans-Bold' };
        if (paymentStatus === IPaymentTransactionStatus.Pending) return { backgroundColor: '#FF99001A', color: '#FF9900', fontFamily: 'DMSans-Bold' };
        if (paymentStatus === IPaymentTransactionStatus.Failed) return { backgroundColor: '#666F7E1A', color: '#666F7E', fontFamily: 'DMSans-Bold' };
        return { backgroundColor: '#666F7E1A', color: '#666F7E', fontFamily: 'DMSans-Bold' };
    };

    return (
        <div style={{
            display: 'flex',
            WebkitTouchCallout: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
            WebkitUserSelect: 'none',
            userSelect: 'none',
            paddingLeft: 10,
        }}
        >
            <div style={{ ...Styles.OrderDetailsOrderStatus, ...orderStatusBackground() }}>
                {/* hidden={!orderStatus} */}
                {Utils.Order.getFulfillmentStatus(orderStatus)}
            </div>
            <div style={{ ...Styles.OrderDetailsOrderStatus, ...paymentStatusBackground() }}>
                {/* hidden={!paymentStatus} */}
                {Utils.Order.getPaymentStatus(paymentStatus)}
            </div>
        </div>
    );
};

export default OrderStatusLabel;
