import React from 'react';
import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import config from 'config';

import Full from 'containers/Full';
import PackageJson from '../package.json';

const App = (): JSX.Element => {
    console.log(`Version: ${PackageJson.version} (${config.env})`);

    return (
        <div className='App'>
            <Full />
            <ToastContainer position='top-right' closeButton={false} theme='colored' />
        </div>
    );
};

export default App;
