export default {
    cardStyle: {
        padding: '20px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        minWidth: '500px',
        // maxWidth: '500px',
        maxWidth: '100%',

    },
};
