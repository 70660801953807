import { GetTenantStatusResponse } from 'api/TenantBase';
import { TenantState } from '.';

const getTenantStatusAttempting = (state: TenantState): boolean => state.actions.tenantStatus || false;
const getTenantStatusError = (state: TenantState): string => state.error.tenantStatus || '';
const getTenantStatus = (state: TenantState): GetTenantStatusResponse => state.tenantStatus || {};

const getSetMaintenanceAttempting = (state: TenantState): boolean => state.actions.setMaintenance || false;
const getSetMaintenanceError = (state: TenantState): string => state.error.setMaintenance || '';

const getSetGoldPriceAttempting = (state: TenantState): boolean => state.actions.setGoldPrice || false;
const getSetGoldPriceError = (state: TenantState): string => state.error.setMaintenance || '';

const getSetToggableGoldPriceAttempting = (state: TenantState): boolean => state.actions.setToggableGoldPrice || false;
const getSetToggableGoldPriceError = (state: TenantState): string => state.error.setMaintenance || '';

export default {
    getTenantStatusAttempting,
    getTenantStatusError,
    getTenantStatus,

    getSetMaintenanceAttempting,
    getSetMaintenanceError,

    getSetGoldPriceAttempting,
    getSetGoldPriceError,

    getSetToggableGoldPriceAttempting,
    getSetToggableGoldPriceError,
};
