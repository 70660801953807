import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OrderGateway from 'api/Order';

import Actions from 'redux/Actions';
import { IOrderStatusEnum, IUpdateOrderTrackingIdParams } from 'redux/slices/order/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchAddTrackingId(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdateTrackingIdAttempt', handleAddTrackingId, api);
}

function* handleAddTrackingId(api: OrderGateway, data: PayloadAction<IUpdateOrderTrackingIdParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { orderId, trackingNo, shippingCarrier, trackingUrl } = data.payload;

    if (!authToken) {
        yield put(Actions.orderUpdateTrackingIdFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.updateTrackingInformation], {
        authToken,
        orderId,
        trackingNo,
        shippingCarrier,
        trackingUrl,
    });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.orderUpdateTrackingIdFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.orderUpdateTrackingIdSuccess());
        yield put(Actions.orderGetOrderDetailsAttempt({ orderId }));
        yield put(Actions.orderSetTrackingIdModalIsOpen(false));

        if (response.data) {
            yield put(Actions.orderUpdateStatusAttempt({
                orderId,
                status: IOrderStatusEnum.Shipped,
            }));
        }
        return;
    }

    yield put(Actions.orderUpdateTrackingIdFailure('Something went wrong. Please try again later'));
}
