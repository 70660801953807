import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OrderGateway from 'api/Order';
import Toast from 'lib/Toast';

import Actions from 'redux/Actions';
import { IApproveManualBankTransferParams } from 'redux/slices/order/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchApproveManualBankTransfer(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderApproveManualBankTransferAttempt', handleApproveManualBankTransfer, api);
}

function* handleApproveManualBankTransfer(api: OrderGateway, data: PayloadAction<IApproveManualBankTransferParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { orderId, isApproved } = data.payload;

    if (!authToken) {
        yield put(Actions.orderApproveManualBankTransferFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    const response = yield* call([api, api.approveManualBankTransfer], { authToken, orderId, isApproved });

    if (response.status === GatewayResponseStatus.Error) {
        Toast.showError(response.message || 'Something went wrong. Please try again later');
        yield put(Actions.orderApproveManualBankTransferFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            const { status } = response.data;
            Toast.show('Manual bank transfer status successfully updated!');
            yield put(Actions.orderApproveManualBankTransferSuccess({ orderId, status }));
        }
        return;
    }

    yield put(Actions.orderApproveManualBankTransferFailure('Something went wrong. Please try again later'));
}
