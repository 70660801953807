import cart from './cart.svg';
import cartBlue from './cartBlue.svg';
import cog from './cog.svg';
import dog from './dog.png';
import logout from './logout.svg';
import upArrow from './upArrow.svg';
import brokenImage from './brokenImage.svg';
import emptyIcon from './emptyIcon.svg';
import angleUp from './angleUp.svg';
import verticalLine from './verticalLine.svg';
import pieChart from './pieChart.svg';
import shoppingBag from './shoppingBag.svg';
import sortDownArrow from './sortDownArrow.svg';
import sortUpArrow from './sortUpArrow.svg';
import cloudUpload from './cloudUpload.svg';
import home from './home.svg';
import homeBlue from './homeBlue.svg';
import dollarSign from './dollarSign.svg';
import dollarBlue from './dollarBlue.svg';
import user from './user.png';
import userBlue from './userBlue.png';
import trash from './trash.svg';

export default {
    cart,
    cog,
    dog,
    logout,
    upArrow,
    brokenImage,
    emptyIcon,
    angleUp,
    verticalLine,
    pieChart,
    shoppingBag,
    sortDownArrow,
    sortUpArrow,
    cloudUpload,
    home,
    homeBlue,
    dollarSign,
    dollarBlue,
    cartBlue,
    user,
    userBlue,
    trash,
};
