import { CustomerState } from '.';
import { IGetCustomerDetailsResponse, IGetCustomersListResponse } from './types';

const getCustomersAttempting = (state: CustomerState): boolean => state.actions.customers || false;
const getCustomersError = (state: CustomerState): string => state.error.customers || '';
const getCustomers = (state: CustomerState): IGetCustomersListResponse[] => state.customers || [];

const getFilterStartDate = (state: CustomerState): string | undefined => state.filterStartDate || undefined;
const getFilterEndDate = (state: CustomerState): string | undefined => state.filterEndDate || undefined;

const getQuery = (state: CustomerState): string => state.query || '';

const getPaginationIndex = (state: CustomerState): number => state.paginationIndex || 1;
const getCurrentPage = (state: CustomerState): number => state.currentPage || 1;

const getCustomersDetailsAttempting = (state: CustomerState): boolean => state.actions.customerDetails || false;
const getCustomersDetailsError = (state: CustomerState): string => state.error.customerDetails || '';
const getCustomersDetails = (state: CustomerState): IGetCustomerDetailsResponse => state.customerDetails || {};

const getCustomersExportAttempting = (state: CustomerState): boolean => state.actions.exportCustomers || false;
const getCustomersExportError = (state: CustomerState): string => state.error.exportCustomers || '';
const getCustomersExport = (state: CustomerState): string => state.exportCustomers || '';

export default {
    getCustomersAttempting,
    getCustomersError,
    getCustomers,
    getFilterStartDate,
    getFilterEndDate,
    getQuery,
    getPaginationIndex,
    getCurrentPage,
    getCustomersDetailsAttempting,
    getCustomersDetailsError,
    getCustomersDetails,
    getCustomersExportAttempting,
    getCustomersExportError,
    getCustomersExport,
};
