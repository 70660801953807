import { IAdminProductCategory, IProductCategoryStatusEnum } from '@upshop/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface GetCategoriesParams {
    authToken: string;
    index: number;
    search?: string;
    sortQuantityAsc?: GetCategoriesSortEnum;
    sortTitleAsc?: GetCategoriesSortEnum;
}

export enum GetCategoriesSortEnum {
    descending,
    ascending,
}

export interface GetCategoriesResponse {
    index: number;
    maxIndex: number;
    data: IAdminProductCategory[];
}

export interface DeleteCategoryParams {
    authToken: string;
    id: string;
}

export interface UpdateCategoryStatusParams {
    authToken: string;
    id: string;
    status: IProductCategoryStatusEnum;
}

export interface CreateCategoryParams {
    authToken: string;
    name: string;
    description: string;
    hasImage: boolean;
}

export abstract class IProductsGateway extends Gateway {
    abstract getCategories(params: GetCategoriesParams): GatewayResponse<GetCategoriesResponse | null>;

    abstract deleteCategory(params: DeleteCategoryParams): GatewayResponse<null | null>;

    abstract updateCategoryStatus(params: UpdateCategoryStatusParams): GatewayResponse<null>;

    abstract createCategory(param: CreateCategoryParams): GatewayResponse<string | null>;
}
