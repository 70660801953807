import React, { useState, useEffect } from 'react';
import { Card, Row, Col, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { PageSkeleton, Label, Input, Button, ImageThumbnail } from '@upshop/exodia';
import { IOrderStatusEnum, IOrderPaymentMethodEnum, IUpdateOrderTrackingIdParams, IAddOrderRemarksParams } from 'redux/slices/order/types';
import Icons from 'Icons';

import Utils from 'lib/Utils';
import { IPaymentTransactionStatus } from '@upshop/types';
import Styles from './styles';

export interface PaymentDetailsDataProps {
    id: string;
    status: IOrderStatusEnum;
    shortId: string;
    orderDate: string;
    paymentId: string;
    paymentMethod: IOrderPaymentMethodEnum;
    paymentStatus: IPaymentTransactionStatus;
    paymentSlipImageUrl?: string;
    paymentSlipUploadDate?: string;
    adminRemarks: string;
}

interface PaymentDetailsProps {
    data: PaymentDetailsDataProps | undefined;
    updating: boolean;
    updatingId: string;
    approvingManualBankTransfer: boolean;
    orderDetailsError: string;
    orderDetailsLoading: boolean;
    modal: boolean;
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => void;
    approveManualBankTransfer: (orderId: string, isApproved: boolean) => void;
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => void;
    setModal: (isOpen: boolean) => void;
    addOrderRemarks: (params: IAddOrderRemarksParams) => void;
}

const PaymentDetails = (props: PaymentDetailsProps): JSX.Element | null => {
    const {
        data,
        updating,
        updatingId,
        approvingManualBankTransfer,
        orderDetailsError,
        orderDetailsLoading,
        modal,
        updateOrderStatus,
        approveManualBankTransfer,
        updateOrderTrackingId,
        setModal,
        addOrderRemarks,
    } = props;

    if (!data) return null;

    const [orderStatus, setOrderStatus] = useState<IOrderStatusEnum>();

    const [trackingNumber, setTrackingNumber] = useState('');
    const [shippingCarrier, setShippingCarrier] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');
    const [paymentSlipModal, setPaymentSlipModalModal] = useState(false);

    const [addTrackingModal, setAddTrackingModal] = useState(false);
    const [remarks, setRemarks] = useState('');

    useEffect(() => {
        if (data?.status && data.status !== orderStatus) {
            setOrderStatus(data.status);
        }
    }, [data]);

    useEffect(() => {
        if (updating === false) {
            setAddTrackingModal(false);
        }
    }, [updating]);

    const renderPaymentDetails = () => {
        return (
            <div>
                <Label label='Payment Status' text={data.paymentStatus !== undefined ? Utils.Order.getPaymentStatus(data.paymentStatus) : '-'} />
                <Label label='Payment Type' text={data?.paymentMethod ? Utils.Order.getPaymentMethod(data.paymentMethod) : '-'} />
                <Label label='Transaction No.' text={data?.paymentId || '-'} />
                <Label label='Transaction Time' text={data?.orderDate || '-'} />
                {/* <Label label='Metadata' text='Metadata' /> */}
            </div>
        );
    };

    const renderPaymentSlipModal = () => {
        return (
            <Modal size='lg' isOpen={paymentSlipModal} toggle={() => setPaymentSlipModalModal(!paymentSlipModal)}>
                <ModalBody style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        style={Styles.paymentSlipImage}
                        src={data?.paymentSlipImageUrl === undefined ? Icons.emptyIcon : data?.paymentSlipImageUrl}
                        onError={(e) => handleImageError(e)}
                        alt='paymentSlipImageUrl'
                    />
                </ModalBody>
                {!data?.id ? false
                    : (
                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Button
                                label='Reject'
                                style={Styles.paymentSlipModalRejectButton}
                                loading={approvingManualBankTransfer}
                                disabled={approvingManualBankTransfer}
                                onClick={() => approveManualBankTransfer(data.id, false)}
                            />
                            <Button
                                label='Approve'
                                style={Styles.paymentSlipModalAcceptButton}
                                loading={approvingManualBankTransfer}
                                disabled={approvingManualBankTransfer}
                                onClick={() => approveManualBankTransfer(data.id, true)}
                            />
                        </div>
                    )}
            </Modal>
        );
    };

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.onerror = null;
        e.currentTarget.src = Icons.brokenImage;
    };

    const onPaymentSlipThumbnailClick = () => {
        if (data?.paymentSlipImageUrl !== undefined) setPaymentSlipModalModal(!paymentSlipModal);
    };

    const renderTransferDetails = () => {
        return (
            <div>
                <Label label='Upload Detail Time' text={data?.paymentSlipUploadDate || '-'} />
                <div style={Styles.ComponentLabel}>
                    Please verify that you have received the money before updating payment status.
                </div>
                <ImageThumbnail
                    style={Styles.paymentSlipImageThumbnail}
                    src={data?.paymentSlipImageUrl === undefined ? Icons.emptyIcon : data?.paymentSlipImageUrl}
                    onClick={() => onPaymentSlipThumbnailClick()}
                    onError={(e) => handleImageError(e)}
                    alt='paymentSlipImageUrl'
                />
            </div>
        );
    };

    const handleUpdateOrderStatus = (statusEnum: IOrderStatusEnum) => {
        if (typeof (statusEnum) !== undefined && data?.id) {
            setOrderStatus(statusEnum);
            updateOrderStatus(data.id, statusEnum);
        }
    };

    const handleUpdatingShippingInfo = () => {
        updateOrderTrackingId({
            trackingNo: trackingNumber,
            shippingCarrier,
            orderId: data.id,
            trackingUrl,
        });
    };

    const toggleModal = () => {
        setModal(!modal);
        setTrackingNumber('');
        setShippingCarrier('');
    };

    const handleAddOrderRemarks = () => {
        addOrderRemarks({
            orderId: data?.id || '',
            remarks,
        });
    };

    const renderDropdownList = () => {
        return (
            <div>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.PendingPayment} key='Unpaid' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PendingPayment)}>Unpaid</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.PaymentFailed} key='PaymentFailed' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PaymentFailed)}>Failed</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.PaymentProcessing} key='PaymentProcessing' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PaymentProcessing)}>Processing</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Unfulfilled} key='Unfulfilled' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Unfulfilled)}>Unfulfilled</DropdownItem>
                <DropdownItem
                    hidden={orderStatus === IOrderStatusEnum.Shipped}
                    key='Fulfilled'
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    Fulfilled
                </DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Completed} key='Completed' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Completed)}>Completed</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Cancelled} key='Refunding' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Cancelled)}>Cancelled</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Refunded} key='Refunded' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Refunded)}>Refunded</DropdownItem>
            </div>
        );
    };

    const renderTrackingInformationModal = () => {
        return (
            <Modal size='md' isOpen={modal} toggle={toggleModal}>
                <ModalHeader>
                    <div style={{ ...Styles.ComponentTitle, margin: 0 }}>
                        Tracking Information
                    </div>
                </ModalHeader>
                <ModalBody
                    style={{
                        padding: '20px',
                    }}
                >
                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Number'
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Url'
                        value={trackingUrl}
                        onChange={(e) => setTrackingUrl(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Shipping Carrier'
                        value={shippingCarrier}
                        onChange={(e) => setShippingCarrier(e.currentTarget.value)}
                    />

                    <div style={{ display: ' flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={toggleModal}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={handleUpdatingShippingInfo}
                            loading={updating}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderAddRemarksModal = () => {
        return (
            <Modal size='md' isOpen={addTrackingModal} toggle={() => setAddTrackingModal(!addTrackingModal)}>
                <ModalHeader>
                    <div style={{ ...Styles.ComponentTitle, margin: 0 }}>
                        Add Remarks
                    </div>
                </ModalHeader>
                <ModalBody
                    style={{
                        padding: '20px',
                    }}
                >
                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Remarks'
                        value={remarks}
                        onChange={(e) => setRemarks(e.currentTarget.value)}
                    />

                    <div style={{ display: ' flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={() => setAddTrackingModal(false)}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={() => handleAddOrderRemarks()}
                            loading={updating}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderRemarks = () => {
        return (
        // <div>
        //     <p style={{ margin: 0 }}>
        //         {data.adminRemarks}
        //     </p>
        // </div>

            <Label label='Remarks' text={data?.adminRemarks || '-'} />
        );
    };

    return (
        <Card
            style={Styles.PaymentDetailsCard}
        >
            {orderDetailsLoading
                ? <PageSkeleton count={13} />
                : (
                    <Row>
                        <Col xs={6}>
                            <div style={{ ...Styles.ComponentTitle, paddingBottom: 10 }}>
                                Payment Details
                            </div>
                            {renderPaymentDetails()}
                        </Col>

                        <Col>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    {/* Remarks */}
                                </div>
                                <div
                                    style={{ display: 'flex', alignItems: 'center', borderRadius: 8, backgroundColor: 'white', color: '#4E6DDD', border: '1px solid #E1E3E6', padding: 8 }}
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => setAddTrackingModal(true)}
                                    onKeyPress={() => setAddTrackingModal(true)}
                                >
                                    <p style={{ margin: 0 }}>Add Remarks</p>
                                </div>
                            </div>

                            {renderRemarks()}
                        </Col>

                        {/* <Col xs={6}>
                            <div style={Styles.ComponentTitle}>
                                Transfer Details
                            </div>
                            {renderTransferDetails()}
                        </Col> */}

                        {/* <Col xs={3}>
                            <div hidden={!data?.id}>
                                <Dropdown isOpen={dropDown} toggle={() => setDropDown(!dropDown)}>
                                    <DropdownToggle style={{ width: '150px', overflow: 'hidden', backgroundColor: 'white', color: '#4E6DDD', border: '1px solid #E1E3E6', borderRadius: 8 }} caret>
                                        {updating && updatingId === data?.id ? <Spinner /> : (Utils.Order.getStatus(orderStatus) || 'Order status')}
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        {renderDropdownList()}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col> */}
                    </Row>
                )}
            {renderTrackingInformationModal()}
            {renderPaymentSlipModal()}
            {renderAddRemarksModal()}
        </Card>
    );
};

export default PaymentDetails;
