import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { IAddress, ICustomAttribute } from '@upshop/types';
import { Label, PageSkeleton, Button, Input } from '@upshop/exodia';
import { IUpdateCustomerParams } from 'redux/slices/reports/types';

import Styles from '../styles';

export interface ReportsCustomerDetailsDataProps {
    address: IAddress;
    gender?: 'MALE' | 'FEMALE';
    birthday?: string; // ISO 8601
    customAttributes?: ICustomAttribute[];
    id: string;
    firstName: string;
    lastName: string;
    totalSpent: {
        totalAmount: number;
        currency: string;
    };
    totalNumOfOrders: number;
    phoneNumber?: string;
    email?: string;
}

interface CustomerDetailsProps {
    data: ReportsCustomerDetailsDataProps | undefined,
    customerDetailsLoading: boolean;
    updateCustomer: (data: IUpdateCustomerParams) => void;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    updatingCustomerDetails: boolean;
}

const CustomerDetails = (props: CustomerDetailsProps): JSX.Element => {
    const { data, customerDetailsLoading, updateCustomer, editMode, setEditMode, updatingCustomerDetails } = props;

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string | undefined>('');
    const [address1, setAddress1] = useState<string>('');
    const [postCode, setPostCode] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [birthday, setBirthday] = useState<string | undefined>('');
    const [contactNumber, setContactNumber] = useState<string | undefined>('');
    const [address2, setAddress2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [country, setCountry] = useState<string>('');

    useEffect(() => {
        if (data !== undefined) {
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmail(data.email);
            setPostCode(data.address.postCode);
            setState(data.address.state);
            setAddress1(data.address.address1);
            setAddress2(data.address.address2);
            setBirthday(data.birthday);
            setContactNumber(data.phoneNumber);
            setCity(data.address.city);
            setCountry(data.address.country);
        }
    }, [data]);

    const renderCustomerDetailsLeft = () => {
        if (editMode) {
            return (
                <div>
                    <Label
                        label='First Name'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={firstName}
                                onChange={(e) => setFirstName(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Last Name'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={lastName}
                                onChange={(e) => setLastName(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Email'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={email}
                                onChange={(e) => setEmail(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Register Date'
                        text={(
                            <div style={{ color: 'grey' }}>
                                Register date here
                            </div>
                        )}
                    />
                    <Label
                        label='Postcode'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={postCode}
                                onChange={(e) => setPostCode(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='State'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={state}
                                onChange={(e) => setState(e.currentTarget.value)}
                            />
                        )}
                    />
                </div>
            );
        } return (
            <div>
                <Label label='First Name' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.firstName ? data.firstName : '-'}</div>} />
                <Label label='Last Name' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.lastName ? data.lastName : '-'}</div>} />
                <Label label='Email' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.email ? data.email : '-'}</div>} />
                <Label label='Register Date' text='Register date here' />
                <Label label='Postcode' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.postCode ? data?.address.postCode : '-'}</div>} />
                <Label label='State' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.state ? data?.address.state : '-'}</div>} />
            </div>
        );
    };

    const renderCustomerDetailsRight = () => {
        const { totalAmount, currency } = data?.totalSpent || {};

        if (editMode) {
            return (
                <div>
                    <Label
                        label='Gender'
                        text={(
                            <div style={{ color: 'grey' }}>
                                {
                                    data?.gender
                                        ? data.gender
                                        : '-'
                                }
                            </div>
                        )}
                    />
                    <Label
                        label='Date of Birth'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={birthday}
                                onChange={(e) => setBirthday(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Contact Number'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Total Amount Spent'
                        text={(
                            <div style={{ color: 'grey' }}>
                                {
                                    totalAmount && currency
                                        ? currency + totalAmount
                                        : '-'
                                }
                            </div>
                        )}
                    />
                    <Label
                        label='City'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={city}
                                onChange={(e) => setCity(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Country'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={Styles.CustomerHistoryDetailsTextInput}
                                value={country}
                                onChange={(e) => setCountry(e.currentTarget.value)}
                            />
                        )}
                    />
                </div>
            );
        } return (
            <div>
                <Label label='Gender' text={<div>{data?.gender ? data?.gender : '-'}</div>} />
                <Label label='Date of Birth' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.birthday ? data?.birthday : '-'}</div>} />
                <Label label='Contact Number' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.phoneNumber ? data?.phoneNumber : '-'}</div>} />
                <Label label='Total Amount Spent' text={<div>{data?.totalSpent.totalAmount && data?.totalSpent.currency ? data?.totalSpent.currency + data?.totalSpent.totalAmount : '-'}</div>} />
                <Label label='City' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.city ? data?.address.city : '-'}</div>} />
                <Label label='Country' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.country ? data?.address.country : '-'}</div>} />
            </div>
        );
    };

    const renderCustomerDetailsAddress = () => {
        if (editMode) {
            return (
                <div>
                    <Label
                        label='Address 1'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={{ ...Styles.CustomerHistoryDetailsTextInput, width: '400px' }}
                                value={address1}
                                onChange={(e) => setAddress1(e.currentTarget.value)}
                            />
                        )}
                    />
                    <Label
                        label='Address 2'
                        text={(
                            <Input
                                disabled={updatingCustomerDetails}
                                style={{ ...Styles.CustomerHistoryDetailsTextInput, width: '400px' }}
                                value={address2}
                                onChange={(e) => setAddress2(e.currentTarget.value)}
                            />
                        )}
                    />
                </div>
            );
        } return (
            <div>
                <Label label='Address 1' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.address1 ? data?.address.address1 : '-'}</div>} />
                <Label label='Address 2' text={<div style={{ borderBottom: '1px solid transparent' }}>{data?.address.address2 ? data?.address.address2 : '-'}</div>} />
            </div>
        );
    };

    const renderButtons = () => {
        if (editMode) {
            return (
                <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            label='Cancel'
                            style={{
                                color: '#4E6DDD',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #E1E3E6',
                                borderRadius: '8px',
                            }}
                            onClick={() => {
                                if (data !== undefined) {
                                    setFirstName(data.firstName);
                                    setLastName(data.lastName);
                                    setEmail(data.email);
                                    setPostCode(data.address.postCode);
                                    setState(data.address.state);
                                    setAddress1(data.address.address1);
                                    setAddress2(data.address.address2);
                                    setBirthday(data.birthday);
                                    setContactNumber(data.phoneNumber);
                                    setCity(data.address.city);
                                    setCountry(data.address.country);
                                }
                                setEditMode(false);
                            }}
                        />
                        <Button
                            hidden={
                                data === undefined || !(firstName !== data.firstName
                                    || lastName !== data.lastName
                                    || email !== data.email
                                    || postCode !== data.address.postCode
                                    || state !== data.address.state
                                    || address1 !== data.address.address1
                                    || address2 !== data.address.address2
                                    || birthday !== data.birthday
                                    || contactNumber !== data.phoneNumber
                                    || city !== data.address.city
                                    || country !== data.address.country)
                            }
                            disabled={updatingCustomerDetails}
                            loading={updatingCustomerDetails}
                            label='Save'
                            style={{
                                color: '#4E6DDD',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #E1E3E6',
                                borderRadius: '8px',
                            }}
                            onClick={() => {
                                if (
                                    firstName !== data?.firstName
                                    || lastName !== data?.lastName
                                    || email !== data?.email
                                    || postCode !== data?.address.postCode
                                    || state !== data?.address.state
                                    || address1 !== data?.address.address1
                                    || address2 !== data?.address.address2
                                    || birthday !== data?.birthday
                                    || contactNumber !== data?.phoneNumber
                                    || city !== data?.address.city
                                    || country !== data?.address.country
                                ) {
                                    if (data !== undefined) {
                                        updateCustomer({ id: data.id, firstName, lastName, phoneNumber: contactNumber, address: { address1, address2, state, city, postCode, country }, birthday });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            );
        } return (
            <div style={{ position: 'absolute', right: '10px', bottom: '10px' }}>
                <Button
                    label='Edit'
                    style={{
                        color: '#4E6DDD',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E6',
                        borderRadius: '8px',
                    }}
                    onClick={() => setEditMode(true)}
                />
            </div>
        );
    };

    return (
        <Card style={Styles.CustomerDetailsCard}>
            {customerDetailsLoading
                ? <PageSkeleton count={10} />
                : (
                    <div>
                        <Row>
                            <div style={Styles.ComponentTitle}>
                                Customer Details
                            </div>

                            <Col>
                                {renderCustomerDetailsLeft()}
                            </Col>

                            <Col>
                                {renderCustomerDetailsRight()}
                            </Col>
                        </Row>

                        <Row>
                            {renderCustomerDetailsAddress()}
                        </Row>

                        {renderButtons()}
                    </div>
                )}
        </Card>
    );
};

export default CustomerDetails;
