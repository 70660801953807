import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import OrderGateway from 'api/Order';
import Toast from 'lib/Toast';

import Actions from 'redux/Actions';
import { IUpdateOrderStatusParams } from 'redux/slices/order/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchUpdateOrderStatus(api: OrderGateway): SagaWatcherReturnType {
    yield takeEvery('order/orderUpdateStatusAttempt', handleUpdateOrder, api);
}

function* handleUpdateOrder(api: OrderGateway, data: PayloadAction<IUpdateOrderStatusParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { orderId, status } = data.payload;

    if (!authToken) {
        yield put(Actions.orderUpdateStatusFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    const response = yield* call([api, api.updateOrderStatus], { authToken, orderId, status });

    if (response.status === GatewayResponseStatus.Error) {
        Toast.showError(response.message || 'Something went wrong. Please try again later');
        yield put(Actions.orderUpdateStatusFailure(response.message || 'Something went wrong. Please try again later'));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        Toast.show('Order status successfully updated!');
        yield put(Actions.orderUpdateStatusSuccess({ orderId, status }));

        window.location.reload();
        return;
    }

    Toast.showError('Something went wrong. Please try again.');
    yield put(Actions.orderUpdateStatusFailure('Something went wrong. Please try again later'));
}
