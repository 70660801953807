import React, { useState, useEffect } from 'react';
import { PageTitle, Input, Button } from '@upshop/exodia';
import { Card, Input as CustomInput, FormFeedback, FormText } from 'reactstrap';
import { connect } from 'react-redux';
import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { RootState } from 'redux/store';

import Icons from 'Icons';
import styles from './styles';

interface CreateCategoryProps {
    createCategoryLoading: boolean;
    createCategoryError: string;
    uploadUrl: string;
    createNewCategory: (name: string, description: string, hasImage: boolean) => void;
}

const CreateCategory = (props: CreateCategoryProps): JSX.Element => {
    const {
        createCategoryLoading,
        createCategoryError,
        uploadUrl,
        createNewCategory,
    } = props;

    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState<File>();

    const form = document.getElementById('uploadImage') as HTMLFormElement;

    useEffect(() => {
        if (uploadUrl && uploadUrl.length) {
            form.submit();
        }
    }, [uploadUrl]);

    const onProceedPressed = () => {
        if (categoryName) {
            createNewCategory(categoryName, categoryDescription, selectedFile !== undefined);
        }
    };

    const renderCreateCategory = () => {
        return (
            <div>
                <div>
                    <Input
                        label='Category Name'
                        value={categoryName}
                        onChange={e => setCategoryName(e.currentTarget.value)}
                        style={{ width: '300px' }}
                        labelStyle={{ color: '#4E6DDD' }}
                    />
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Input
                        label='Description'
                        value={categoryDescription}
                        onChange={e => setCategoryDescription(e.currentTarget.value)}
                        style={{ width: '300px' }}
                        labelStyle={{ color: '#4E6DDD' }}
                    />
                </div>

                <div style={{ marginTop: '20px' }}>
                    <div style={{ color: '#4E6DDD' }}>
                        Category Banner
                        {' '}
                        <img alt='upload' src={Icons.cloudUpload} />
                    </div>

                    <form action={uploadUrl} method='post' id='uploadImage'>
                        <CustomInput
                            id='fileUpload'
                            style={{ width: '400px', height: '100px' }}
                            type='file'
                            accept='application/pdf, image/*'
                            onChange={(e) => {
                                if (e.currentTarget.files) setSelectedFile(e.currentTarget.files[0]);
                            }}
                        />
                        <FormFeedback tooltip>Please upload proof of payment.</FormFeedback>
                        <FormText>Drop image here</FormText>
                    </form>
                </div>

                <div style={{ marginTop: '50px' }}>
                    <Button
                        primary
                        disabled={!categoryName || !categoryDescription}
                        loading={createCategoryLoading}
                        onClick={onProceedPressed}
                        style={{ marginTop: 20, maxWidth: '300px', marginLeft: '0px' }}
                        label='Add'
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            style={{
                margin: '25px',
                maxWidth: '1500px',
            }}
        >
            <div style={{ marginLeft: '30px', marginBottom: '20px' }}>
                <PageTitle size='xl'>New Category</PageTitle>
            </div>

            <Card style={styles.CategoriesCard}>
                {renderCreateCategory()}
            </Card>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    createCategoryLoading: Selectors.getProductsCreateCategoryAttempting(state),
    createCategoryError: Selectors.getProductsCreateCategoryError(state),
    uploadUrl: Selectors.getProductsCreateCategory(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    createNewCategory: (name: string, description: string, hasImage: boolean) => dispatch(Actions.productsCreateCategoryAttempt({ name, description, hasImage })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
