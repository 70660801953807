const gold = 'rgb(255,215,0)';
const lightblue = 'rgb(105, 137, 254)';
const blue = 'rgb(78, 109, 221)';
const coolwhite = 'rgb(252, 253, 254)';
const black = 'rgb(0, 0, 0)';
const grey = 'rgb(128, 128, 128)';
const orangepeel = 'rgb(255, 153, 0)';
const lightorangepeel = 'rgb(255, 153, 0, 0.1)';
const successgreen = 'rgb(56, 174, 0)';
const lightsuccessgreen = 'rgb(56, 174, 0, 0.1)';
const tartorange = 'rgb(250, 77, 77)';
const lighttartorange = 'rgb(250, 77, 77, 0.1)';
const lightpurple = 'rgb(150, 114, 255, 0.1)';
const red = 'rgba(232, 0, 0)';
const lightRed = 'rgba(232, 0, 0,0.7)';

export default {
    gold,
    lightblue,
    blue,
    coolwhite,
    black,
    grey,
    orangepeel,
    lightorangepeel,
    successgreen,
    lightsuccessgreen,
    tartorange,
    lighttartorange,
    lightpurple,
    red,
    lightRed,
};
