import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import TenantGateway from 'api/Tenant';
import Toast from 'lib/Toast';

import Actions from 'redux/Actions';
import { ISetMaintenanceParams } from 'redux/slices/tenant/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchSetMaintenace(api: TenantGateway): SagaWatcherReturnType {
    yield takeEvery('tenant/tenantSetMaintenanceAttempt', handleSetMaintenance, api);
}

function* handleSetMaintenance(api: TenantGateway, data: PayloadAction<ISetMaintenanceParams>) {
    const { isMaintenance } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    if (!authToken) {
        yield put(Actions.reportsUpdateCustomerFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    const response = yield* call([api, api.setMaintenance], { isMaintenance, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.tenantSetMaintenanceFailure(response.message));
        Toast.showError(response.message || 'Something went wrong. Please try again.');
        return;
    }

    Toast.show('Gold price successfully updated!');
    yield put(Actions.tenantSetMaintenanceSuccess({ isMaintenance }));
}
