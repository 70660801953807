import React from 'react';
import { ICustomAttribute, IOrderStatusEnum } from '@upshop/types';
import { PageSkeleton, Button } from '@upshop/exodia';
import { Card } from 'reactstrap';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import OrderStatusLabel from '../../../order/Components/OrderStatusLabel';
import Table from '../../../order/Components/Table';

import Styles from '../styles';

export interface CustomerHistoryDetailsOrdersProps {
    id: string;
    orderDate: string; // ISO 8601 time format
    totalPrice: number;
    totalBoughtItems: number;
    currency: string;
    status: IOrderStatusEnum;
    customAttributes?: ICustomAttribute[];
}

interface CustomerHistoryDetailsProps {
    orders: CustomerHistoryDetailsOrdersProps[];
    customerDetailsLoading: boolean;
    customerDetailsError: string;
}

const CustomerHistoryDetails = (props: CustomerHistoryDetailsProps): JSX.Element => {
    const { orders, customerDetailsLoading, customerDetailsError } = props;

    const navigate = useNavigate();

    const prepareTableData = () => {
        const tableHeaders = ['Order ID', 'Date', 'Payment Status & Fulfillment Status', 'Total Amount'];

        const tableData = orders?.map(order => {
            const { id, orderDate, totalPrice, totalBoughtItems, currency, status } = order;

            return [<Button style={{ background: 0, outline: 'none', border: 0, color: '#4E6DDD' }} label={id} onClick={() => navigate(`/orders/${id}`)} />, dayjs(orderDate).format('DD/MM/YYYY'), <OrderStatusLabel orderStatus={status} />, totalPrice];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const renderTable = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            <div style={{ marginTop: '10px' }}>
                {customerDetailsLoading
                    ? <PageSkeleton count={10} />
                    : (
                        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                            {customerDetailsError ? <div style={{ color: 'red' }}>{customerDetailsError}</div> : <Table tableHeaders={tableHeaders} tableData={tableData} />}
                        </div>
                    )}
            </div>
        );
    };

    return (
        <Card style={Styles.CustomerHistoryDetailsCard}>
            <div style={{ ...Styles.ComponentTitle, paddingLeft: '20px' }}>
                Customer History
            </div>
            {renderTable()}
        </Card>
    );
};

export default CustomerHistoryDetails;
