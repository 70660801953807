import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PageTitle, SearchBar, Button, Datepicker, PageSkeleton, Pagination, Input } from '@upshop/exodia';
import { Card, Dropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import dayjs from 'dayjs';

import { RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { IGetOrderParams, IGetOrdersResponse, IOrderStatusEnum, IUpdateOrderTrackingIdParams, IFulfillmentStatusEnum, IPaymentStatusEnum } from 'redux/slices/order/types';
import { useNavigate } from 'react-router-dom';

import Utils from 'lib/Utils';
import Icons from 'Icons';
import Table from './Components/Table';
import styles from './styles';
import ComponentStyles from './Components/styles';

// required for react-datepicker
import 'react-datepicker/dist/react-datepicker.css';

interface OrderProps {
    ordersLoading: boolean;
    ordersError: string;
    orders: IGetOrdersResponse[];
    index: number;
    currentPage: number;
    updatingOrderStatus: boolean;
    updatingId: string;
    startDate: string | undefined;
    endDate: string | undefined;
    query: string | undefined;
    selectedFilters: IOrderStatusEnum[];
    modal: boolean;
    exportOrder: string | undefined;
    getOrders: (params: IGetOrderParams) => void;
    setIndex: (index: number) => void;
    setCurrentPage: (page: number) => void;
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => void;
    setStartDate: (date: string | undefined) => void;
    setEndDate: (date: string | undefined) => void;
    setQuery: (query: string | undefined) => void;
    setSelectedFilters: (filters: IOrderStatusEnum[]) => void;
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => void;
    setModal: (isOpen: boolean) => void;
    exportOrders: (params: IGetOrderParams) => void;
}

const Order = (props: OrderProps): JSX.Element => {
    const {
        ordersLoading,
        ordersError,
        orders,
        index,
        currentPage,
        updatingOrderStatus,
        updatingId,
        startDate,
        endDate,
        query,
        selectedFilters,
        modal,
        exportOrder,
        getOrders,
        setIndex,
        setCurrentPage,
        updateOrderStatus,
        setStartDate,
        setEndDate,
        setQuery,
        setSelectedFilters,
        updateOrderTrackingId,
        setModal,
        exportOrders,
    } = props;

    const [dateDropdown, setDateDropdown] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [trackingInfoOrderId, setTrackingInfoOrderId] = useState('');

    const [selectedExportMethod, setSelectedExportMethod] = useState('');

    const [allowDownload, setAllowDownload] = useState(false);

    const navigate = useNavigate();

    // trackingNumber and shippingCarrier useStates not required, will replace both with api call to update trackingNumber and shipping
    // carrier directly upon 'Save' button click in the modal
    const [trackingNumber, setTrackingNumber] = useState('');
    const [shippingCarrier, setShippingCarrier] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');

    useEffect(() => {
        setQuery('');
        getOrders({ index: currentPage, dateFrom: startDate || undefined, dateTo: endDate || undefined, fulfillmentStatus: selectedFilters, search: '' });
    }, []);

    useEffect(() => {
        getOrders({ index: currentPage, dateFrom: startDate || undefined, dateTo: endDate || undefined, fulfillmentStatus: selectedFilters, search: query?.trim() });
    }, [selectedFilters, endDate, currentPage]);

    useEffect(() => {
        if (orders[0]?.maxIndex >= 1) {
            setIndex(index || 1);
            setCurrentPage(currentPage || 1);
        }
    }, []);

    const exportData = (data: string, fileName: string) => {
        const url = `data:text/csv;base64,${data}`;
        const a = document.createElement('a');

        // Assign attributes
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a); // Append to body to ensure it's clickable
        a.click();
        a.remove();

        setAllowDownload(false);
    };

    useEffect(() => {
        if (exportOrder) {
            if (allowDownload) {
                exportData(exportOrder, 'orders_export.xlsx');
            }
        }
    }, [exportOrder, allowDownload]);

    const handleExportOrder = async () => {
        exportOrders({ index: currentPage, dateFrom: startDate || undefined, dateTo: endDate || undefined, fulfillmentStatus: selectedFilters, search: query?.trim() });

        setAllowDownload(true);
    };

    const clearFilters = () => {
        setStartDate(undefined);
        setEndDate(undefined);
        setQuery(undefined);
        setIndex(1);
        setCurrentPage(1);
        setSelectedFilters([]);

        exportOrders({ index: 1, dateFrom: undefined, dateTo: undefined, fulfillmentStatus: [], search: undefined });
    };

    const prepareTableData = () => {
        const tableHeaders = ['Order Id', 'Order Date', 'Customer', 'Fulfillment Status', 'Payment Status', 'Total(RM)'];
        // , 'Actions'

        const tableData = orders[currentPage - 1]?.data.map(order => {
            const { id, shortId, customerName, orderDate, currency, totalPrice, totalBoughtItems, fulfillmentStatus, paymentInfo, customAttributes } = order;

            const dropdownBlock = (
                <div hidden={!id} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '150px', paddingTop: 10, paddingBottom: 10, overflow: 'hidden', backgroundColor: `${fulfillmentStatus ? Utils.OrderColor.getFulfillmentBgColor(fulfillmentStatus) : '#666F7E1A'}`, color: `${Utils.OrderColor.getFulfillmentTextColor(fulfillmentStatus) || '#666F7E'}`, border: 'none', borderRadius: 8 }}>
                        <p style={{ margin: 0 }}>{Utils.Order.getFulfillmentStatus(fulfillmentStatus) || 'No Status'}</p>
                    </div>
                </div>
            );

            const paymentBlock = (
                <div hidden={!id} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '150px', paddingTop: 10, paddingBottom: 10, overflow: 'hidden', backgroundColor: `${Utils.OrderColor.getPaymentBgColor(paymentInfo.paymentStatus) || '#666F7E1A'}`, color: `${Utils.OrderColor.getPaymentTextColor(paymentInfo.paymentStatus) || '#666F7E'}`, border: 'none', borderRadius: 8 }}>
                        <p style={{ margin: 0 }}>{Utils.Order.getPaymentStatus(paymentInfo.paymentStatus) || 'No status'}</p>
                    </div>
                </div>
            );

            const actionDelBtn = (
                <div>
                    <img src={Icons.trash} alt='delete' style={{ width: 25, height: 25, cursor: 'pointer' }} />
                </div>
            );

            return [
                <Button
                    style={{
                        background: 0,
                        outline: 'none',
                        border: 0,
                        color: '#4E6DDD',
                        fontFamily: 'DMSans-Bold',
                        fontSize: '1.2rem',
                    }}
                    buttonCss={[
                        `
                        width: 100%;
                    `,
                    ]}
                    label={shortId}
                    onClick={() => navigate(`/orders/${id}`)}
                />,
                dayjs(orderDate).format('DD/MM/YYYY'),
                customerName,
                // dropdownButton,
                dropdownBlock,
                // paymentStatus,
                // paymentButton,
                paymentBlock,
                // totalBoughtItems,
                currency.concat(totalPrice.toString()),
                // actionDelBtn,
            ];
        });

        return {
            tableHeaders,
            tableData,
        };
    };

    const renderTable = () => {
        const { tableHeaders, tableData } = prepareTableData();

        return (
            ordersLoading ? (
                <PageSkeleton count={10} />
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px', marginTop: 40 }}>
                    <Table tableHeaders={tableHeaders} tableData={tableData?.length ? tableData : []} />

                    <div style={{ position: 'absolute', bottom: '5px' }}>
                        <Pagination onClick={setCurrentPage} currentPage={currentPage} maxPages={orders[0]?.maxIndex > 0 ? orders[0]?.maxIndex : 0} index={index} setIndex={setIndex} />
                    </div>
                </div>

            )
        );
    };

    const renderTrackingInformationModal = () => {
        return (
            <Modal size='md' isOpen={modal} toggle={toggleModal}>
                <ModalHeader>
                    <div style={{ ...ComponentStyles.ComponentTitle, margin: 0 }}>
                        Tracking Information
                    </div>
                </ModalHeader>
                <ModalBody
                    style={{
                        padding: '20px',
                    }}
                >
                    <Input
                        labelStyle={ComponentStyles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Number'
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={ComponentStyles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Url'
                        value={trackingUrl}
                        onChange={(e) => setTrackingUrl(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={ComponentStyles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Shipping Carrier'
                        value={shippingCarrier}
                        onChange={(e) => setShippingCarrier(e.currentTarget.value)}
                    />

                    <div style={{ display: ' flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={toggleModal}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={handleUpdatingShippingInfo}
                            loading={updatingOrderStatus}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const onDatesChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;

        if (start) setStartDate(dayjs(start).startOf('day').toISOString());
        if (end) setEndDate(dayjs(end).endOf('day').toISOString());
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch(e.currentTarget.value.trim());
        }
    };

    const handleSearch = (queryString: string | undefined) => {
        setCurrentPage(1);
        setIndex(1);
        getOrders({ index: 1, dateFrom: startDate || undefined, dateTo: endDate || undefined, fulfillmentStatus: selectedFilters, search: queryString?.trim() || '' });
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const renderDropdownList = () => {
        return (
            <DropdownItem key='csv' onClick={handleExportOrder} style={{ maxWidth: '300px', overflowX: 'hidden', overflowY: 'auto' }}>Export to CSV</DropdownItem>
        );
    };

    const renderFilterList = () => {
        return (
            <>
                <DropdownItem onClick={() => handleFilterClick(IOrderStatusEnum.PendingPayment)}>Pending Payment</DropdownItem>
                <DropdownItem onClick={() => handleFilterClick(IOrderStatusEnum.PaymentFailed)}>Payment Failed</DropdownItem>
                <DropdownItem onClick={() => handleFilterClick(IOrderStatusEnum.PaymentProcessing)}>Payment Processing</DropdownItem>
                <DropdownItem onClick={() => handleFilterClick(IOrderStatusEnum.Refunded)}>Refunded</DropdownItem>
            </>
        );
    };

    const renderAdditionalFilterButtons = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button
                    hidden={!selectedFilters?.includes(IOrderStatusEnum.PendingPayment)}
                    style={{
                        color: '#4069FF',
                        backgroundColor: selectedFilters?.includes(IOrderStatusEnum.PendingPayment) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                        width: 'auto',
                    }}
                    label='Pending Payment'
                    onClick={() => handleFilterClick(IOrderStatusEnum.PendingPayment)}
                />
                <Button
                    hidden={!selectedFilters?.includes(IOrderStatusEnum.PaymentFailed)}
                    style={{
                        color: '#4069FF',
                        backgroundColor: selectedFilters?.includes(IOrderStatusEnum.PaymentFailed) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                        width: 'auto',
                    }}
                    label='Payment Failed'
                    onClick={() => handleFilterClick(IOrderStatusEnum.PaymentFailed)}
                />
                <Button
                    hidden={!selectedFilters?.includes(IOrderStatusEnum.PaymentProcessing)}
                    style={{
                        color: '#4069FF',
                        backgroundColor: selectedFilters?.includes(IOrderStatusEnum.PaymentProcessing) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                        width: 'auto',
                    }}
                    label='Payment Processing'
                    onClick={() => handleFilterClick(IOrderStatusEnum.PaymentProcessing)}
                />
                <Button
                    hidden={!selectedFilters?.includes(IOrderStatusEnum.Refunded)}
                    style={{
                        color: '#4069FF',
                        backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Refunded) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                        width: 'auto',
                    }}
                    label='Refunded'
                    onClick={() => handleFilterClick(IOrderStatusEnum.Refunded)}
                />
            </div>
        );
    };

    const handleFilterClick = (filter: IOrderStatusEnum) => {
        if (selectedFilters?.includes(filter)) {
            const filteredFilters = selectedFilters.filter(item => item !== filter);
            setSelectedFilters(filteredFilters);
        } else setSelectedFilters(selectedFilters?.concat(filter));
    };

    const handleUpdatingShippingInfo = () => {
        updateOrderTrackingId({
            trackingNo: trackingNumber,
            shippingCarrier,
            orderId: trackingInfoOrderId,
            trackingUrl: '',
        });
    };

    const toggleModal = () => {
        setModal(!modal);
        setTrackingNumber('');
        setShippingCarrier('');
        setTrackingInfoOrderId('');
    };

    return (
        <div
            style={{
                padding: '25px',
                maxWidth: '1500px',
            }}
        >
            <div style={{ marginLeft: '30px', marginBottom: '0px' }}>
                <PageTitle size='xl' color='#6989FE'>Orders</PageTitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '10px',
                    gap: 30,
                    paddingRight: 20,
                }}
            >
                <div
                    style={{
                        width: '250px',
                        marginRight: '15px',
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Datepicker
                        selected={startDate ? new Date(startDate) : null}
                        startDate={startDate ? new Date(startDate) : null}
                        endDate={endDate ? new Date(endDate) : null}
                        onChange={onDatesChange}
                    />
                </div>

                <div>
                    <Dropdown isOpen={dateDropdown} toggle={() => setDateDropdown(!dateDropdown)}>
                        <DropdownToggle style={{ minWidth: '300px', overflow: 'hidden', color: '#4E6DDD', fontFamily: 'DMSans-Bold', backgroundColor: 'white', border: '1px solid #E1E3E6', borderRadius: 8 }} caret>
                            Export
                        </DropdownToggle>
                        <DropdownMenu end>
                            {renderDropdownList()}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    role='button'
                    tabIndex={0}
                    onClick={clearFilters}
                    onKeyDown={clearFilters}
                >
                    <p style={{ margin: 0, color: 'grey' }}>clear filters</p>
                </div>
            </div>

            <Card style={styles.OrdersCard}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '60px' }}>
                    <div>
                        <SearchBar
                            onClick={() => handleSearch(query)}
                            label
                            onKeyDown={(e) => handleOnKeyDown(e)}
                            value={query || ''}
                            onChange={handleOnChange}
                        />
                    </div>

                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters === undefined || selectedFilters.length === 0 ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='All'
                            onClick={() => setSelectedFilters([])}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Shipped) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Shipped'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Shipped)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Unfulfilled) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Unfulfilled'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Unfulfilled)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Completed) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Completed'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Completed)}
                        />
                        <Button
                            style={{
                                color: '#4069FF',
                                backgroundColor: selectedFilters?.includes(IOrderStatusEnum.Refunded) ? 'rgba(105, 137, 254, 0.1)' : 'transparent',
                                width: 'auto',
                            }}
                            label='Refunded'
                            onClick={() => handleFilterClick(IOrderStatusEnum.Refunded)}
                        />
                    </div>
                </div>
                {renderTable()}
            </Card>
            {renderTrackingInformationModal()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    ordersLoading: Selectors.getOrderOrdersAttempting(state),
    ordersError: Selectors.getOrderOrdersError(state),
    orders: Selectors.getOrderOrders(state),
    index: Selectors.getOrderPaginationIndex(state),
    currentPage: Selectors.getOrderCurrentPage(state),
    updatingOrderStatus: Selectors.getOrderUpdateOrderStatusAttempting(state),
    updatingId: Selectors.getOrderUpdateOrderStatus(state),
    startDate: Selectors.getOrderFilterStartDate(state),
    endDate: Selectors.getOrderFilterEndDate(state),
    query: Selectors.getOrderQuery(state),
    selectedFilters: Selectors.getOrderFilters(state),
    modal: Selectors.getOrderTrackingIdModalIsOpen(state),
    exportOrder: Selectors.getOrderOrderExport(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrders: (params: IGetOrderParams) => dispatch(Actions.orderGetOrdersAttempt({ ...params })),
    setIndex: (index: number) => dispatch(Actions.orderSetPaginationIndex(index)),
    setCurrentPage: (page: number) => dispatch(Actions.orderSetCurrentPage(page)),
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => dispatch(Actions.orderUpdateStatusAttempt({ orderId, status })),
    setStartDate: (date: string | undefined) => dispatch(Actions.orderSetFilterStartDate(date)),
    setEndDate: (date: string | undefined) => dispatch(Actions.orderSetFilterEndDate(date)),
    setQuery: (query: string | undefined) => dispatch(Actions.orderSetQuery(query)),
    setSelectedFilters: (filters: IOrderStatusEnum[]) => dispatch(Actions.orderSetFilters(filters)),
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => dispatch(Actions.orderUpdateTrackingIdAttempt(params)),
    setModal: (isOpen: boolean) => dispatch(Actions.orderSetTrackingIdModalIsOpen(isOpen)),
    exportOrders: (params: IGetOrderParams) => dispatch(Actions.orderExportOrdersAttempt({ ...params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Order);
