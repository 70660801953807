import Colors from 'Themes/Colors';

export default {
    ComponentTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: Colors.blue,
        margin: '0px 3px 6px 3px',
    } as const,
    ComponentLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.lightblue,
        margin: '0px 3px 2px 3px',
    } as const,
    OrderNoteTextArea: {
        width: '100%',
        height: '100px',
        padding: '7px',
        minHeight: '50px',
        backgroundColor: Colors.coolwhite,
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '8px',
    } as const,
    PaymentDetailsCard: {
        padding: '10px 25px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgb(0,0,0,0.05)',
        width: '720px',
        minHeight: '335px',
        marginTop: '15px',
    } as const,
    TrackingInfoModalLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.lightblue,
        margin: '20px 3px 2px 3px',
    } as const,
    OrderDetailsCard: {
        display: 'block',
        padding: '10px 25px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        width: '480px',
        height: '615px',
        marginLeft: '15px',
        overflowY: 'auto',
    } as const,
    OrderNoteAddRemark: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.lightblue,
        margin: '10px 3px 10px 3px',
    } as const,
    PurchasedItemsCollapsibleTitleRow: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '90%',
        padding: '0px 10px',
    } as const,
    PurchasedItemsCollapsibleTitle: {
        overflow: 'hidden',
        display: 'block',
        height: '100%',
        color: Colors.blue,
        fontWeight: 'bold',
        alignItems: 'center',
    } as const,
    PurchasedItemsCollapsibleContentRow: {
        display: 'flex',
        padding: '10px',
        flexWrap: 'wrap',
    } as const,
    PurchasedItems: {
        display: 'block',
        height: '210px',
        overflowY: 'auto',
    } as const,
    CustomerDetailsCard: {
        padding: '10px 25px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        width: '720px',
        minHeight: '265px',
    } as const,
    OrderDetailsOrderStatus: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        minWidth: '150px',
        height: '40px',
        margin: '10px',
        border: '0',
        borderRadius: '5px',
        backgroundColor: 'rgb(0,0,0,0.1)',
    },
    OrderNoteNotes: {
        width: '100%',
        padding: '10px 7px',
        minHeight: '20px',
        backgroundColor: Colors.lightpurple,
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '8px',
    } as const,
    paymentSlipImageThumbnail: {
        width: '300px',
        height: '180px',
        marginTop: '5px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '8px',
        cursor: 'pointer',
    } as const,
    paymentSlipImage: {
        width: '75%',
        height: 'auto',
    } as const,
    paymentSlipModalRejectButton: {
        backgroundColor: Colors.red,
        color: 'white',
    } as const,
    paymentSlipModalAcceptButton: {
        backgroundColor: Colors.blue,
        color: 'white',
    } as const,
    activityLogUpCaret: {
        left: '10px',
        position: 'absolute',
        width: '100px',
        height: '50px',
    } as const,
    activityLogVerticalLine: {
        left: '-20px',
        marginTop: '-20px',
        position: 'absolute',
        width: '100px',
        height: '75px',
    } as const,
    activityLogContainer: {
        paddingTop: '20px',
        position: 'absolute',
        width: '80%',
        left: '30px',
    } as const,
    activityLogCard: {
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '8px',
        background: 'white',
        padding: '10px',
        marginTop: '25px',
        color: 'rgb(0, 165, 155, 1)',
        fontWeight: 'bold',
    } as const,
    activityLogUser: {
        left: '40px',
        position: 'absolute',
        fontSize: '12px',
        color: 'rgb(0, 0, 0, 0.6)',
        fontStyle: 'italic',
    } as const,
};
