import React, { useState, useEffect } from 'react';
import { PageTitle, Button, PageSkeleton } from '@upshop/exodia';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Card } from 'reactstrap';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { IAdminTenantStatus, ISetMaintenanceParams, ISetTogggableGoldPriceParams } from 'redux/slices/tenant/types';
import Maintenance from '../settings/Components/Maintenance';
import GoldPriceSettings from '../settings/Components/GoldPriceSettings';

import styles from '../settings/styles';

interface SettingsProps {
    getTenantStatus: () => void;
    tenantStatus: IAdminTenantStatus;
    tenantStatusLoading: boolean;
    tenantStatusError: string;
    setMaintenance: (data: ISetMaintenanceParams) => void;
    setToggableGoldPrice: (params: ISetTogggableGoldPriceParams) => void;
    loading: boolean;
    error: string;
}

const Dashboard = (props: SettingsProps): JSX.Element => {
    const {
        getTenantStatus,
        tenantStatus,
        tenantStatusLoading,
        tenantStatusError,
        setMaintenance,
        setToggableGoldPrice,
        loading,
        error,
    } = props;
    const { goldPrice, isMaintenance, toggableGoldPrice } = tenantStatus;

    const [maintenanceMode, setMaintenanceMode] = useState(isMaintenance);

    const [gold375G, setGold375G] = useState(toggableGoldPrice.gold375G.value === 0 ? undefined : toggableGoldPrice.gold375G.value);
    const [gold750, setGold750] = useState(toggableGoldPrice.gold750.value === 0 ? undefined : toggableGoldPrice.gold750.value);
    const [gold916B, setGold916B] = useState(toggableGoldPrice.gold916B.value === 0 ? undefined : toggableGoldPrice.gold916B.value);
    const [gold916A, setGold916A] = useState(toggableGoldPrice.gold916A.value === 0 ? undefined : toggableGoldPrice.gold916A.value);
    const [gold916, setGold916] = useState(toggableGoldPrice.gold916.value === 0 ? undefined : toggableGoldPrice.gold916.value);
    const [gold925, setGold925] = useState(toggableGoldPrice.gold925.value === 0 ? undefined : toggableGoldPrice.gold925.value);
    const [gold999, setGold999] = useState(toggableGoldPrice.gold999.value === 0 ? undefined : toggableGoldPrice.gold999.value);
    const [gold9999, setGold9999] = useState(toggableGoldPrice.gold9999.value === 0 ? undefined : toggableGoldPrice.gold9999.value);

    useEffect(() => {
        getTenantStatus();
    }, []);

    useEffect(() => {
        if (toggableGoldPrice.gold375G.value !== gold375G) setGold375G(toggableGoldPrice.gold375G.value === 0 ? undefined : toggableGoldPrice.gold375G.value);
        if (toggableGoldPrice.gold750.value !== gold750) setGold750(toggableGoldPrice.gold750.value === 0 ? undefined : toggableGoldPrice.gold750.value);
        if (toggableGoldPrice.gold916B.value !== gold916B) setGold916B(toggableGoldPrice.gold916B.value === 0 ? undefined : toggableGoldPrice.gold916B.value);
        if (toggableGoldPrice.gold916A.value !== gold916A) setGold916A(toggableGoldPrice.gold916A.value === 0 ? undefined : toggableGoldPrice.gold916A.value);
        if (toggableGoldPrice.gold916.value !== gold916) setGold916(toggableGoldPrice.gold916.value === 0 ? undefined : toggableGoldPrice.gold916.value);
        if (toggableGoldPrice.gold925.value !== gold925) setGold925(toggableGoldPrice.gold925.value === 0 ? undefined : toggableGoldPrice.gold925.value);
        if (toggableGoldPrice.gold999.value !== gold999) setGold999(toggableGoldPrice.gold999.value === 0 ? undefined : toggableGoldPrice.gold999.value);
        if (toggableGoldPrice.gold9999.value !== gold9999) setGold9999(toggableGoldPrice.gold9999.value === 0 ? undefined : toggableGoldPrice.gold9999.value);
    }, [goldPrice]);

    useEffect(() => {
        if (isMaintenance !== maintenanceMode) setMaintenance({ isMaintenance: maintenanceMode });
    }, [maintenanceMode]);

    const handleSaveClick = () => {
        if (gold916B !== goldPrice.gold916B || gold916A !== goldPrice.gold916A || gold925 !== goldPrice.gold925 || gold999 !== goldPrice.gold999 || gold9999 !== goldPrice.gold9999) {
            setToggableGoldPrice({
                gold375G: {
                    isActive: toggableGoldPrice.gold375G.isActive,
                    value: 0,
                },
                gold750: {
                    isActive: toggableGoldPrice.gold750.isActive,
                    value: (Number(gold750) ? Number(gold750) : 0),
                },
                gold916B: {
                    isActive: toggableGoldPrice.gold916B.isActive,
                    value: (Number(gold916B) ? Number(gold916B) : 0),
                },
                gold916A: {
                    isActive: toggableGoldPrice.gold916A.isActive,
                    value: (Number(gold916A) ? Number(gold916A) : 0),
                },
                gold925: {
                    isActive: toggableGoldPrice.gold925.isActive,
                    value: (Number(gold925) ? Number(gold925) : 0),
                },
                gold916: {
                    isActive: toggableGoldPrice.gold916.isActive,
                    value: (Number(gold916) ? Number(gold916) : 0),
                },
                gold999: {
                    isActive: toggableGoldPrice.gold999.isActive,
                    value: (Number(gold999) ? Number(gold999) : 0),
                },
                gold9999: {
                    isActive: toggableGoldPrice.gold9999.isActive,
                    value: (Number(gold9999) ? Number(gold9999) : 0),
                },
            });
        }
    };

    return (
        <div style={{ width: '100%', padding: '35px' }}>
            <div style={{ marginBottom: '80px' }}>
                <PageTitle size='xl'>Home   Dashboard</PageTitle>
            </div>
            <div>
                <Card style={styles.cardStyle}>
                    {tenantStatusLoading
                        ? <PageSkeleton count={20} />
                        : (
                            <div>
                                <Maintenance maintenanceSwitch={maintenanceMode} setMaintenanceSwitch={setMaintenanceMode} />
                                <GoldPriceSettings toggableGoldPrice={toggableGoldPrice} goldPrice={goldPrice} gold375G={gold375G} gold750={gold750} gold916B={gold916B} gold916A={gold916A} gold916={gold916} gold925={gold925} gold999={gold999} gold9999={gold9999} setGold375G={setGold375G} setGold750={setGold750} setGold916B={setGold916B} setGold916A={setGold916A} setGold916={setGold916} setGold925={setGold925} setGold999={setGold999} setGold9999={setGold9999} />
                                <Button primary label='Save' onClick={handleSaveClick} loading={loading} />
                            </div>
                        )}
                </Card>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    tenantStatusLoading: Selectors.getTenantTenantStatusAttempting(state),
    tenantStatusError: Selectors.getTenantTenantStatusError(state),
    tenantStatus: Selectors.getTenantTenantStatus(state),
    loading: Selectors.getTenantSetMaintenanceAttempting(state) || Selectors.getTenantSetGoldPriceAttempting(state),
    error: Selectors.getTenantSetGoldPriceError(state) || Selectors.getTenantSetMaintenanceError(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    setMaintenance: (data: ISetMaintenanceParams) => dispatch(Actions.tenantSetMaintenanceAttempt({ ...data })),
    setToggableGoldPrice: (params: ISetTogggableGoldPriceParams) => dispatch(Actions.tenantSetToggableGoldPriceAttempt(params)),
    getTenantStatus: () => dispatch(Actions.tenantGetTenantStatusAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
