import Colors from 'Themes/Colors';

export default {
    CategoriesCard: {
        padding: '20px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        minWidth: '1200px',
        minHeight: '500px',
        maxWidth: '1500px',
    },
    ComponentTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: Colors.blue,
        margin: '0px 3px 6px 3px',
    },
    ModalLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.lightblue,
        margin: '20px 3px 2px 3px',
    },
};
