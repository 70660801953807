import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import Actions from 'redux/Actions';
import Utils from 'lib/Utils';
import NavActions from 'services/Navigation/Actions';

export default function* watchStartup(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup, api);
}

function* handleStartup(api: AuthGateway) {
    const authToken = Utils.Auth.getAuthToken();

    const checkTokenValidity = async () => {
        const apiUrl = 'https://api.example.com/protected-resource';

        if (!authToken) {
            console.log('No authToken found');
            return false;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                console.log('Token is valid');
                return true;
            } if (response.status === 401) {
                console.log('Token expired or invalid');
                return false;
            }
            console.log(`Unexpected response: ${response.status}`);
            return false;
        } catch (error) {
            console.error('Error checking token validity:', error);
            return false;
        }
    };

    if (authToken) {
        // Set authToken to redux
        yield put(Actions.authLoginSuccess(authToken));
    } if (!authToken) {
        NavActions.navToLogin();
    }

    yield put(Actions.authStartupSuccess());
}
