import config from 'config';

import AuthGateway from './Auth';

import TenantGateway from './Tenant';

import OrderGateway from './Order';

import ReportsGateway from './Reports';

import ProductsGateway from './Products';

import CustomerGateway from './Customer';

const baseUrl = config.upshopBaseUrl as string;

const auth = new AuthGateway(baseUrl);
const tenant = new TenantGateway(baseUrl);
const order = new OrderGateway(baseUrl);
const reports = new ReportsGateway(baseUrl);
const products = new ProductsGateway(baseUrl);
const customer = new CustomerGateway(baseUrl);

export default {
    auth,
    tenant,
    order,
    reports,
    products,
    customer,
};
