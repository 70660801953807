import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import TenantGateway from 'api/Tenant';
import Toast from 'lib/Toast';

import Actions from 'redux/Actions';
import { ISetGoldPriceParams } from 'redux/slices/tenant/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';

export default function* watchSetGoldPrice(api: TenantGateway): SagaWatcherReturnType {
    yield takeEvery('tenant/tenantSetGoldPriceAttempt', handleSetGoldPrice, api);
}

function* handleSetGoldPrice(api: TenantGateway, data: PayloadAction<ISetGoldPriceParams>) {
    const { gold375G, gold750, gold916B, gold916A, gold916, gold925, gold999, gold9999 } = data.payload;
    const authToken = yield* select(Selectors.getAuthAuthToken);

    if (!authToken) {
        yield put(Actions.reportsUpdateCustomerFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    // const getValues = () => {
    //     let newPayload;
    //     data.payload.map(item) = () => {
    //         if (item !== undefined) {
    //             newPayload = { ...item };
    //         }
    //     };

    //     return newPayload;
    // };

    const response = yield* call([api, api.setGoldPrice], { gold375G, gold750, gold916B, gold916A, gold916, gold925, gold999, gold9999, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.tenantSetGoldPriceFailure(response.message));
        Toast.showError(response.message || 'Something went wrong. Please try again.');
        return;
    }

    Toast.show('Gold price successfully updated!');
    yield put(Actions.tenantSetGoldPriceSuccess({ gold375G, gold750, gold916B, gold916A, gold916, gold925, gold999, gold9999 }));
}
