import { ProductsState } from '.';
import { IGetProductsCategoriesResponse } from './types';

const getCategoriesAttempting = (state: ProductsState): boolean => state.actions.categories || false;
const getCategoriesError = (state: ProductsState): string => state.error.categories || '';
const getCategories = (state: ProductsState): IGetProductsCategoriesResponse[] => state.categories || [];

const getCategoriesCurrentIndex = (state: ProductsState): number => state.currentIndex || 1;
const getCategoriesCurrentPage = (state: ProductsState): number => state.currentPage || 1;

const getCategoriesQuery = (state: ProductsState): string => state.query || '';
const getCategoriesSortQuantity = (state: ProductsState): number => state.sortQuantity ?? -1;
const getCategoriesSortTitle = (state: ProductsState): number => state.sortTitle ?? -1;

const getDeleteCategoryAttempting = (state: ProductsState): boolean => state.actions.deleteCategory || false;
const getDeleteCategoryError = (state: ProductsState): string => state.error.deleteCategory || '';
const getDeleteCategory = (state: ProductsState): string => state.deleteCategory || '';

const getUpdateCategoryStatusAttempting = (state: ProductsState): boolean => state.actions.updateCategoryStatus || false;
const getUpdateCategoryStatusError = (state: ProductsState): string => state.error.updateCategoryStatus || '';
const getUpdateCategoryStatus = (state: ProductsState): string => state.updateCategoryStatus || '';

const getCreateCategoryAttempting = (state: ProductsState): boolean => state.actions.createProductCategory || false;
const getCreateCategoryError = (state: ProductsState): string => state.error.createProductCategory || '';
const getCreateCategory = (state: ProductsState): string => state.createProductCategory || '';

export default {
    getCategoriesAttempting,
    getCategoriesError,
    getCategories,

    getCategoriesCurrentIndex,
    getCategoriesCurrentPage,

    getCategoriesQuery,
    getCategoriesSortQuantity,
    getCategoriesSortTitle,

    getDeleteCategoryAttempting,
    getDeleteCategoryError,
    getDeleteCategory,

    getUpdateCategoryStatusAttempting,
    getUpdateCategoryStatusError,
    getUpdateCategoryStatus,

    getCreateCategoryAttempting,
    getCreateCategoryError,
    getCreateCategory,
};
