import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IAdminOrderDetails } from '@upshop/types';
import { IGetOrderLogsResponse } from 'redux/slices/order/types';
import Utils from 'lib/Utils';
import {
    IOrderGateway,
    GetOrdersParams,
    GetOrdersResponse,
    GetOrderDetailsParams,
    UpdateOrderStatusParams,
    ApproveManualBankTransferParams,
    ApproveManualBankTransferResponse,
    UpdateTrackingIdParams,
    GetOrderLogsParams,
    UpdateOrderTrackingInformationParams,
    AddOrderRemarksParams,
} from './OrderBase';

export default class OrderGateway extends IOrderGateway {
    async getOrders(params: GetOrdersParams): GatewayResponse<GetOrdersResponse | null> {
        const { authToken, ...restGetOrderParams } = params;

        const response: ApiResponse<GetOrdersResponse> = await this.api.get('/tenant/orders', restGetOrderParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async getOrderDetails(params: GetOrderDetailsParams): GatewayResponse<IAdminOrderDetails | null> {
        const { authToken, orderId } = params;

        const response: ApiResponse<IAdminOrderDetails> = await this.api.get(`/tenant/orderDetails/${orderId}`, {}, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async updateOrderStatus(params: UpdateOrderStatusParams): GatewayResponse<null> {
        const { authToken, ...restUpdateOrderStatusParams } = params;

        const response: ApiResponse<null> = await this.api.put('/tenant/orders/status', restUpdateOrderStatusParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async updateTrackingId(params: UpdateTrackingIdParams): GatewayResponse<null> {
        const { authToken, trackingId, orderId, logisticCompany } = params;

        const response: ApiResponse<null> = await this.api.post('/tenant/orders/addTracking', {
            trackingId,
            orderId,
            logisticCompany,
        }, Utils.Auth.getHeaders(authToken));

        return this.process(response);
    }

    async updateTrackingInformation(params: UpdateOrderTrackingInformationParams): GatewayResponse<null> {
        const { authToken, trackingNo, orderId, shippingCarrier, trackingUrl } = params;

        const response: ApiResponse<null> = await this.api.post('/tenant/orders/trackingInformation', {
            trackingNo,
            orderId,
            shippingCarrier,
            trackingUrl,
        }, Utils.Auth.getHeaders(authToken));

        return this.process(response);
    }

    async addOrderRemarks(params: AddOrderRemarksParams): GatewayResponse<null> {
        const { authToken, orderId, remarks } = params;

        const response: ApiResponse<null> = await this.api.post('/tenant/orders/remarks', {
            orderId,
            remarks,
        }, Utils.Auth.getHeaders(authToken));

        return this.process(response);
    }

    async approveManualBankTransfer(params: ApproveManualBankTransferParams): GatewayResponse<ApproveManualBankTransferResponse | null> {
        const { authToken, ...restApproveManualBankTransferParams } = params;

        const response: ApiResponse<ApproveManualBankTransferResponse> = await this.api.put('/tenant/orders/manualBankTransfer', restApproveManualBankTransferParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async exportOrder(params: GetOrdersParams): GatewayResponse<string | null> {
        const { authToken, ...restGetOrderParams } = params;

        const response: ApiResponse<string> = await this.api.get('/tenant/orders/export', restGetOrderParams, Utils.Auth.getHeaders(authToken));
        return this.process(response);
    }

    async placeOrderLogs(params: GetOrderLogsParams): GatewayResponse<IGetOrderLogsResponse[] | null> {
        const { authToken } = params;

        const response: ApiResponse<IGetOrderLogsResponse[]> = await this.api.get('/tenant/orders/jemisys/placerOrder/logs', {}, Utils.Auth.getHeaders(authToken));

        return this.process(response);
    }
}
