import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { PageSkeleton, Label, Button, Input } from '@upshop/exodia';
import { IOrderStatusEnum, IOrderPaymentMethodEnum, IUpdateOrderTrackingIdParams } from 'redux/slices/order/types';
import Icons from 'Icons';
import { ICustomAttribute, ILogisticsProvider, IOrderLogs } from '@upshop/types';
import Utils from 'lib/Utils';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Styles from './styles';

export interface OrderDetailsDataProps {
    id: string;
    orderDate: string;
    status: IOrderStatusEnum;
    location: (string | undefined)[];
    customerRemarks: string;
    currency: string;
    totalPrice: number;
    paymentMethod: IOrderPaymentMethodEnum;
    shippingFee?: number;
    remarks?: string;
    name: string;
    address: string;
    phoneNumber?: number
    trackingId?: string;
    logisticsProvider?: ILogisticsProvider;
    customAttributes?: ICustomAttribute[];
    deliveryCustomAttributes?: ICustomAttribute[];
    orderLogs?: IOrderLogs[];
}

interface OrderDetailsProps {
    data: OrderDetailsDataProps | undefined;
    orderDetailsError: string;
    orderDetailsLoading: boolean;
    updating: boolean;
    updatingId: string;
    updateOrderStatus: (orderId: string, status: IOrderStatusEnum) => void;
    modal: boolean;
    setModal: (isOpen: boolean) => void;
    updateOrderTrackingId: (params: IUpdateOrderTrackingIdParams) => void;
}

const OrderDetails = (props: OrderDetailsProps): JSX.Element => {
    dayjs.extend(utc);

    const { data, orderDetailsError, orderDetailsLoading, updating, updatingId, updateOrderStatus, modal, setModal, updateOrderTrackingId } = props;

    const [locations, setLocations] = useState<(string | undefined)[]>([]);
    const [dropDown, setDropDown] = useState(false);
    const [orderStatus, setOrderStatus] = useState<IOrderStatusEnum>();
    const [trackingNumber, setTrackingNumber] = useState('');
    const [shippingCarrier, setShippingCarrier] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');
    const [trackingError, setTrackingError] = useState('');

    useEffect(() => {
        if (data?.location) {
            setLocations(data.location.filter(item => item !== undefined && !locations?.includes(item)));
        }

        if (data?.status && data.status !== orderStatus) {
            setOrderStatus(data.status);
        }
    }, [data?.location, data?.status]);

    const handleUpdateOrderStatus = (statusEnum: IOrderStatusEnum) => {
        if (typeof (statusEnum) !== undefined && data?.id) {
            setOrderStatus(statusEnum);
            updateOrderStatus(data.id, statusEnum);
        }
    };

    const handleUpdatingShippingInfo = () => {
        setTrackingError('');

        if (!trackingNumber) {
            setTrackingError('Tracking number is required');
            return;
        }
        if (!shippingCarrier) {
            setTrackingError('Shipping carrier is required');
            return;
        }
        if (!trackingUrl) {
            setTrackingError('Tracking url is required');
            return;
        }

        updateOrderTrackingId({
            trackingNo: trackingNumber,
            shippingCarrier,
            orderId: data?.id || '',
            trackingUrl,
        });
    };

    const toggleModal = () => {
        setModal(!modal);
        setTrackingNumber('');
        setShippingCarrier('');
    };

    const renderTrackingInformationModal = () => {
        return (
            <Modal size='md' isOpen={modal} toggle={toggleModal}>
                <ModalHeader>
                    <div style={{ ...Styles.ComponentTitle, margin: 0 }}>
                        Tracking Information
                    </div>
                </ModalHeader>
                <ModalBody
                    style={{
                        padding: '20px',
                    }}
                >
                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Number'
                        placeholder={data?.trackingId}
                        value={trackingNumber}
                        onChange={(e) => setTrackingNumber(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Tracking Url'
                        placeholder={data?.logisticsProvider?.url}
                        value={trackingUrl}
                        onChange={(e) => setTrackingUrl(e.currentTarget.value)}
                    />

                    <Input
                        labelStyle={Styles.TrackingInfoModalLabel}
                        style={{ width: '100%' }}
                        label='Shipping Courier'
                        placeholder={data?.logisticsProvider?.name}
                        value={shippingCarrier}
                        onChange={(e) => setShippingCarrier(e.currentTarget.value)}
                    />

                    {
                        trackingError && <div style={{ color: 'red', marginTop: '10px' }}>{trackingError}</div>
                    }

                    <div style={{ display: ' flex', marginTop: '20px', justifyContent: 'center' }}>
                        <Button
                            style={{ marginLeft: '0px' }}
                            label='Cancel'
                            onClick={toggleModal}
                        />

                        <Button
                            style={{ marginLeft: '20px' }}
                            label='Save'
                            primary
                            onClick={handleUpdatingShippingInfo}
                            loading={updating}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    const renderDropdownList = () => {
        return (
            <div>
                {/* <DropdownItem hidden={orderStatus === IOrderStatusEnum.PendingPayment} key='Pending Payment' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PendingPayment)}>Pending Payment</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.PaymentFailed} key='Payment Failed' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PaymentFailed)}>Payment Failed</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.PaymentProcessing} key='Payment Processing' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.PaymentProcessing)}>Payment Processing</DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Unfulfilled} key='Unfulfilled' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Unfulfilled)}>Unfulfilled</DropdownItem> */}
                <DropdownItem
                    hidden={orderStatus === IOrderStatusEnum.Shipped}
                    key='Shipped'
                    onClick={() => {
                        setModal(true);
                    }}
                >
                    Shipped
                </DropdownItem>
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Completed} key='Completed' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Completed)}>Completed</DropdownItem>
                {/* <DropdownItem hidden={orderStatus === IOrderStatusEnum.Cancelled} key='Cancelled' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Cancelled)}>Cancelled</DropdownItem> */}
                <DropdownItem hidden={orderStatus === IOrderStatusEnum.Refunded} key='Refunded' onClick={() => handleUpdateOrderStatus(IOrderStatusEnum.Refunded)}>Refunded</DropdownItem>
            </div>
        );
    };

    const renderOrderDetails = () => {
        return (
            <Row>
                <Col>
                    <Label label='Order No.' text={data?.id || '-'} />
                    <Label label='Date' text={dayjs.utc(data?.orderDate).local().format('D MMMM  YYYY h:mm A') || '-'} />
                    <Label label='Status' text={data?.status !== undefined ? Utils.Order.getStatus(data.status) : '-'} />
                    {/* <Label label='Store Location' text={locations?.length ? locations : '-'} /> */}
                </Col>

                <Col>
                    <div>
                        <Label label='Order Remarks' text={data?.customerRemarks || '-'} />
                    </div>

                    <Label label='Total Paid Amount' text={data?.totalPrice ? `${data?.currency} ${data.totalPrice}` : '-'} />
                    <Label
                        hidden={data?.customAttributes === undefined}
                        label='Metadata'
                        text={() => {
                            if (data?.customAttributes !== undefined) {
                                data.customAttributes.map(attribute => {
                                    return (
                                        <div style={{ display: 'block' }}>
                                            <div>{attribute.name}</div>
                                            <div>{attribute.value}</div>
                                        </div>
                                    );
                                });
                            }
                        }}
                    />
                </Col>
            </Row>
        );
    };

    const renderDeliveryDetails = () => {
        return (
            <Row>
                <Col>
                    {/* <Label label='Delivery Status' text={data?.status !== undefined ? Utils.Order.getDeliveryStatus(data.status) : '-'} /> */}
                    {/* <Label label='Recipient Name' text={data?.name !== ' ' ? data?.name : '-'} />
                    <Label label='Recipient Phone No.' text={data?.phoneNumber || '-'} />
                    <Label label='Recipient Address' text={data?.address || '-'} /> */}
                    <Label label='Delivery Method' text={data?.logisticsProvider?.name || 'Not yet assigned'} />
                    <Label label='Shipping Fee' text={`${data?.currency} ${data?.shippingFee}` || '-'} />
                </Col>

                <Col>
                    <Label label='Tracking No.' text={data?.trackingId || 'Not yet assigned'} />
                    <Label label='Tracking URL' text={data?.logisticsProvider?.url || 'Not yet assigned'} />
                    {/* <Label label='Delivery Method' text={data?.logisticsProvider?.name || '-'} /> */}
                    <Label
                        hidden={data?.deliveryCustomAttributes === undefined}
                        label='Metadata'
                        text={() => {
                            if (data?.deliveryCustomAttributes !== undefined) {
                                data.deliveryCustomAttributes.map(attribute => {
                                    return (
                                        <div style={{ display: 'block' }}>
                                            <div>{attribute.name}</div>
                                            <div>{attribute.value}</div>
                                        </div>
                                    );
                                });
                            }
                        }}
                    />
                </Col>
            </Row>
        );
    };

    const renderAuditLogs = () => {
        return (
            <Row>
                <Col>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        {data?.orderLogs?.map((log) => {
                            return (
                                <div key={log._id} style={{ border: '1px solid #e0e0e0', borderRadius: 12, padding: 10 }}>
                                    <p style={{ margin: 0 }}>{log.action}</p>
                                    <p style={{ margin: 0 }}>{dayjs(log.createdAt).toString()}</p>
                                </div>
                            );
                        })}
                    </div>
                </Col>
            </Row>
        );
    };

    const renderNotes = () => {
        // return notes.map()
        return (
            <div>
                <div style={Styles.OrderNoteNotes}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat laboriosam fugiat
                </div>

                <div style={{ fontSize: '12px', color: 'rgb(0, 0, 0, 0.6)', fontStyle: 'italic' }}>
                    User1 - (date here)
                </div>
            </div>
        );
    };

    const renderOrderNoteBody = () => {
        return (
            <div>
                <div style={{ width: '100%', marginBottom: '20px' }}>
                    <div style={Styles.OrderNoteAddRemark}>
                        Add Remark
                    </div>
                    {/* <textarea
                        maxLength={300}
                        style={Styles.OrderNoteTextArea}
                        value={remark}
                        onChange={(e) => setRemark(e.currentTarget.value)}
                    /> */}
                    <div style={{ width: '100%', height: '40px' }}>
                        <Button
                            label='Submit'
                            style={{ float: 'right' }}
                        // onclick here
                        />
                    </div>
                </div>

                <div>
                    <div style={Styles.OrderNoteAddRemark}>
                        Notes
                    </div>
                    {renderNotes()}
                </div>
            </div>
        );
    };

    const renderActivityLog = () => {
        return (
            data?.orderLogs?.map((log, index) => (

                <div key={log._id}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', overflow: 'hidden', height: 'auto' }}>
                        <img alt='upCaret' src={Icons.angleUp} style={{ width: 45, height: 'auto' }} />
                        <img alt='verticalLine' src={Icons.verticalLine} style={{ width: 80, height: 'auto', position: 'absolute', left: '-18px', top: '-2px' }} />
                    </div>
                    <div style={{ border: '1px solid rgb(0,0,0,0.1)', borderRadius: 12, padding: 10, color: 'rgb(0, 165, 155, 1)', fontWeight: 'bold' }}>
                        <p style={{ margin: 0 }}>{log.action}</p>
                        <p style={{ margin: 0 }}>{dayjs(log.createdAt).format('MMMM D, YYYY h:mm A')}</p>
                    </div>
                    <div style={{ height: 22, overflow: 'hidden' }}>
                        <div style={{ position: 'relative' }}>
                            <img alt='verticalLine' src={Icons.verticalLine} style={{ width: 80, position: 'absolute', left: '-18px', top: '-27px' }} />
                        </div>
                    </div>
                </div>
            ))

        );
    };

    return (
        <Card style={Styles.OrderDetailsCard}>
            {orderDetailsLoading
                ? <PageSkeleton count={24} />
                : (
                    <Col>
                        <Row style={{ height: '260px' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                <div style={Styles.ComponentTitle}>
                                    Order Details
                                </div>

                                {
                                    data?.id ? (
                                        <div>
                                            <Dropdown isOpen={dropDown} toggle={() => setDropDown(!dropDown)}>
                                                <DropdownToggle style={{ width: 'fit-content', overflow: 'hidden', backgroundColor: 'white', color: '#4E6DDD', border: '1px solid #E1E3E6', borderRadius: 8 }} caret>
                                                    {updating && updatingId === data?.id ? <Spinner /> : (Utils.Order.getStatus(orderStatus) || 'No status')}
                                                </DropdownToggle>
                                                <DropdownMenu end>
                                                    {renderDropdownList()}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    ) : (
                                        <div>
                                            <Dropdown isOpen={dropDown} toggle={() => setDropDown(!dropDown)}>
                                                <DropdownToggle style={{ width: 'fit-content', overflow: 'hidden', backgroundColor: 'white', color: '#4E6DDD', border: '1px solid #E1E3E6', borderRadius: 8 }} caret>
                                                    Payment Status Not Found
                                                </DropdownToggle>
                                            </Dropdown>
                                        </div>
                                    )
                                }
                            </div>

                            {renderOrderDetails()}
                        </Row>

                        <Row>
                            <div style={{ ...Styles.ComponentTitle, paddingBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <p>Delivery Details</p>

                                {
                                    data?.logisticsProvider?.name && (
                                        <Button label='Edit' onClick={() => setModal(true)} style={{ color: '#4069ff', backgroundColor: 'white', border: '1px solid #0000001a', width: 'fit-content' }} />
                                    )
                                }

                            </div>
                            {renderDeliveryDetails()}
                        </Row>

                        {/* <Row>
                            <div style={{ ...Styles.ComponentTitle, paddingBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                                <p>Order Logs</p>
                            </div>
                            {renderAuditLogs()}
                        </Row> */}

                        {/* <Row style={{ marginTop: '30px' }}>
                            <div style={Styles.ComponentTitle}>
                                Order Note
                            </div>
                            {renderOrderNoteBody()}
                        </Row>

                        <Row style={{ marginTop: '30px' }}>
                            <div>
                                <div style={Styles.ComponentTitle}>
                                    Activity Log
                                </div>
                                {renderActivityLog()}
                            </div>
                        </Row> */}

                        <Row style={{ marginTop: '30px' }}>
                            <div>
                                <div style={Styles.ComponentTitle}>
                                    Activity Log
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {renderActivityLog()}
                                </div>
                            </div>
                        </Row>

                        {renderTrackingInformationModal()}
                    </Col>
                )}
        </Card>
    );
};

export default OrderDetails;
