import Colors from 'Themes/Colors';

export default {
    CustomersCard: {
        padding: '20px',
        border: '1px solid rgb(0,0,0,0.1)',
        borderRadius: '15px',
        boxShadow: '0px 0px 50px 10px rgba(0,0,0,0.05)',
        minWidth: '1200px',
        maxWidth: '1500px',
        height: '550px',
    },
    CustomerDetailsBackButton: {
        margin: '0px 20px 0px 0px',
        width: '50px',
        height: '50px',
    },
    CustomerDetailsCard: {
        width: '475px',
        height: '600px',
        margin: '10px 0px 0px 0px',
        padding: '10px 25px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.24)',
        borderRadius: '20px',
    },
    ComponentTitle: {
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        color: Colors.blue,
        margin: '0px 3px 20px 3px',
    },
    ComponentLabel: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: Colors.lightblue,
        margin: '0px 3px 2px 3px',
    },
    CustomerHistoryDetailsCard: {
        margin: '10px 0px 0px 30px',
        padding: '10px 5px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.24)',
        borderRadius: '20px',
        minWidth: '500px',
    },
    CustomerHistoryDetailsTextInput: {
        outline: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: '1px solid #E6EAF0',
        padding: 0,
        margin: 0,
    },
};
