import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { GetCustomerDetailsResponse } from 'api/CustomerBase';
import { CustomerReduxState, IGetCustomerDetailsParams, IGetCustomersListResponse, IGetCustomersParams } from './types';

const initialState: CustomerReduxState = {
    actions: {
        customers: false,
        customerDetails: false,
        exportCustomers: false,
    },
    error: {
        customers: '',
        customerDetails: '',
        exportCustomers: '',
    },
    customers: [
        {
            index: -1,
            maxIndex: -1,
            data: [],
        },
    ],
    customerDetails: {
        name: '',
        mobileNumber: '',
        totalOrder: 0,
        totalRevenue: 0,
        averageRevenuePerOrder: 0,
        email: '',
        address: {
            address1: '',
            address2: '',
            address3: '',
            state: '',
            city: '',
            postCode: '',
            country: '',
            customAttributes: [
                {
                    name: '',
                    value: '',
                },
            ],
        },
        metadata: [
            {
                name: '',
                value: '',
            },
        ],
    },
    query: undefined,
    filterStartDate: undefined,
    filterEndDate: undefined, // default to today's
    paginationIndex: 1,
    currentPage: 1,
    exportCustomers: '',
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        customerGetCustomersAttempt: (state, _action: PayloadAction<IGetCustomersParams>) => {
            state.actions.customers = true;
            state.error.customers = '';
        },
        customerGetCustomersSuccess: (state, action: PayloadAction<IGetCustomersListResponse>) => {
            state.actions.customers = false;
            if (action.payload) {
                state.customers[action.payload.index - 1] = action.payload;
            }
        },
        customerGetCustomersFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.customers = false;
            if (action.payload) {
                state.error.customers = action.payload;
            }
        },

        customerSetFilterStartDate: (state, action: PayloadAction<string | undefined>) => {
            state.filterStartDate = action.payload;
            state.filterEndDate = undefined;
        },
        customerSetFilterEndDate: (state, action: PayloadAction<string | undefined>) => {
            state.filterEndDate = action.payload;
        },

        customerSetQuery: (state, action: PayloadAction<string | undefined>) => {
            state.query = action.payload;
        },

        customerSetPaginationIndex: (state, action: PayloadAction<number>) => {
            if (action.payload) state.paginationIndex = action.payload;
        },
        customerSetCurrentPage: (state, action: PayloadAction<number>) => {
            if (action.payload) state.currentPage = action.payload;
        },

        customerGetCustomersDetailsAttempt: (state, _action: PayloadAction<IGetCustomerDetailsParams>) => {
            state.actions.customerDetails = true;
            state.error.customerDetails = '';
        },
        customerGetCustomersDetailsSuccess: (state, action: PayloadAction<GetCustomerDetailsResponse>) => {
            state.actions.customerDetails = false;
            if (action.payload) {
                state.customerDetails = action.payload;
            }
        },
        customerGetCustomersDetailsFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.customerDetails = false;
            if (action.payload) {
                state.error.customerDetails = action.payload;
            }
        },
        customerExportCustomersAttempt: (state, _action: PayloadAction<IGetCustomersParams>) => {
            state.actions.exportCustomers = true;
            state.error.exportCustomers = '';
        },
        customerExportCustomersSuccess: (state, action: PayloadAction<string | undefined>) => {
            state.exportCustomers = action.payload;
        },
        customerExportCustomersFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.exportCustomers = false;
            if (action.payload) {
                state.error.exportCustomers = action.payload;
            }
        },
    },
});

export type CustomerState = typeof initialState;

export default {
    actions: customerSlice.actions,
    reducers: customerSlice.reducer,
};
