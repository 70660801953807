import { IAdminCustomerDetails, IAdminOrderDetails } from '@upshop/types';
import { IGetOrderLogsResponse, IGetOrdersResponse, IOrderStatusEnum } from './slices/order/types';
import { IAdminTenantStatus } from './slices/tenant/types';
import { IGetCustomersResponse } from './slices/reports/types';
import auth from './slices/auth/Selectors';
import tenant from './slices/tenant/Selectors';
import order from './slices/order/Selectors';
import reports from './slices/reports/Selectors';
import products from './slices/products/Selectors';
import customers from './slices/customer/Selectors';

import { RootState } from './store';
import { IGetProductsCategoriesResponse } from './slices/products/types';
import { IGetCustomerDetailsResponse, IGetCustomersListResponse } from './slices/customer/types';

const getAuthLoginAttempting = (state: RootState): boolean => auth.getLoginAttempting(state.auth);
const getAuthLoginError = (state: RootState): string => auth.getLoginError(state.auth);

const getAuthStartupAttempting = (state: RootState): boolean => auth.getStartupAttempting(state.auth);
const getAuthStartupError = (state: RootState): string => auth.getStartupError(state.auth);
const getAuthAuthToken = (state: RootState): string => auth.getAuthToken(state.auth);

const getAuthResetPasswordAttempting = (state: RootState): boolean => auth.getResetPasswordAttempting(state.auth);
const getAuthResetPasswordError = (state: RootState): string => auth.getResetPasswordError(state.auth);
const getAuthResetPasswordSuccess = (state: RootState): boolean => auth.getResetPasswordSuccess(state.auth);

const getCustomerCustomersAttempting = (state: RootState): boolean => customers.getCustomersAttempting(state.customer);
const getCustomerCustomersError = (state: RootState): string => customers.getCustomersError(state.customer);
const getCustomerCustomers = (state: RootState): IGetCustomersListResponse[] => customers.getCustomers(state.customer);

const getCustomerFilterStartDate = (state: RootState): string | undefined => customers.getFilterStartDate(state.customer);
const getCustomerFilterEndDate = (state: RootState): string | undefined => customers.getFilterEndDate(state.customer);

const getCustomerQuery = (state: RootState): string => customers.getQuery(state.customer);

const getCustomerPaginationIndex = (state: RootState): number => customers.getPaginationIndex(state.customer);
const getCustomerCurrentPage = (state: RootState): number => customers.getCurrentPage(state.customer);

const getCustomerCustomersDetailsAttempting = (state: RootState): boolean => customers.getCustomersDetailsAttempting(state.customer);
const getCustomerCustomersDetailsError = (state: RootState): string => customers.getCustomersDetailsError(state.customer);
const getCustomerCustomersDetails = (state: RootState): IGetCustomerDetailsResponse => customers.getCustomersDetails(state.customer);

const getCustomerCustomersExportAttempting = (state: RootState): boolean => customers.getCustomersExportAttempting(state.customer);
const getCustomerCustomersExportError = (state: RootState): string => customers.getCustomersExportError(state.customer);
const getCustomerCustomersExport = (state: RootState): string => customers.getCustomersExport(state.customer);

const getOrderOrdersAttempting = (state: RootState): boolean => order.getOrdersAttempting(state.order);
const getOrderOrdersError = (state: RootState): string => order.getOrdersError(state.order);
const getOrderOrders = (state: RootState): IGetOrdersResponse[] => order.getOrders(state.order);

const getOrderOrderDetailsAttempting = (state: RootState): boolean => order.getOrderDetailsAttempting(state.order);
const getOrderOrderDetailsError = (state: RootState): string => order.getOrderDetailsError(state.order);
const getOrderOrderDetails = (state: RootState): IAdminOrderDetails => order.getOrderDetails(state.order);

const getOrderUpdateOrderStatusAttempting = (state: RootState): boolean => order.getUpdateOrderStatusAttempting(state.order);
const getOrderUpdateOrderStatusError = (state: RootState): string => order.getUpdateOrderStatusError(state.order);
const getOrderUpdateOrderStatus = (state: RootState): string => order.getUpdateOrderStatus(state.order);// id of the order that is being updated

const getOrderPaginationIndex = (state: RootState): number => order.getPaginationIndex(state.order);
const getOrderCurrentPage = (state: RootState): number => order.getCurrentPage(state.order);

const getOrderApproveManualBankTransferAttempting = (state: RootState): boolean => order.getApproveManualBankTransferAttempting(state.order);
const getOrderApproveManualBankTransferError = (state: RootState): string => order.getApproveManualBankTransferError(state.order);

const getOrderFilterStartDate = (state: RootState): string | undefined => order.getFilterStartDate(state.order);
const getOrderFilterEndDate = (state: RootState): string | undefined => order.getFilterEndDate(state.order);

const getOrderQuery = (state: RootState): string => order.getQuery(state.order);
const getOrderFilters = (state: RootState): IOrderStatusEnum[] => order.getFilters(state.order);
const getOrderTrackingIdModalIsOpen = (state: RootState): boolean => order.getTrackingIdModalIsOpen(state.order);

const getOrderOrderExportAttempting = (state: RootState): boolean => order.getOrderExportAttempting(state.order);
const getOrderOrderExportError = (state: RootState): string => order.getOrderExportError(state.order);
const getOrderOrderExport = (state: RootState): string => order.getOrderExport(state.order);

const getOrderJemisysPlaceOrderLogsAttempting = (state: RootState): boolean => order.getJemisysPlaceOrderLogsAttempting(state.order);
const getOrderJemisysPlaceOrderLogsError = (state: RootState): string => order.getJemisysPlaceOrderLogsError(state.order);
const getOrderJemisysPlaceOrderLogs = (state: RootState): IGetOrderLogsResponse[] => order.getJemisysPlaceOrderLogs(state.order);

const getProductsCategoriesAttempting = (state: RootState): boolean => products.getCategoriesAttempting(state.products);
const getProductsCategoriesError = (state: RootState): string => products.getCategoriesError(state.products);
const getProductsCategories = (state: RootState): IGetProductsCategoriesResponse[] => products.getCategories(state.products);

const getProductsCategoriesCurrentIndex = (state: RootState): number => products.getCategoriesCurrentIndex(state.products);
const getProductsCategoriesCurrentPage = (state: RootState): number => products.getCategoriesCurrentPage(state.products);

const getProductsCategoriesQuery = (state: RootState): string => products.getCategoriesQuery(state.products);
const getProductsCategoriesSortQuantity = (state: RootState): number => products.getCategoriesSortQuantity(state.products);
const getProductsCategoriesSortTitle = (state: RootState): number => products.getCategoriesSortTitle(state.products);

const getProductsDeleteCategoryAttempting = (state: RootState): boolean => products.getDeleteCategoryAttempting(state.products);
const getProductsDeleteCategoryError = (state: RootState): string => products.getDeleteCategoryError(state.products);
const getProductsDeleteCategory = (state: RootState): string => products.getDeleteCategory(state.products);

const getProductsUpdateCategoryStatusAttempting = (state: RootState): boolean => products.getUpdateCategoryStatusAttempting(state.products);
const getProductsUpdateCategoryStatusError = (state: RootState): string => products.getUpdateCategoryStatusError(state.products);
const getProductsUpdateCategoryStatus = (state: RootState): string => products.getUpdateCategoryStatus(state.products);

const getProductsCreateCategoryAttempting = (state: RootState): boolean => products.getCreateCategoryAttempting(state.products);
const getProductsCreateCategoryError = (state: RootState): string => products.getCreateCategoryError(state.products);
const getProductsCreateCategory = (state: RootState): string => products.getCreateCategory(state.products);

const getReportsCustomersAttempting = (state: RootState): boolean => reports.getCustomersAttempting(state.reports);
const getReportsCustomersError = (state: RootState): string => reports.getCustomersError(state.reports);
const getReportsCustomers = (state: RootState): IGetCustomersResponse[] => reports.getCustomers(state.reports);

const getReportsCustomersCurrentPage = (state: RootState): number => reports.getCustomersCurrentPage(state.reports);
const getReportsCustomersCurrentIndex = (state: RootState): number => reports.getCustomersCurrentIndex(state.reports);

const getReportsCustomerDetailsAttempting = (state: RootState): boolean => reports.getCustomerDetailsAttempting(state.reports);
const getReportsCustomerDetailsError = (state: RootState): string => reports.getCustomerDetailsError(state.reports);
const getReportsCustomerDetails = (state: RootState): IAdminCustomerDetails => reports.getCustomerDetails(state.reports);

const getReportsUpdateCustomerAttempting = (state: RootState): boolean => reports.getUpdateCustomerAttempting(state.reports);
const getReportsUpdateCustomerError = (state: RootState): string => reports.getUpdateCustomerError(state.reports);

const getReportsCustomerDetailsEditMode = (state: RootState): boolean => reports.getCustomerDetailsEditMode(state.reports);

const getTenantTenantStatusAttempting = (state: RootState): boolean => tenant.getTenantStatusAttempting(state.tenant);
const getTenantTenantStatusError = (state: RootState): string => tenant.getTenantStatusError(state.tenant);
const getTenantTenantStatus = (state: RootState): IAdminTenantStatus => tenant.getTenantStatus(state.tenant);

const getTenantSetMaintenanceAttempting = (state: RootState): boolean => tenant.getSetMaintenanceAttempting(state.tenant);
const getTenantSetMaintenanceError = (state: RootState): string => tenant.getSetMaintenanceError(state.tenant);

const getTenantSetGoldPriceAttempting = (state: RootState): boolean => tenant.getSetGoldPriceAttempting(state.tenant);
const getTenantSetGoldPriceError = (state: RootState): string => tenant.getSetGoldPriceError(state.tenant);

const getTenantSetToggableGoldPriceAttempting = (state: RootState): boolean => tenant.getSetToggableGoldPriceAttempting(state.tenant);
const getTenantSetToggableGoldPriceError = (state: RootState): string => tenant.getSetToggableGoldPriceError(state.tenant);

export default {
    getAuthLoginAttempting,
    getAuthLoginError,

    getAuthAuthToken,

    getAuthStartupAttempting,
    getAuthStartupError,

    getAuthResetPasswordAttempting,
    getAuthResetPasswordError,
    getAuthResetPasswordSuccess,

    getCustomerCustomersAttempting,
    getCustomerCustomersError,
    getCustomerCustomers,

    getCustomerFilterStartDate,
    getCustomerFilterEndDate,

    getCustomerQuery,

    getCustomerPaginationIndex,
    getCustomerCurrentPage,

    getCustomerCustomersDetailsAttempting,
    getCustomerCustomersDetailsError,
    getCustomerCustomersDetails,

    getCustomerCustomersExportAttempting,
    getCustomerCustomersExportError,
    getCustomerCustomersExport,

    getOrderOrdersAttempting,
    getOrderOrdersError,
    getOrderOrders,

    getOrderOrderDetailsAttempting,
    getOrderOrderDetailsError,
    getOrderOrderDetails,

    getOrderUpdateOrderStatusAttempting,
    getOrderUpdateOrderStatusError,
    getOrderUpdateOrderStatus,

    getOrderPaginationIndex,
    getOrderCurrentPage,

    getOrderApproveManualBankTransferAttempting,
    getOrderApproveManualBankTransferError,

    getOrderFilterStartDate,
    getOrderFilterEndDate,

    getOrderQuery,
    getOrderFilters,
    getOrderTrackingIdModalIsOpen,

    getOrderOrderExportAttempting,
    getOrderOrderExportError,
    getOrderOrderExport,

    getOrderJemisysPlaceOrderLogsAttempting,
    getOrderJemisysPlaceOrderLogsError,
    getOrderJemisysPlaceOrderLogs,

    getProductsCategoriesAttempting,
    getProductsCategoriesError,
    getProductsCategories,

    getProductsCategoriesCurrentIndex,
    getProductsCategoriesCurrentPage,

    getProductsCategoriesQuery,
    getProductsCategoriesSortQuantity,
    getProductsCategoriesSortTitle,

    getProductsDeleteCategoryAttempting,
    getProductsDeleteCategoryError,
    getProductsDeleteCategory,

    getProductsUpdateCategoryStatusAttempting,
    getProductsUpdateCategoryStatusError,
    getProductsUpdateCategoryStatus,

    getProductsCreateCategoryAttempting,
    getProductsCreateCategoryError,
    getProductsCreateCategory,

    getReportsCustomersAttempting,
    getReportsCustomersError,
    getReportsCustomers,

    getReportsCustomersCurrentPage,
    getReportsCustomersCurrentIndex,

    getReportsCustomerDetailsAttempting,
    getReportsCustomerDetailsError,
    getReportsCustomerDetails,

    getReportsUpdateCustomerAttempting,
    getReportsUpdateCustomerError,

    getReportsCustomerDetailsEditMode,

    getTenantTenantStatusAttempting,
    getTenantTenantStatusError,
    getTenantTenantStatus,

    getTenantSetMaintenanceAttempting,
    getTenantSetMaintenanceError,

    getTenantSetGoldPriceAttempting,
    getTenantSetGoldPriceError,

    getTenantSetToggableGoldPriceAttempting,
    getTenantSetToggableGoldPriceError,
};
