import { put, call, select, takeEvery } from 'typed-redux-saga/macro';
import ProductsGateway from 'api/Products';
import { SagaWatcherReturnType } from 'sagas/types';
import { IUpdateCategoryStatusParams } from 'redux/slices/products/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import Toast from 'lib/Toast';

export default function* watchUpdateCategoryStatus(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsSetCategoryStatusAttempt', handleUpdateCategoryStatus, api);
}

function* handleUpdateCategoryStatus(api: ProductsGateway, data: PayloadAction<IUpdateCategoryStatusParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id, status } = data.payload;

    if (!authToken) {
        yield put(Actions.productsSetCategoryStatusFailure('Not authorized!'));
        return;
    }

    const response = yield* call([api, api.updateCategoryStatus], { authToken, id, status });

    if (response.status === GatewayResponseStatus.Error) {
        Toast.showError(response.message || 'Something went wrong. Please try again later');
        yield put(Actions.productsSetCategoryStatusFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        Toast.show('Category status successfully updated!');
        yield put(Actions.productsSetCategoryStatusSuccess({ id, status }));
        return;
    }

    yield put(Actions.productsSetCategoryStatusFailure('Something went wrong. Please try again later'));
}
