import { IAddress, IAdminCustomerDetails } from '@upshop/types';
import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { IGetCustomersResponse } from 'redux/slices/reports/types';

export interface GetCustomersParams {
    authToken: string;
    index: number;
    dateFrom: string | null;
    dateTo: string | null;
    search?: string;
}

export interface GetCustomerDetailsParams {
    authToken: string;
    id: string;
}

export interface UpdateCustomerParams {
    authToken: string;
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    email?: string;
    address: IAddress;
    birthday?: string;
}

export abstract class IReportsGateway extends Gateway {
    abstract getCustomers(params: GetCustomersParams): GatewayResponse<IGetCustomersResponse[] | null>;

    abstract getCustomerDetails(params: GetCustomerDetailsParams): GatewayResponse<IAdminCustomerDetails | null>;

    abstract updateCustomer(params: UpdateCustomerParams): GatewayResponse<null>;
}
