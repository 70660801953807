import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Toast from 'lib/Toast';
import ReportsGateway from 'api/Reports';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import { IUpdateCustomerParams } from 'redux/slices/reports/types';

export default function* watchUpdateCustomer(api: ReportsGateway): SagaWatcherReturnType {
    yield takeEvery('reports/reportsUpdateCustomerAttempt', handleUpdateCustomer, api);
}

function* handleUpdateCustomer(api: ReportsGateway, data: PayloadAction<IUpdateCustomerParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { id, firstName, lastName, phoneNumber, email, address, birthday } = data.payload;

    if (!authToken) {
        yield put(Actions.reportsUpdateCustomerFailure('Not authorized!'));
        Toast.showError('Not authorized!');
        return;
    }

    const response = yield* call([api, api.updateCustomer], { authToken, id, firstName, lastName, phoneNumber, email, address, birthday });

    if (response.status === GatewayResponseStatus.Error) {
        Toast.showError(response.message || 'Something went wrong. Please try again.');
        yield put(Actions.reportsUpdateCustomerFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            Toast.show('Customer info successfully updated!');
            yield put(Actions.reportsUpdateCustomerSuccess({ id, firstName, lastName, phoneNumber, email, address, birthday }));
            return;
        }
    }

    Toast.showError('Something went wrong. Please try again.');
    yield put(Actions.reportsUpdateCustomerFailure('Something went wrong. Please try again later'));
}
