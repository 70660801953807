import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { ITenantGateway, GetTenantStatusParams, SetGoldPriceParams, GetTenantStatusResponse, SetMaintenanceParams, SetToggableGoldPriceParams } from './TenantBase';

export default class TenantGateway extends ITenantGateway {
    async getTenantStatus(params: GetTenantStatusParams): GatewayResponse<GetTenantStatusResponse | null> {
        const { authToken } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<GetTenantStatusResponse> = await this.api.get('/tenant/settings/status', {}, getHeaders());
        return this.process(response);
    }

    async setGoldPrice(params: SetGoldPriceParams): GatewayResponse<null | null> {
        const { authToken, ...restSetGoldPriceParams } = params;
        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.post('/tenant/settings/goldPrice', restSetGoldPriceParams, getHeaders());
        return this.process(response);
    }

    async setToggableGoldPrice(params: SetToggableGoldPriceParams): GatewayResponse<null | null> {
        const { authToken, ...restSetGoldPriceParams } = params;
        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.post('/tenant/settings/toggableGoldPrice', restSetGoldPriceParams, getHeaders());
        return this.process(response);
    }

    async setMaintenance(params: SetMaintenanceParams): GatewayResponse<null | null> {
        const { authToken, ...setMaintenanceParams } = params;

        const getHeaders = () => ({
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response: ApiResponse<null> = await this.api.post('/tenant/settings/maintenance', setMaintenanceParams, getHeaders());
        return this.process(response);
    }
}
