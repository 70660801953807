import { put, call, select, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import ProductsGateway from 'api/Products';

import Actions from 'redux/Actions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import { IGetCategoriesParams } from 'redux/slices/products/types';

export default function* watchGetCategories(api: ProductsGateway): SagaWatcherReturnType {
    yield takeEvery('products/productsGetCategoriesAttempt', handleGetCategories, api);
}

function* handleGetCategories(api: ProductsGateway, data: PayloadAction<IGetCategoriesParams>) {
    const authToken = yield* select(Selectors.getAuthAuthToken);
    const { index, search, sortQuantityAsc, sortTitleAsc } = data.payload;

    if (!authToken) {
        yield put(Actions.productsGetCategoriesFailure('Not authorized!'));
        return;
    }

    // const response = yield* call([api, api.getCategories], { authToken, index, search, sortQuantityAsc, sortTitleAsc });

    const response = {
        status: 1,
        message: '',
        data: {
            index: 1,
            maxIndex: 1,
            data: [
                {
                    status: 1,
                    numberOfItems: 3,
                    id: 'test id',
                    name: 'test name',
                    description: 'test description',
                    imageUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=1.00xw:0.669xh;0,0.190xh&resize=980:*',
                },
                {
                    status: 1,
                    numberOfItems: 3,
                    id: 'test id',
                    name: 'test name',
                    description: 'test description',
                    imageUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=1.00xw:0.669xh;0,0.190xh&resize=980:*',
                },
                {
                    status: 1,
                    numberOfItems: 3,
                    id: 'test id',
                    name: 'test name',
                    description: 'test description',
                    imageUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=1.00xw:0.669xh;0,0.190xh&resize=980:*',
                },
            ],
        },
    };

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.productsGetCategoriesFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(Actions.productsGetCategoriesSuccess(response.data));
            return;
        }
    }

    yield put(Actions.productsGetCategoriesFailure('Something went wrong. Please try again later'));
}
