import { fork } from 'redux-saga/effects';

import { RootSagaReturnType } from 'sagas/types';

import CustomerGateway from 'api/Customer';
import watchGetCustomer from './getCustomers';
import watchGetCustomersDetails from './getCustomersDetails';
import watchExportCustomer from './exportCustomer';

export default (api: CustomerGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCustomer, api);
        yield fork(watchGetCustomersDetails, api);
        yield fork(watchExportCustomer, api);
    }

    return {
        rootSaga,
    };
};
