import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Card, Modal, ModalBody } from 'reactstrap';
import { Input, EditButton, ToggleSwitch } from '@upshop/exodia';

import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import Actions from 'redux/Actions';
import { GetOrderLogsParams } from 'api/OrderBase';
import Selectors from 'redux/Selectors';
import { IGetOrderLogsResponse } from 'redux/slices/order/types';
import { ISetGoldPriceParams, ISetTogggableGoldPriceParams } from 'redux/slices/tenant/types';
import styles from './styles';

interface toggleGoldPriceProps {
    gold375G: {
        isActive: boolean;
        value: number;
    },
    gold750: {
        isActive: boolean;
        value: number;
    },
    gold916: {
        isActive: boolean;
        value: number;
    },
    gold916B: {
        isActive: boolean;
        value: number;
    },
    gold916A: {
        isActive: boolean;
        value: number;
    },
    gold925: {
        isActive: boolean;
        value: number;
    },
    gold999: {
        isActive: boolean;
        value: number;
    },
    gold9999: {
        isActive: boolean;
        value: number;
    }
}

interface GoldPriceSettingsProps {
    toggableGoldPrice: toggleGoldPriceProps;
    goldPrice: ISetGoldPriceParams;
    orderLogs: IGetOrderLogsResponse[];
    gold375G: number | undefined;
    gold750: number | undefined;
    gold916B: number | undefined;
    gold916A: number | undefined;
    gold916: number | undefined;
    gold925: number | undefined;
    gold999: number | undefined;
    gold9999: number | undefined;
    setGold375G: (e: any) => void;
    setGold750: (e: any) => void;
    setGold916B: (e: any) => void;
    setGold916A: (e: any) => void;
    setGold916: (e: any) => void;
    setGold925: (e: any) => void;
    setGold999: (e: any) => void;
    setGold9999: (e: any) => void;
    getOrderLogs: (e: any) => void;
    setToggableGoldPrice: (params: ISetTogggableGoldPriceParams) => void;
}

const GoldPriceSettings = (props: GoldPriceSettingsProps): JSX.Element => {
    const [openSettings, setOpenSettings] = useState(0);
    const [showSettings, setShowSettings] = useState(false);

    const { toggableGoldPrice, goldPrice, orderLogs, gold375G, gold750, gold916B, gold916A, gold916, gold925, gold999, gold9999, setGold375G, setGold750, setGold916B, setGold916A, setGold916, setGold925, setGold999, setGold9999, getOrderLogs, setToggableGoldPrice } = props;

    const [gold375GEnabled, setGold375GEnabled] = useState(false);
    const [gold750Enabled, setGold750Enabled] = useState(false);
    const [gold916BEnabled, setGold916BEnabled] = useState(false);
    const [gold916AEnabled, setGold916AEnabled] = useState(false);
    const [gold916Enabled, setGold916Enabled] = useState(false);
    const [gold925Enabled, setGold925Enabled] = useState(false);
    const [gold999Enabled, setGold999Enabled] = useState(false);
    const [gold9999Enabled, setGold9999Enabled] = useState(false);

    useEffect(() => {
        if (openSettings === 5) {
            setShowSettings(true);
            setOpenSettings(0);
        }
    }, [openSettings]);

    useEffect(() => {
        getOrderLogs({});
    }, []);

    const toggleShowSettings = () => {
        setShowSettings(!showSettings);
    };

    const renderEditButton = (visible: boolean, onClick: () => void) => {
        if (!visible) return false;

        return (
            <Col style={styles.goldEditButton}>
                <EditButton onClick={onClick} />
            </Col>
        );
    };

    const handleToggleGoldPrice = (goldType: string, isActive: boolean) => {
        switch (goldType) {
            case '375G':
                setToggableGoldPrice({ ...toggableGoldPrice, gold375G: { value: toggableGoldPrice.gold375G.value, isActive } });
                break;
            case '750':
                setToggableGoldPrice({ ...toggableGoldPrice, gold750: { value: toggableGoldPrice.gold750.value, isActive } });
                break;
            case '916B':
                setToggableGoldPrice({ ...toggableGoldPrice, gold916B: { value: toggableGoldPrice.gold916B.value, isActive } });
                break;
            case '916A':
                setToggableGoldPrice({ ...toggableGoldPrice, gold916A: { value: toggableGoldPrice.gold916A.value, isActive } });
                break;
            case '916':
                setToggableGoldPrice({ ...toggableGoldPrice, gold916: { value: toggableGoldPrice.gold916.value, isActive } });
                break;
            case '925':
                setToggableGoldPrice({ ...toggableGoldPrice, gold925: { value: toggableGoldPrice.gold925.value, isActive } });
                break;
            case '999':
                setToggableGoldPrice({ ...toggableGoldPrice, gold999: { value: toggableGoldPrice.gold999.value, isActive } });
                break;

            case '9999':
                setToggableGoldPrice({ ...toggableGoldPrice, gold9999: { value: toggableGoldPrice.gold9999.value, isActive } });
                break;

            default:
                break;
        }
    };

    return (
        <Card style={styles.goldCardStyle}>
            <Container>
                <Col>
                    <Row style={{ paddingBottom: '15px' }}>
                        <div style={{ display: 'flex', color: 'blue' }}>
                            <div
                                style={{ marginRight: '5px' }}
                                onClick={() => setOpenSettings(openSettings + 1)}
                                onKeyPress={() => setOpenSettings(openSettings + 1)}
                                role='button'
                                tabIndex={0}
                            >
                                <p>Setting </p>
                            </div>
                            <p>Prices (Real Time)</p>
                        </div>
                    </Row>

                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 20 }}>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold750.isActive}
                                    onClick={() => handleToggleGoldPrice('750', !toggableGoldPrice.gold750.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 750</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>

                                        <Input
                                            disabled={!gold750Enabled}
                                            value={gold750?.toString()}
                                            onChange={(e) => setGold750(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold750Enabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>

                                    {renderEditButton(!gold750Enabled, () => setGold750Enabled(!gold750Enabled))}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold916.isActive}
                                    onClick={() => handleToggleGoldPrice('916', !toggableGoldPrice.gold916.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 916</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>
                                        <Input
                                            disabled={!gold916Enabled}
                                            value={gold916?.toString()}
                                            onChange={(e) => setGold916(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold916Enabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>
                                    {renderEditButton(!gold916Enabled, () => setGold916Enabled(!gold916Enabled))}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold916B.isActive}
                                    onClick={() => handleToggleGoldPrice('916B', !toggableGoldPrice.gold916B.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 916(B)</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>

                                        <Input
                                            disabled={!gold916BEnabled}
                                            value={gold916B?.toString()}
                                            onChange={(e) => setGold916B(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold916BEnabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>

                                    {renderEditButton(!gold916BEnabled, () => setGold916BEnabled(!gold916BEnabled))}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold916A.isActive}
                                    onClick={() => handleToggleGoldPrice('916A', !toggableGoldPrice.gold916A.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 916(A)</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>
                                        <Input
                                            disabled={!gold916AEnabled}
                                            value={gold916A?.toString()}
                                            onChange={(e) => setGold916A(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold916AEnabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>
                                    {renderEditButton(!gold916AEnabled, () => setGold916AEnabled(!gold916AEnabled))}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold999.isActive}
                                    onClick={() => handleToggleGoldPrice('999', !toggableGoldPrice.gold999.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 999</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>
                                        <Input
                                            disabled={!gold999Enabled}
                                            value={gold999?.toString()}
                                            onChange={(e) => setGold999(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold999Enabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>
                                    {renderEditButton(!gold999Enabled, () => setGold999Enabled(!gold999Enabled))}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 12, boxShadow: '1px 2px 9px #d9d9d9' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20, marginRight: 20, gap: 10 }}>
                                <p>Use Jemisys</p>
                                <ToggleSwitch
                                    isOn={toggableGoldPrice.gold9999.isActive}
                                    onClick={() => handleToggleGoldPrice('9999', !toggableGoldPrice.gold9999.isActive)}
                                />
                            </div>

                            <div style={{ padding: 40 }}>
                                <h1 style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem' }}>Gold Price 999.9</h1>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: 150 }}>
                                        <p style={{ margin: 0, fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE' }}>MYR</p>
                                        <Input
                                            disabled={!gold9999Enabled}
                                            value={gold9999?.toString()}
                                            onChange={(e) => setGold9999(e.currentTarget.value)}
                                            style={{ fontFamily: 'DMSans-Bold', fontSize: '1.4rem', color: '#6989FE', padding: 5, width: '80%', border: `${!gold9999Enabled ? 'none' : '1px solid #6989FE'}` }}
                                        />
                                    </div>
                                    {renderEditButton(!gold9999Enabled, () => setGold9999Enabled(!gold9999Enabled))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Container>

            <Modal isOpen={showSettings} toggle={toggleShowSettings} size='xl'>
                <ModalBody>
                    <div style={{ flex: 1, display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 12, overflow: 'auto', padding: 40 }}>
                            {
                                orderLogs.map((orderLog) => {
                                    return (
                                        <div key={orderLog._id} style={{ flex: 1, borderRadius: 12, border: 'solid 1px #404040', padding: 20 }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p>
                                                    Status:
                                                    {' '}
                                                    {orderLog.status}
                                                </p>

                                                <p>
                                                    {orderLog.tenantName}
                                                    :
                                                    {' '}
                                                    {JSON.parse(orderLog.params).Location}
                                                </p>
                                            </div>

                                            <div>
                                                <p className='m-0'>
                                                    Created at:
                                                    {' '}
                                                    {orderLog.createdAt}
                                                </p>

                                                <p>
                                                    Updated at:
                                                    {' '}
                                                    {orderLog.updatedAt}
                                                </p>
                                            </div>
                                            <div>
                                                <p className='m-0' style={{ overflowWrap: 'break-word' }}>
                                                    {JSON.stringify(JSON.parse(orderLog.params))}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Card>
    );
};

const mapStateToProps = (state: RootState) => ({
    orderLogs: Selectors.getOrderJemisysPlaceOrderLogs(state),
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrderLogs: (params: GetOrderLogsParams) => dispatch(Actions.orderGetJemisysPlaceOrderLogsAttempt(params)),
    setToggableGoldPrice: (params: ISetTogggableGoldPriceParams) => dispatch(Actions.tenantSetToggableGoldPriceAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoldPriceSettings);
