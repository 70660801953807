import { fork } from 'redux-saga/effects';
import ReportsGateway from 'api/Reports';
import { RootSagaReturnType } from 'sagas/types';

import watchGetCustomers from './getCustomers';
import watchGetCustomerDetails from './getCustomerDetails';
import watchUpdateCustomer from './updateCustomer';

export default (api: ReportsGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetCustomers, api);
        yield fork(watchGetCustomerDetails, api);
        yield fork(watchUpdateCustomer, api);
    }

    return {
        rootSaga,
    };
};
